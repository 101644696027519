import axios from "axios";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Card } from "react-bootstrap";
import { toast } from "react-toastify";
// import IsLoading from "../../user/other/IsLoading";
import { Link, NavLink, useParams } from "react-router-dom";
import {
  faThumbsDown,
  faThumbsUp,
  faBullhorn,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Blog.css";

const BlogDetail = () => {
  const [post, setPost] = useState({
    topicId: "",
    topicSlug: "",
    topicName: "",
    postTitle: "",
    authId: "",
    authName: "",
    slug: "",
    keyword: "",
    image: "",
    titleVi: "",
    descVi: "",
    contentVi: "",
    statusId: "",
    createdAt: "",
  });
  const [isLoad, setIsload] = useState(false);
  const { post_slug } = useParams();
  const [postsRelated, setpostsRelated] = useState([]);

  useEffect(() => {
    if (post.postSlug != post_slug) {
      document.title = "Gen A | Tin tức";
      window.scrollTo({ top: 0, behavior: "smooth" });
      getPostBySlug(post_slug);
    }
  }, [post_slug]);

  const getImage = (src) => {
    if (src == null) return "/logo512.png";
    else return src;
  };

  const getPostBySlug = async (post_slug) => {
    setIsload(true);
    if (post_slug !== "") {
      await axios
        .get(`/post/${post_slug}`)
        .then((res) => {
          // setpostsRelated(res.data.postsRelated)
          let postData = res.data.data;
          if (postData != null) {
            setPost({
              topicId: postData.categoryId,
              topicSlug: "",
              topicName: "",
              postTitle: postData.postTitle,
              slug: postData.postSlug,
              keyword: postData.postKeyword,
              image: postData.image?.imageLink ?? postData.image?.imageSrc,
              descVi: postData.postDescription,
              contentVi: postData.postContent,
              createdAt: postData.updatedAt,
            });
          }
          setIsload(false);
        })
        .catch((error) => {
          setIsload(false);
          toast(error.message, { type: "error" });
        });
    }
  };

  const meta = {
    title: post.postTitle,
    description: post.descVi,
    keywords: post.keyword,
    og_image: "",
    fb_app_id: "",
  };

  const renderPostsRelated = () => {
    return postsRelated?.map((item, index) => {
      return (
        <Col md={3} key={`postsRelated${index}`}>
          <Card>
            <Card.Img
              variant="top"
              src={getImage(item.posts_image)}
              alt="img"
              className="post_img"
            />
            <Card.Body>
              <Card.Title>
                <Link to={`/blog/${item.posts_slug}`} className="text-titleVi">
                  {item.posts_title}
                </Link>
              </Card.Title>

              <Card.Text>
                <span className="text_descVi">{item.posts_desc}</span>
              </Card.Text>

              <div className="cart-reviews">
                <Card.Link href="#">
                  <FontAwesomeIcon icon={faThumbsUp} />
                </Card.Link>
                <Card.Link href="#">
                  <FontAwesomeIcon icon={faThumbsDown} />
                </Card.Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      );
    });
  };
  return (
    <div className="detail_bg" id="page_blog">
      <Row className="m-0">
        <Col>
          <div className="container pt-3 ps-md-4  bg-white">
            <Breadcrumb className="my-2" style={{ display: "contents" }}>
              <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/" }}>
                Trang chủ
              </Breadcrumb.Item>

              <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/blog/" }}>
                Tin tức
              </Breadcrumb.Item>
              {post.topicSlug != "" ? (
                <Breadcrumb.Item
                  linkAs={NavLink}
                  linkProps={{ to: `/blog/${post.topicSlug}` }}
                >
                  {post.topicName}
                </Breadcrumb.Item>
              ) : null}
              <Breadcrumb.Item active>{post.postTitle}</Breadcrumb.Item>
            </Breadcrumb>

            <h1 className="text-center">{post.postTitle}</h1>
            <div className="blog-desc">{post.descVi}</div>
            <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: post.contentVi }}
            ></div>

            {postsRelated.length > 0 ? (
              <>
                <div className="text_PostsRelated">
                  <FontAwesomeIcon icon={faBullhorn} className="me-1" /> Bài
                  viết liên quan
                </div>
              </>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row></Row>
      {/* <IsLoading show={isLoad} /> */}
      {/* <HeadMeta {...meta} /> */}
    </div>
  );
};

export default BlogDetail;
