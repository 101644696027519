import React from "react";

import "./feedback.css";

import { Row, Col } from "react-bootstrap";
function Feedback() {
  // const sidenavToggle = () => {
  //     const navbarSupportedContent = document.getElementById('navbarSupportedContent');

  //     let testclass = navbarSupportedContent.className;

  //     if (testclass === 'navbar-collapse collapse show' && window.innerWidth < 992) {
  //         navbarSupportedContent.classList.remove('show');
  //     }
  // }
  const dataFeedback = [
    {
      id: 0,
      name: "Nguyễn văn A",
      image:
        "https://th.bing.com/th/id/OIF.xDzOLEDge4kFhgt5uB6mfQ?pid=ImgDet&rs=1",
      feedback:
        "Sản phẩm tốt, đáng tin cậy, Sản phẩm tốt, đáng tin cậy, Sản phẩm tốt, đáng tin cậy, Sản phẩm tốt, đáng tin cậy, Sản phẩm tốt, đáng tin cậy.",
      date: "22 July , 2024. Monday",
    },
    {
      id: 1,
      name: "Nguyễn văn B",
      image:
        "https://th.bing.com/th/id/OIF.xDzOLEDge4kFhgt5uB6mfQ?pid=ImgDet&rs=1",

      feedback:
        "Sản phẩm tốt, đáng tin cậy, Sản phẩm tốt, đáng tin cậy, Sản phẩm tốt, đáng tin cậy, Sản phẩm tốt, đáng tin cậy, Sản phẩm tốt, đáng tin cậy.",
      date: "22 July , 2024. Monday",
    },

    {
      id: 2,
      name: "Nguyễn văn C",
      image:
        "https://th.bing.com/th/id/OIP.-uN_d-gvuDMVI8Uy3mQkgwHaKP?pid=ImgDet&w=205&h=282&c=7",
      feedback:
        "Sản phẩm tốt, đáng tin cậy, Sản phẩm tốt, đáng tin cậy, Sản phẩm tốt, đáng tin cậy, Sản phẩm tốt, đáng tin cậy, Sản phẩm tốt, đáng tin cậy.",
      date: "22 July , 2024. Monday",
    },
  ];
  return (
    <section className="shop-blog section">
      <div>
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h2>Khách hàng nói gì về chúng tôi</h2>
            </div>
          </div>
        </div>
        <Row>
          {dataFeedback.map((item) => {
            return (
              <Col key={`dataFeedback` + item.id}>
                <div className="shop-single-blog">
                  <img
                    src={item.image}
                    alt="#"
                    style={{
                      borderRadius: "50%",
                      height: "150px",
                      width: "150px",
                      objectFit: "cover",
                    }}
                  />
                  <div className="content">
                    <p className="date">{item.date}</p>
                    <a href="#" className="title">
                      {item.name}
                    </a>
                    <a href="#" className="more-btn">
                      {item.feedback}
                    </a>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}

export default Feedback;
