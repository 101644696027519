import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Row, Col, Card, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import DataTables from "../datatable/DataTables";
import OrderModal from "./OrderModal";
import { priceFormatter } from "../other/Format";
import { useNavigate } from "react-router-dom";

const OrderIndex = (page) => {
  const [orders, setOrders] = useState([]);
  const [show, setShow] = useState(false);
  const [showInf, setShowInf] = useState(false);
  const navigate = useNavigate();

  const [status, setStatus] = useState([
    { value: "PENDING", label: "Chờ duyệt", color: "#FFA500" }, // Orange
    { value: "ACCEPTED", label: "Đã duyệt", color: "#008000" }, // Green
    { value: "DELIVERED", label: "Đã gửi", color: "#0000FF" }, // Blue
    { value: "REJECTED", label: "Từ chối", color: "#FF0000" }, // Red
    { value: "REFUNDED", label: "Hoàn tiền", color: "#800080" }, // Purple
    { value: "CANCELED", label: "Hủy", color: "#808080" }, // Gray
  ]);
  const [data, setData] = useState({});
  const onHide = (isLoading) => {
    setShow(false);
    setShowInf(false);
    if (isLoading) getOders();
  };
  useEffect(() => {
    getOders();
    document.title = "Quản lý đơn hàng";
  }, [page.element]);
  const getOders = () => {
    axios
      .get(`admin/orders`)
      .then((res) => {
        setOrders(res.data.data?.products);
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          navigate("/admin/access-denied");
        }
        console.log(err);
      });
  };
  const getCategoryBySlug = (slug) => {
    axios
      .get(`admin/edit-order/${slug}`)
      .then((res) => {
        setData(res.data.data ?? {});
        setShowInf(true);
      })
      .catch((err) => {
        toast(err.data.message, { type: "error", autoClose: 1000 });
        console.log(err);
      });
  };
  const deleteCategory = (id) => {
    axios
      .delete(`/admin/delete-order/${id}`)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 1000 });
        getOders();
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        } else
          toast(err.response?.data?.message || "Xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
      });
  };
  const renderTable = () => {
    const columns = [
      {
        name: "Mã đơn hàng",
        selector: (row) => row.trackingNumber,
        cell: (row) => <div className="text-wrap">{row.trackingNumber}</div>,
      },
      {
        name: "Khách hàng",
        selector: (row) => row.name,
        cell: (row) => <div className="text-wrap">{row.name}</div>,
      },
      {
        name: "Tổng tiền",
        selector: (row) => row.totalAmount,
        cell: (row) => (
          <div className="text-wrap">
            {priceFormatter.format(row.totalAmount)}
          </div>
        ),
        right: "true",
      },
      {
        name: "Trạng thái",
        selector: (row) =>
          status?.filter((e) => e.value == row.status)[0]?.label,
        sortable: true,
        center: "true",
        cell: (row) => (
          <div
            className="text-wrap"
            style={{
              color: status?.filter((e) => e.value == row.status)[0]?.color,
            }}
          >
            {status?.filter((e) => e.value == row.status)[0]?.label}
          </div>
        ),
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];
    const data = [];
    if (orders?.length > 0)
      orders?.map((item) => {
        data?.push({
          ...item,
          setting: (
            <>
              <Button
                className="me-2"
                size="sm"
                onClick={() => getCategoryBySlug(item.id)}
                title="Chi tiết"
              >
                <i className="fa-solid fa-circle-info"></i>
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => deleteCategory(item.id)}
                title="Xóa"
              >
                <i className="fas fa-trash"></i>
              </Button>
            </>
          ),
        });
      });

    return <DataTables data={data} columns={columns} />;
  };
  return (
    <div className="container">
      <Breadcrumb className="mb-2 mt-2 " style={{ display: "contents" }}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Tổng quan
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Quản lý đơn hàng</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header>
              <Card.Title>
                <i className="fas fa-list me-1"></i> Danh sách đơn hàng
              </Card.Title>
            </Card.Header>
            <Card.Body>{renderTable()}</Card.Body>
          </Card>
        </Col>
      </Row>
      {showInf ? (
        <OrderModal
          data={data}
          onHide={onHide}
          show={showInf}
          statusList={status}
        />
      ) : null}
    </div>
  );
};

export default OrderIndex;
