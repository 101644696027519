import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  NavItem,
} from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import DataTables from "../datatable/DataTables";
import { useNavigate } from "react-router-dom";
import { priceFormatter } from "../other/Format";
const IncomeIndex = () => {
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Thu nhập cá nhân";
    getCategories();
  }, []);

  const getCategories = () => {
    axios
      .get(`admin/personal-incomes`)
      .then((res) => {
        setCategories(res.data.data?.incomes);
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          navigate("/admin/access-denied");
        }
        console.log(err);
      });
  };

  const convertDate = (maxFromDate) => {
    const day = String(maxFromDate.getDate()).padStart(2, "0");
    const month = String(maxFromDate.getMonth() + 1).padStart(2, "0");
    const year = maxFromDate.getFullYear();

    return `${day}/${month}/${year}`;
  };
  const renderTable = () => {
    const columns = [
      {
        name: "Từ ngày",
        selector: (row) => row.incomes,
        cell: (row) => (
          <div className="text-wrap">{convertDate(new Date(row.fromDate))}</div>
        ),
      },
      {
        name: "Đến ngày",
        selector: (row) => row.incomes,
        sortable: true,
        cell: (row) => (
          <div className="text-wrap">{convertDate(new Date(row.toDate))}</div>
        ),
      },
      {
        name: "Tổng thu nhập",
        selector: (row) => row.incomes,
        cell: (row) => (
          <div className="text-wrap">{priceFormatter.format(row.total)}</div>
        ),

        right: true,
      },
    ];
    const incomesColumns = [
      {
        name: "Thu nhập",
        selector: (row) => row.amount,
        cell: (row) => (
          <div className="text-wrap">{priceFormatter.format(row.amount)}</div>
        ),
      },
      {
        name: "Ghi chú",
        selector: (row) => row.description,
        cell: (row) => <div className="text-wrap">{row.description}</div>,

        wrap: true,
      },
    ];
    const data = [];
    if (categories?.length > 0)
      categories?.map((item) => {
        data?.push({
          ...item,
        });
      });
    const IncomesTable = ({ data }) => {
      const dataIncome = [];
      if (data?.IncomeDetails?.length > 0)
        data?.IncomeDetails?.map((item) => {
          dataIncome?.push({
            ...item,
          });
        });
      if (data.incomes?.length == 0) {
        return <i className="text-muted ms-5">Không có dữ liệu!</i>;
      }

      return (
        <DataTables
          className="ms-5"
          data={dataIncome}
          columns={incomesColumns}
          pagination={false}
          fixedHeader={false}
        />
      );
    };
    return (
      <DataTables
        data={data}
        columns={columns}
        expandableRows
        // expandableRowExpanded={(row) => row === currentRow}
        expandOnRowClicked
        // onRowClicked={(row) => setCurrentRow(row)}
        expandableRowsComponent={IncomesTable}
        // onRowExpandToggled={(bool, row) => setCurrentRow(row)}
        progressComponent={
          <Spinner
            animation="border"
            role="status"
            variant="primary"
            style={{ width: "3rem", height: "3rem" }}
            className="mt-3"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        }
      />
    );
  };
  return (
    <div className="container">
      <Breadcrumb className="mb-2 mt-2 " style={{ display: "contents" }}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Tổng quan
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Thu nhập cá nhân</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header>
              <Card.Title>
                <i className="fas fa-list me-1"></i> Thu nhập cá nhân -{" "}
                {categories[0]?.User?.userFullName}
              </Card.Title>
            </Card.Header>
            <Card.Body>{renderTable()}</Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default IncomeIndex;
