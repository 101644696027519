import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";

// import '../../assets/admin/css/styles.css';
import { toast } from "react-toastify";
import axios from "axios";
import "./masterLayout.css";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import { useLocation } from "react-router-dom";
function MasterLayout() {
  const location = useLocation();
  useEffect(() => {
    axios.post(`/auth-user`).then((res) => {
      if (res.data) {
        localStorage.setItem(
          "permission",
          JSON.stringify(
            res.data.data?.Roles[0]?.Permissions?.map((item) => {
              return item.permissionName;
            })
          )
        );
      } else {
        localStorage.setItem("permission", []);
      }
    });
  }, [location]);

  const sidenavToggle = () => {
    const sbNavFixed = document.getElementById("sb-nav-fixed");
    //sbNavFixed.classList.remove('sb-sidenav-toggled');

    let testClass = sbNavFixed.className;

    if (
      testClass === "sb-nav-fixed sb-sidenav-toggled" &&
      window.innerWidth < 992
    ) {
      sbNavFixed.classList.remove("sb-sidenav-toggled");
    }
  };

  if (!localStorage.getItem("access_token")) {
    toast("Bạn chưa đăng nhập.", { type: "error" });
    return <Navigate to="/login" />;
  }

  return (
    <div className="sb-nav-fixed" id="sb-nav-fixed">
      <Navbar />

      <div id="layoutSidenav">
        <div id="layoutSidenav_nav" className="bg-dark">
          <Sidebar />
        </div>

        <div id="layoutSidenav_content_" onClick={sidenavToggle}>
          <main>
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default MasterLayout;
