import React, { useEffect, useState } from "react";
import "./homePage.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HotItem from "../hotItem/HotItem";
import Sale from "../sale/Sale";
import TrendProduct from "../trendProduct/TrendProduct";
import Feedback from "../feedback/Feedback";
import { Button, Col, Form, Row, Modal, Container } from "react-bootstrap";
import Navbar_N from "../../../layouts/frontend/navbar/NavBar_N";
import NavbarMiddle from "../../../layouts/frontend/navbarMiddle/Navbar_middle";
import axios from "axios";
import Footer from "../../../layouts/frontend/footer/Footer";
import PageHero from "../../../layouts/frontend/pageHero/PageHero";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IsLoading from "../../../layouts/IsLoading";
import {
  faCar,
  faHandsHoldingCircle,
  faShieldVirus,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
function HomePage() {
  const [logo, setLogo] = useState();
  const [isLoad, setIsLoad] = useState(false);
  const [banner, setBanner] = useState([]);
  const [settings, setSettings] = useState({});
  const [contact, setContact] = useState({});
  const [errors, setErrors] = useState({});
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getProducts();
    getLogo();
    document.title = "Gen A";
  }, []);
  useEffect(() => {}, [window.innerWidth]);
  const getLogo = () => {
    setIsLoad(true);
    axios
      .get(`settings`)
      .then((res) => {
        setLogo(res.data.data?.filter((e) => e.name == "logo")[0]?.payload);
        setIsLoad(false);
        setSettings(res.data.data);
        setBanner(res.data.data?.filter((e) => e.name == "banner")[0]?.payload);
      })
      .catch((err) => {
        setIsLoad(true);

        console.log(err);
      });
  };
  const dataService = [
    {
      icon: faCar,
      name: "MIỄN PHÍ VẬN CHUYỂN",
      content: "Đơn hàng trên 99.000",
    },
    {
      icon: faHandsHoldingCircle,
      name: "GIÁ TỐT NHẤT",
      content: "100% bảo mật",
    },
    {
      icon: faShieldVirus,
      name: "BẢO HÀNH VÀ ĐỔI TRẢ",
      content: "Bảo hành trọn đời",
    },
    {
      icon: faHeadset,
      name: "TƯ VẤN VÀ HỖ TRỢ",
      content: "Hỗ trợ 24/7",
    },
  ];
  const [products, setProduct] = useState([]);
  const [saleItem, setSaleItem] = useState({});
  const handleInput = (e) => {
    let value = e.target.value;
    let field = e.target.name;
    contact[field] = value;
    delete errors[field];
    setContact({ ...contact });
    setErrors({ ...errors });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let contactData = {
      contactName: contact.contactName || "",
      contactPhone: contact.contactPhone || "",
      contactEmail: contact.contactEmail || "",
      contactNotes: contact.contactNotes || "",
      contactAddress: "",
    };
    axios
      .post("/create-contact", contactData)

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          setContact({});
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErrors(error.response?.data?.messages ?? "");
      });
  };

  const getProducts = () => {
    setIsLoad(true);
    axios
      .get(`products`)
      .then((res) => {
        setIsLoad(false);
        setProduct(res.data.data?.products);
        getProductPriority(
          findLowestPriorityProduct(res.data.data?.products)?.productSlug
        );
      })
      .catch((err) => {
        setIsLoad(true);

        console.log(err);
      });
  };
  const getProductPriority = (slug) => {
    setIsLoad(true);

    axios
      .get(`product/${slug}`)
      .then((res) => {
        setSaleItem(res.data.data);
        setIsLoad(false);
      })
      .catch((err) => {
        setIsLoad(false);

        console.log(err);
      });
  };
  const findLowestPriorityProduct = (products) => {
    if (products.length === 0) return null;

    let lowestPriorityProduct = products[0];

    for (let i = 1; i < products.length; i++) {
      if (products[i].productPriority < lowestPriorityProduct.productPriority) {
        lowestPriorityProduct = products[i];
      }
    }

    return lowestPriorityProduct;
  };
  return (
    <>
      {isLoad ? (
        <IsLoading show={isLoad} />
      ) : (
        <>
          {settings ? (
            <NavbarMiddle logo={logo} setting={settings} />
          ) : (
            <IsLoading show={isLoad} />
          )}
          <Navbar_N />
          <Row className="me-md-0 row-homepage">
            <Col md={4} className="pe-0 ">
              <Form onSubmit={handleSubmit}>
                <div className=" ps-2  ">
                  <h2 className="text-contact text-center">
                    TÌM HIỂU CƠ HỘI KINH DOANH
                  </h2>

                  <Row>
                    <Col>
                      <Form.Floating className="mb-3 mb-md-2">
                        <Form.Control
                          defaultValue={""}
                          value={contact.contactName || ""}
                          name="contactName"
                          type="text"
                          placeholder="Họ và tên"
                          onChange={(e) => handleInput(e)}
                        />
                        <Form.Label>
                          Họ và tên <i className="text-danger">*</i>
                        </Form.Label>
                        <span className="text-err">
                          {errors.contactName ? errors.contactName : ""}
                        </span>
                      </Form.Floating>
                    </Col>
                  </Row>
                  <Form.Floating className="mb-3  mb-md-2">
                    <Form.Control
                      defaultValue={""}
                      value={contact.contactPhone || ""}
                      name="contactPhone"
                      type="text"
                      placeholder="Số điện thoại"
                      onChange={(e) => handleInput(e)}
                    />
                    <Form.Label>Số điện thoại</Form.Label>
                    <span className="text-err">
                      {errors.contactPhone ? errors.contactPhone : ""}
                    </span>
                  </Form.Floating>
                  <Form.Floating className="mb-3  mb-md-2">
                    <Form.Control
                      defaultValue={""}
                      value={contact.contactEmail || ""}
                      name="contactEmail"
                      type="text"
                      placeholder="contactEmail"
                      onChange={(e) => handleInput(e)}
                    />
                    <Form.Label>
                      Địa chỉ email<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-err">
                      {errors.contactEmail ? errors.contactEmail : ""}
                    </span>
                  </Form.Floating>
                  <Form.Floating className="mb-3  mb-md-2">
                    <Form.Control
                      defaultValue={""}
                      value={contact.contactNotes || ""}
                      name="contactNotes"
                      type="text"
                      placeholder="Lời nhắn"
                      onChange={(e) => handleInput(e)}
                    />
                    <Form.Label>Lời nhắn</Form.Label>{" "}
                    <span className="text-err">
                      {errors.contactNotes ? errors.contactNotes : ""}
                    </span>
                  </Form.Floating>
                  <center>
                    <Button variant="danger" type="submit" className="mb-2">
                      Gửi
                    </Button>
                  </center>
                </div>
              </Form>
            </Col>
            <Col md={8} className="pe-0 ">
              <PageHero banner={banner} />
            </Col>
          </Row>

          <Container>
            {products?.length > 0 ? (
              <TrendProduct products={products} logo={logo} />
            ) : (
              <IsLoading show={true} />
            )}
            <HotItem />
            {saleItem ? <Sale logo={logo} /> : <IsLoading show={true} />}
            <Feedback />
          </Container>
          <section className="shop-services section home">
            <div className="container">
              <div className="row py-3">
                {dataService?.map((service) => {
                  return (
                    <div
                      className="col-lg-3 col-md-6 col-6"
                      key={`dataService` + service.content}
                    >
                      <div className="single-service">
                        <div
                          style={{
                            fontSize: "35px",
                            marginRight: "10px",
                            color: "#dc3545",
                          }}
                        >
                          <FontAwesomeIcon icon={service.icon} />
                        </div>
                        <div>
                          <h4>{service.name}</h4>
                          <p>{service.content}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
}

export default HomePage;
