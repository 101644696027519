import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./cart.css";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  FormControl,
} from "react-bootstrap";
import { faCartPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import { useCart } from "react-use-cart";
import swal from "sweetalert";
import Select from "react-select";
import { priceFormatter } from "../../admin/other/Format";
function Cart() {
  const navigate = useNavigate();
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [waveHouse, setWaveHouse] = useState([]);
  const [choiceWaveHouse, setChoiceWaveHouse] = useState({
    value: null,
    label: "Chọn kho hàng",
  });
  const [wards, setWards] = useState([]);
  const [optionWards, setOptionWards] = useState([]);

  const [choiceProvince, setChoiceProvince] = useState({
    value: null,
    label: "Chọn tỉnh/ thành phố",
  });
  const [choiceDistrict, setChoiceDistrict] = useState({
    value: 0,
    label: "Chọn quận/ huyện",
  });
  const [choiceWard, setChoiceWard] = useState({
    value: 0,
    label: "Chọn phường/ xã",
  });
  const [err, setErr] = useState({});
  const [customer, setCustomer] = useState({
    email: "",
    name: "",
    shippingAddress: "",
    phoneNumber: "",
    note: "",
  });
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
    emptyCart,
    metadata,
    updateCartMetadata,
  } = useCart();

  useEffect(() => {
    document.title = "Giỏ hàng";
    window.scrollTo({ top: 0, behavior: "smooth" });
    getProvinces();
  }, []);
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minHeight: "50px",
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: "-8px",
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: "-8px",
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: "-8px",
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      // position: "unset",
      maxHeight: "180px",
      overflowY: "auto", // Allows scrolling if the content exceeds maxHeight
      // zIndex: 100, // Ensures the menu appears above other elements
      borderRadius: "4px", // Adjusts the border radius for rounded corners
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adds a subtle shadow
    }),

    option: (baseStyles, state) => ({
      ...baseStyles,
      padding: "10px 20px", // Adjusts padding for options
      backgroundColor: state.isSelected
        ? "#007bff"
        : state.isFocused
        ? "#f0f0f0"
        : "white", // Colors for selected and focused states
      color: state.isSelected ? "white" : "black", // Text color adjustments
      cursor: "pointer", // Changes the cursor to a pointer
      "&:active": {
        backgroundColor: state.isSelected ? "#0056b3" : "#e0e0e0", // Active state background color
      },
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      marginTop: "-8px",
    }),
  };

  const getProvinces = () => {
    axios
      .get(`provinces`)
      .then((res) => {
        setProvinces(
          res.data.data?.map((province) => {
            return { value: province.id, label: province.name };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getWaveHouse = (id) => {
    axios
      .get(`warehouses?provinceId=${id}`)
      .then((res) => {
        setWaveHouse(
          res.data.data?.map((province) => {
            return { value: province.id, label: province.name };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDistricts = (idProvince) => {
    axios
      .get(`districts/${idProvince}`)
      .then((res) => {
        setDistricts(
          res.data.data?.map((province) => {
            return { value: province.id, label: province.name };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getWards = (idDistrict) => {
    axios
      .get(`wards/${idDistrict}`)
      .then((res) => {
        setWards(
          res.data.data?.map((province) => {
            return { value: province.id, label: province.name };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputCustomer = (e) => {
    let field = e.target.name;
    let value = e.target.value;

    let data = { ...customer };
    delete err[field];

    data[field] = value;
    setCustomer(data);
  };
  const handleInput = (e) => {
    let field = e.target.name;
    let value = e.target.value;

    let data = { ...metadata };

    data[field] = value;

    if (value.length) {
      delete data.error_list[field];
    }

    if (field === "province_id") {
      // const provinceData = provinces.filter(item => item.id === parseInt(value));
      // data['feeshipping'] = provinceData[0].freeship;

      let districtShow = districts.filter(
        (item) => item.province_id === parseInt(value)
      );
      setOptionWards([]);
      data.district_id = "";
      data.ward_id = "";
    } else if (field === "district_id") {
      // const provinceData = provinces.filter(item => item.id === parseInt(metadata.province_id));
      // const districtData = districts.filter(item => item.id === parseInt(value));

      let wardShow = wards.filter(
        (item) =>
          item.district_id === parseInt(value) &&
          item.province_id === parseInt(metadata.province_id)
      );
      setOptionWards(wardShow);
      data.ward_id = "";
    } else if (field === "discount_code") {
      data.discount_type = "";
      data.discount_value = "";
      data.discount_money = 0;
    }

    updateCartMetadata(data);
  };
  const getSum = () => {
    let sum = 0;
    items.map((item) => {
      sum += item.quantity * item.salePrice;
    });
    return sum;
  };
  const updateQuantity = (id, quantity) => {
    if (quantity <= 0) {
      swal({
        title: "Bạn muốn xóa sản phẩm?",
        icon: "error",
        dangerMode: true,
        buttons: ["Đóng", "Xóa"],
      }).then((ok) => {
        if (ok) {
          updateItemQuantity(id, quantity);
        }
      });
    } else {
      updateItemQuantity(id, quantity);
    }

    // Update Discount
  };
  const deleteProduct = (id) => {
    swal({
      title: "Bạn muốn xóa sản phẩm?",
      icon: "error",
      dangerMode: true,
      buttons: ["Đóng", "Xóa"],
    }).then((ok) => {
      if (ok) {
        return removeItem(id);
      }
    });
  };
  const createOrder = (e) => {
    e.preventDefault();
    let orderProduct = items?.map((item) => {
      return item.id;
    });
    let orderProductQuantity = items?.map((item) => {
      return item.quantity;
    });
    let arrProduct = orderProduct.map((a, index) => [
      a,
      orderProductQuantity[index],
    ]);
    let order = {
      ...customer,
      orderProducts: arrProduct,
      // warehouseId: choiceWaveHouse.value,
      shippingAddress: `${customer.shippingAddress} ${
        choiceWard.value > 0 ? choiceWard.label : ""
      } ${choiceDistrict.value > 0 ? choiceDistrict.label : ""} ${
        choiceProvince.value != null ? choiceProvince.label : ""
      }`,
    };
    console.log(order, "order");
    axios
      .post(`create-order`, order)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 3000 });
        swal({
          title: "Đặt hàng thành công!",
          text: "Cảm ơn bạn đã đặt hàng! Chúng tôi sẽ trực tiếp liên hệ với bạn trong thời gian sớm nhất.",
          icon: "success",
          successMode: true,
          buttons: "Đóng",
        }).then((ok) => {
          emptyCart();
        });
      })
      .catch((error) => {
        setErr(error.response?.data?.messages);
      });
  };

  return (
    <>
      {/* <!-- Shopping Cart --> */}
      <div className="shopping-cart section">
        <div className="container">
          {items?.length < 1 ? (
            <div className="row">
              <h5 className="text-muted">Giỏ hàng không có sản phẩm!</h5>
              <Link to={"/product"} className="btn btn-primary">
                <FontAwesomeIcon icon={faCartPlus} /> Mua thêm
              </Link>
            </div>
          ) : (
            <>
              {/*           
          </>
          } */}
              <div className="row">
                <div className="col-12">
                  {/* <!-- Shopping Summery --> */}
                  <table className="table shopping-summery">
                    <thead>
                      <tr className="main-hading">
                        <th>Sản phẩm</th>
                        <th>Tên</th>
                        <th className="text-center">Giá</th>
                        <th className="text-center">Số lượng</th>
                        <th className="text-center">Tổng</th>
                        <th className="text-center">
                          Xóa
                          {/* <i className="ti-trash remove-icon"></i> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items?.map((item) => {
                        return (
                          <tr>
                            <td className="image" data-title="Hình ảnh">
                              <img
                                src={
                                  item.mainImage?.imageSrc ??
                                  item.mainImage?.imageLink
                                }
                              />
                            </td>
                            <td
                              className="product-des"
                              data-title="Tên sản phẩm"
                            >
                              <p className="product-name">
                                <a href={`product-detail/${item.productSlug}`}>
                                  {item?.productName}
                                </a>
                              </p>
                              <p className="product-des text-wrap">
                                {item?.productDescription}
                              </p>
                            </td>
                            <td className="price" data-title="Giá">
                              <span>
                                {priceFormatter.format(item.salePrice)} ₫{" "}
                              </span>
                            </td>
                            <td
                              className="qty align-middle"
                              data-title="Số lượng"
                            >
                              <InputGroup className=" d-flex">
                                {/* <Button
                                  variant="outline-danger border-end-0"
                                  onClick={() =>
                                    updateQuantity(item.id, item.quantity - 1)
                                  }
                                  disabled={item.quantity <= 1}
                                >
                                  -
                                </Button> */}
                                <FormControl
                                  type="number"
                                  value={item.quantity}
                                  onChange={(e) =>
                                    updateQuantity(item.id, e.target.value)
                                  }
                                  min="1"
                                  max={100}
                                  className="text-center"
                                />
                                {/* <Button
                                  variant="outline-danger"
                                  onClick={() =>
                                    updateQuantity(item.id, item.quantity + 1)
                                  }
                                  disabled={item.quantity >= 100}
                                >
                                  +
                                </Button> */}
                              </InputGroup>
                            </td>
                            <td
                              className="total-amount"
                              data-title="Thành tiền"
                            >
                              <span>
                                {priceFormatter.format(
                                  item.salePrice * item.quantity
                                )}{" "}
                                ₫
                              </span>
                            </td>
                            <td className="action" data-title="Xóa">
                              <a onClick={() => deleteProduct(item.id)}>
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  style={{ color: "red", cursor: "pointer" }}
                                />
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* <!--/ End Shopping Summery --> */}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {/* <!-- Total Amount --> */}
                  <div className="total-amount">
                    <Form onSubmit={createOrder}>
                      <div className="row">
                        <div className="col-lg-8 col-md-5 col-12">
                          <div className="left">
                            <div className="">
                              <p
                                style={{
                                  background: "rgb(174, 2, 20)",
                                  color: "#fff",
                                  padding: "10px",
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                              >
                                THÔNG TIN KHÁCH HÀNG
                              </p>

                              <Row>
                                <Col md={4}>
                                  <Form.Floating className="mb-3">
                                    <Form.Control
                                      onChange={handleInputCustomer}
                                      name="name"
                                      type="text"
                                      placeholder="Tên người nhận"
                                      required
                                    />
                                    <Form.Label>
                                      Tên người nhận
                                      <i className="text-danger">*</i>
                                    </Form.Label>
                                    <span className="text-err">
                                      {err?.name}
                                    </span>
                                  </Form.Floating>
                                </Col>
                                <Col md={4}>
                                  <Form.Floating className="mb-3">
                                    <Form.Control
                                      onChange={handleInputCustomer}
                                      name="email"
                                      type="text"
                                      placeholder="Tên người nhận"
                                      required
                                    />
                                    <Form.Label>
                                      Email<i className="text-danger">*</i>
                                    </Form.Label>
                                    <span className="text-err">
                                      {err?.email}
                                    </span>
                                  </Form.Floating>
                                </Col>
                                <Col md={4}>
                                  <Form.Floating className="mb-3">
                                    <Form.Control
                                      onChange={handleInputCustomer}
                                      name="phoneNumber"
                                      type="text"
                                      placeholder="Tên người nhận"
                                      required
                                    />
                                    <Form.Label>
                                      Số điện thoại
                                      <i className="text-danger">*</i>
                                    </Form.Label>
                                    <span className="text-err">
                                      {err?.phoneNumber}
                                    </span>
                                  </Form.Floating>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={4} className="mb-3">
                                  <Select
                                    defaultValue={choiceProvince}
                                    options={provinces}
                                    styles={customStyles}
                                    required
                                    placeholder="Chọn tỉnh/ thành phố"
                                    value={choiceProvince}
                                    onChange={(choice) => {
                                      setChoiceProvince(choice);
                                      getDistricts(choice.value);
                                      delete err[`provinceId`];
                                      delete err[`districtId`];
                                      delete err[`wardId`];
                                      setChoiceWard({
                                        value: 0,
                                        label: "Chọn phường/ xã",
                                      });
                                      // getWaveHouse(choice.value);
                                      setChoiceDistrict({
                                        value: 0,
                                        label: "Chọn quận/ huyện",
                                      });
                                      setChoiceWaveHouse({
                                        value: null,
                                        label: "Chọn kho",
                                      });
                                    }}
                                  />
                                  <span className="text-err">
                                    {err.provinceId}
                                  </span>
                                </Col>
                                <Col md={4} className="mb-3">
                                  <Select
                                    options={districts}
                                    styles={customStyles}
                                    placeholder="Chọn quận/ huyện"
                                    value={choiceDistrict}
                                    isDisabled={choiceProvince.value == null}
                                    onChange={(choice) => {
                                      setChoiceDistrict(choice);
                                      getWards(choice.value);
                                      delete err[`districtId`];
                                      delete err[`wardId`];
                                      setChoiceWard({
                                        value: 0,
                                        label: "Chọn phường/ xã",
                                      });
                                    }}
                                  />

                                  <span className="text-err">
                                    {err.districtId}
                                  </span>
                                </Col>
                                <Col md={4} className="mb-3">
                                  <Select
                                    options={wards}
                                    styles={customStyles}
                                    placeholder="Chọn xã/ phường"
                                    value={choiceWard}
                                    isDisabled={choiceDistrict.value == 0}
                                    onChange={(choice) => {
                                      delete err[`wardId`];

                                      setChoiceWard(choice);
                                    }}
                                  />
                                  <span className="text-err">{err.wardId}</span>
                                </Col>
                              </Row>

                              <Row>
                                <Col md={4} className="mb-3">
                                  <Form.Floating className="mb-3">
                                    <Form.Control
                                      onChange={handleInputCustomer}
                                      name="shippingAddress"
                                      type="text"
                                      placeholder="Tên người nhận"
                                      required
                                    />
                                    <Form.Label>
                                      Địa chỉ<i className="text-danger">*</i>
                                    </Form.Label>
                                    <span className="text-err">
                                      {err?.shippingAddress}
                                    </span>
                                  </Form.Floating>
                                </Col>
                                {/* <Col md={4} className="mb-3">
                                  <Select
                                    options={waveHouse}
                                    styles={customStyles}
                                    placeholder="Chọn kho"
                                    value={choiceWaveHouse}
                                    isDisabled={choiceProvince.value == null}
                                    onChange={(choice) => {
                                      delete err[`wardId`];
                                      setChoiceWaveHouse(choice);
                                    }}
                                  />
                                  <span className="text-err">{err.wardId}</span>
                                </Col> */}
                                <Col md={8} className="mb-3">
                                  <Form.Floating className="mb-3">
                                    <Form.Control
                                      onChange={handleInputCustomer}
                                      name="note"
                                      type="text"
                                      placeholder="Tên người nhận"
                                    />
                                    <Form.Label>
                                      Ghi chú<i className="text-danger"></i>
                                    </Form.Label>
                                    <span className="text-err">
                                      {err?.note}
                                    </span>
                                  </Form.Floating>
                                </Col>{" "}
                                <Col
                                  className="mb-2 col-md-3 col-12 col-sm-12"
                                  style={{ zIndex: 6 }}
                                ></Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-7 col-12">
                          <div className="right">
                            <ul>
                              <li>
                                Tổng{" "}
                                <span>{priceFormatter.format(getSum())} ₫</span>
                              </li>
                              <li>
                                Vận chuyển<span>Miễn phí</span>
                              </li>
                              <li className="last">
                                Thành tiền
                                <span>{priceFormatter.format(getSum())} ₫</span>
                              </li>
                            </ul>
                            <div className="button5">
                              <Button className="btn" type="submit">
                                Đặt hàng
                              </Button>
                              <Link to="/" className="btn">
                                Tiếp tục mua sắm
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* <!--/ End Shopping Cart --> */}
    </>
  );
}

export default Cart;
