import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const RoleModal = ({ show, onHide, data, type, permission }) => {
  useEffect(() => {}, []);

  const [roleName, setRoleName] = useState(data?.roleName || "");
  const [isCheckGroup, setIsCheckGroup] = useState([]);
  const [permissionCheck, setPermissionCheck] = useState(
    data?.Permissions?.map((item) => {
      return String(item.id);
    }) || []
  );

  const [err, setErr] = useState({});
  const handleSubmit = (event) => {
    event.preventDefault();
    if (type == "CN") updateCategory();
    else createCategory();
  };
  const hanldeCheck = (e) => {
    const { value, checked } = e.target;
    let dataInput = { ...permissionCheck };
    dataInput = [...permissionCheck, value];
    if (!checked) {
      dataInput = dataInput.filter((item) => item !== value);
    }
    setPermissionCheck([...new Set(dataInput)]);
  };
  const handleSelectGroup = (e) => {
    const { value, checked } = e.target;
    const idChildArr = e.target.dataset?.id_child?.split(",");

    if (idChildArr.length > 1) {
      var dataInput = [...permissionCheck];
      dataInput = [...dataInput, ...idChildArr];
      setPermissionCheck([...new Set(dataInput)]);
      if (!checked) {
        idChildArr.map((id, i) => {
          dataInput = dataInput?.filter((item) => item != id);
          setPermissionCheck([...new Set(dataInput)]);
          return id;
        });
      }
    } else {
      var dataInput = [...permissionCheck];
      dataInput = [...dataInput, value];
      setPermissionCheck([...new Set(dataInput)]);
      if (!checked) {
        setPermissionCheck([
          ...new Set(dataInput?.filter((item) => item != value)),
        ]);
      }
    }
    setIsCheckGroup([...isCheckGroup, String(value)]);

    if (!checked) {
      setIsCheckGroup(isCheckGroup?.filter((item) => item !== String(value)));
    }
  };
  const RenderPermission = () => {
    return (
      <Row>
        <Modal.Title>Quyền truy cập</Modal.Title>
        {permission.length > 0
          ? permission.map((item, index) => {
              let perChild = [];
              let idPerChild = [];
              let checkedParent = false;

              if (isCheckGroup?.includes(String(item.id))) {
                checkedParent = true;
              }

              if (item.children?.length > 0) {
                let n = 0;
                item.children?.map((itemChild, i) => {
                  idPerChild.push(String(itemChild.id));
                  let checkedChild = permissionCheck.includes(
                    String(itemChild.id)
                  );

                  n = checkedChild ? n + 1 : n;
                  if (
                    itemChild.permissionName != "Thêm người dùng" &&
                    itemChild.permissionName != "Thêm đơn hàng"
                  )
                    perChild.push(
                      <Form.Check
                        key={"per-child-" + itemChild.id}
                        className="ms-4"
                        type="checkbox"
                        label={itemChild.permissionName}
                        name="permission"
                        value={itemChild.id}
                        onChange={hanldeCheck}
                        checked={checkedChild}
                      ></Form.Check>
                    );
                });

                if (item.children?.length == n) {
                  checkedParent = true;
                }
              } else {
                if (
                  permissionCheck &&
                  permissionCheck?.includes(String(item.id))
                ) {
                  checkedParent = true;
                }
              }

              return (
                <Col md={4} className="mt-1" key={"per-parent-" + item.id}>
                  <h5>
                    <Form.Check
                      type="checkbox"
                      label={item.permissionName}
                      value={String(item.id)}
                      data-id_child={idPerChild}
                      onChange={handleSelectGroup}
                      checked={checkedParent}
                    ></Form.Check>
                  </h5>

                  {perChild}
                </Col>
              );
            })
          : null}
      </Row>
    );
  };
  const createCategory = () => {
    let data = {
      roleName: roleName,
      permissionIds: permissionCheck?.map((id) => {
        return Number(id);
      }),
    };

    axios
      .post("/admin/create-role", data)

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          onHide();
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErr(error.response?.data?.messages ?? "");
      });
  };
  const updateCategory = () => {
    let dataUpdate = {
      roleId: data.id || "",
      roleName: roleName,
      permissionIds: permissionCheck?.map((id) => {
        return Number(id);
      }),
    };
    axios
      .put("/admin/update-role", dataUpdate)

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          onHide();
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErr(error.response?.data?.messages ?? "");
      });
  };
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        {type == "TM" ? "Thêm vai trò" : "Chi tiết vai trò"}
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  value={roleName ?? ""}
                  name="roleName"
                  id="roleName"
                  placeholder="Tên vai trò"
                  onChange={(e) => {
                    setRoleName(e.target.value);
                    setErr({
                      ...err,
                      roleName: "",
                    });
                  }}
                  required
                />
                <Form.Label>
                  Tên vai trò<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err.roleName}</span>
              </Form.Floating>
            </Col>
            {RenderPermission()}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} variant={"secondary"}>
            {" "}
            Đóng
          </Button>
          <Button type="submit" variant={type == "TM" ? "success" : "primary"}>
            {type == "TM" ? "Lưu" : "Cập nhật"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default RoleModal;
