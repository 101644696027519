import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  FloatingLabel,
  Button,
  Form,
  Table,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { priceFormatter } from "../other/Format";

const OrderModal = ({ show, onHide, data, type, statusList }) => {
  useEffect(() => {
    getCategories();
  }, []);

  const [dataInput, setDataInput] = useState(data);
  const [err, setErr] = useState({});
  const [status, setStatus] = useState(statusList);
  const [waveHouse, setWaveHouse] = useState([]);

  const handleInput = (event) => {
    const field = event.target.name;
    let value = event.target.value;
    const newDataInput = { ...dataInput };
    newDataInput[field] = value;
    setDataInput(newDataInput);
    const newErr = { ...err };
    delete newErr[field];
    setErr(newErr);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    updateOrder();
  };
  const getCategories = () => {
    axios
      .get(`admin/warehouses`)
      .then((res) => {
        setWaveHouse(
          res.data.data?.map((province) => {
            return { value: province.id, label: province.name };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateOrder = () => {
    const newCategory = {
      orderId: dataInput.id,
      email: dataInput.email,
      name: dataInput.name,
      shippingAddress: dataInput.shippingAddress,
      phoneNumber: dataInput.phoneNumber,
      note: dataInput.note,
      warehouseId: Number(dataInput.warehouseId) || null,
      status: dataInput.status,
      // categoryParentId:dataInput.categoryParentId
    };
    axios
      .put("/admin/update-order", newCategory)

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          onHide(true);
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErr(error.response?.data?.messages ?? "");
      });
  };
  const optionStatus = () => {
    if (status.length > 0) {
      return status.map((item, key) => {
        return (
          <option key={`topicOption${key}`} value={item.value}>
            {item.label}
          </option>
        );
      });
    }
  };
  const optionWaveHouse = () => {
    if (waveHouse.length > 0) {
      return waveHouse.map((item, key) => {
        return (
          <option key={`topicOption${key}`} value={item.value}>
            {item.label}
          </option>
        );
      });
    }
  };
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        Chi tiết đơn hàng - {data.trackingNumber}
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  value={dataInput.name ?? ""}
                  name="name"
                  id="name"
                  placeholder="Tên danh mục"
                  onChange={handleInput}
                  required
                />
                <Form.Label>
                  Tên khách hàng<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err.name}</span>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  //   as="textarea"
                  value={dataInput.shippingAddress ?? ""}
                  onChange={handleInput}
                  name="shippingAddress"
                  id="shippingAddress"
                  placeholder="Tên danh mục"
                  required
                />
                <Form.Label>
                  Địa chỉ<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err.shippingAddress}</span>
              </Form.Floating>
            </Col>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  //   as="textarea"
                  value={dataInput.email ?? ""}
                  onChange={handleInput}
                  name="email"
                  id="email"
                  placeholder="Tên danh mục"
                  required
                />
                <Form.Label>
                  Email<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err.email}</span>
              </Form.Floating>
            </Col>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  //   as="textarea"
                  value={dataInput.phoneNumber ?? ""}
                  onChange={handleInput}
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Tên danh mục"
                  required
                />
                <Form.Label>
                  Số điện thoại<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err.phoneNumber}</span>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  //   as="textarea"
                  value={dataInput.note ?? ""}
                  onChange={handleInput}
                  name="note"
                  id="note"
                  placeholder="Tên danh mục"
                />

                <Form.Label>
                  Ghi chú<i className="text-danger"></i>
                </Form.Label>
                <span className="text-err">{err.note}</span>
              </Form.Floating>
            </Col>
            <Col>
              <FloatingLabel
                label={
                  <>
                    Trạng thái<i className="text-danger">*</i>
                  </>
                }
                className="mb-3"
              >
                <Form.Select
                  onChange={handleInput}
                  value={dataInput.status || "DEFAULT"}
                  name="status"
                  required
                  className={err.status ? "is-invalid" : ""}
                >
                  <option value="DEFAULT">-- Chọn --</option>
                  {optionStatus()}
                </Form.Select>

                <span className="text-err">
                  {err.status ? "Không được để trống" : ""}
                </span>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                label={
                  <>
                    Chọn kho<i className="text-danger"></i>
                  </>
                }
                className="mb-3"
              >
                <Form.Select
                  onChange={handleInput}
                  value={dataInput.warehouseId || null}
                  name="warehouseId"
                  className={err.warehouseId ? "is-invalid" : ""}
                >
                  <option value={null}>-- Chọn --</option>
                  {optionWaveHouse()}
                </Form.Select>

                <span className="text-err">
                  {err.warehouseId ? "Không được để trống" : ""}
                </span>
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="ms-1 me-1">
            <Table bordered hover style={{ border: "1px solid" }}>
              <thead>
                <tr>
                  <th colSpan={5} className="text-center">
                    {" "}
                    Danh sách sản phẩm
                  </th>
                </tr>

                <tr className="text-center">
                  <th>STT</th>
                  <th>Tên sản phẩm</th>

                  <th>Giá</th>
                  <th>Số lượng</th>

                  <th>Thành tiền</th>
                </tr>
              </thead>
              {data?.OrderProducts?.length > 0 ? (
                <>
                  <tbody>
                    {data?.OrderProducts?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.Product?.productName}</td>
                          <td className="text-end">
                            {priceFormatter.format(item?.purchasePrice ?? 0)}
                          </td>
                          <td className="text-end">{item?.quantity}</td>
                          <td className="text-end">
                            {priceFormatter.format(
                              item?.purchasePrice * item?.quantity || 0
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <th colSpan={4} className="text-center">
                      Tổng
                    </th>
                    <th className="text-end">
                      {priceFormatter.format(data?.totalAmount ?? 0)}
                    </th>
                  </tfoot>
                </>
              ) : (
                <p className="text-center">Không có dữ liệu . . .</p>
              )}
            </Table>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} variant="secondary">
            {" "}
            Đóng
          </Button>
          <Button type="submit">{type == "TM" ? "Lưu" : "Cập nhật"}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default OrderModal;
