import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";

// import ShowImg from './Showimage';
import Footer from "../../../layouts/frontend/footer/Footer";
import NavbarMiddle from "../../../layouts/frontend/navbarMiddle/Navbar_middle";

import Navbar from "../../../layouts/frontend/navbar/NavBar_N";
import axios from "axios";

function HomeIndex() {
  const [setting, setSettings] = useState([]);
  useEffect(() => {
    document.title = "Gen A";
    window.scrollTo({ top: 0, behavior: "smooth" });
    getSetting();
  }, []);

  const getSetting = () => {
    axios
      .get(`settings`)
      .then((res) => {
        setSettings(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {setting?.length > 0 ? <NavbarMiddle setting={setting} /> : null}
      <Navbar />

      <main>
        <Container>
          <Outlet />
        </Container>
      </main>

      <Footer />
    </>
  );
}

export default HomeIndex;
