import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { priceFormatter } from "../../admin/other/Format";
import "./product.css";
function ProductItemNew(data) {
  const tensp = data?.item?.productName;
  const salePrice = priceFormatter.format(data?.item?.salePrice);
  const price = priceFormatter.format(data?.item?.price);
  const image =
    data?.item?.mainImage?.imageLink ??
    data?.item?.mainImage?.imageSrc ??
    data.logo;
  const navigate = useNavigate();

  return (
    <div className="single-post first">
      <div className="image">
        <img src={image} alt="#" />
      </div>
      <div className="content">
        <h5>
          <Link to={`/product-detail/${data?.item?.productSlug}`}>{tensp}</Link>
        </h5>
        <p className="price">{salePrice} ₫</p>
        <p>
          <i className="ti-star " />
          <i className="ti-star" /> <i className="ti-star" />{" "}
          <i className="ti-star" />
          <i className="ti-star" />
        </p>
      </div>
    </div>
  );
}

export default ProductItemNew;
