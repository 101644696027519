import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Card, Button, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const CategoryModal = ({ show, onHide, data, type }) => {
  useEffect(() => {}, []);

  const [dataInput, setDataInput] = useState(data);
  const [err, setErr] = useState({});
  const handleInput = (event) => {
    const field = event.target.name;
    let value = event.target.value;
    const newDataInput = { ...dataInput };
    if (field == "status") value = event.target.checked ? "S1" : "S2";
    newDataInput[field] = value;
    setDataInput(newDataInput);
    const newErr = { ...err };
    delete newErr[field];
    setErr(newErr);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (type == "CN") updateCategory();
    else createCategory();
  };
  const createCategory = () => {
    const newCategory = {
      categoryName: dataInput.categoryName ?? "",
      type: dataInput.type ?? "",
      image: dataInput.image,
      categoryPriority: dataInput.categoryPriority ?? "",
      categoryContent: dataInput.categoryContent ?? "",
      categoryKeyword: dataInput.categoryKeyword ?? "",
      status: dataInput.status ?? "",
      // categoryParentId:dataInput.categoryParentId
    };
    axios
      .post("/admin/create-category", newCategory)

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          onHide();
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErr(error.response?.data?.messages ?? "");
      });
  };
  const updateCategory = () => {
    const newCategory = {
      categoryId: dataInput.id,
      categoryName: dataInput.categoryName,
      type: dataInput.type,
      image: dataInput.image,
      categoryPriority: dataInput.categoryPriority,
      categoryContent: dataInput.categoryContent,
      categoryKeyword: dataInput.categoryKeyword,
      status: dataInput.status,
      // categoryParentId:dataInput.categoryParentId
    };
    axios
      .put("/admin/update-category", newCategory)

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          onHide();
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErr(error.response?.data?.messages ?? "");
      });
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {type == "TM" ? "Thêm danh mục" : "Chi tiết danh mục"}
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  value={dataInput.categoryName ?? ""}
                  name="categoryName"
                  id="categoryName"
                  placeholder="Tên danh mục"
                  onChange={handleInput}
                  required
                />
                <Form.Label>
                  Tên danh mục<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err.categoryName}</span>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  as="textarea"
                  value={dataInput.categoryContent ?? ""}
                  onChange={handleInput}
                  name="categoryContent"
                  id="categoryContent"
                  placeholder="Tên danh mục"
                  rows={Math.ceil(
                    dataInput?.categoryContent?.split("\n")?.length
                  )}
                />
                <Form.Label>
                  Nội dung<i className="text-danger"></i>
                </Form.Label>
                <span className="text-err">{err.categoryContent}</span>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  value={dataInput.categoryKeyword ?? ""}
                  name="categoryKeyword"
                  id="categoryKeyword"
                  onChange={handleInput}
                  placeholder="Tên danh mục"
                />
                <Form.Label>
                  Từ khóa<i className="text-danger"></i>
                </Form.Label>
                <span className="text-err">{err.categoryKeyword}</span>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="number"
                  value={dataInput.categoryPriority ?? ""}
                  name="categoryPriority"
                  onChange={handleInput}
                  id="categoryPriority"
                  placeholder="Độ ưu tiên"
                />
                <Form.Label>
                  Độ ưu tiên<i className="text-danger"></i>
                </Form.Label>
                <span className="text-err">{err.categoryPriority}</span>
              </Form.Floating>
            </Col>
            <Col className="align-middle mt-3">
              <Form.Check
                className="mb-3"
                type="checkbox"
                onChange={handleInput}
                label="Hiện lên trang chủ"
                name="status"
                checked={dataInput.status == "S1"}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} variant={"secondary"}>
            {" "}
            Đóng
          </Button>
          <Button type="submit" variant={type == "TM" ? "success" : "primary"}>
            {type == "TM" ? "Lưu" : "Cập nhật"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CategoryModal;
