import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, FloatingLabel, Form, Image, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import TextEditor from "../other/TextEditor";

const PostModal = (props) => {
  const navigate = useNavigate();
  const id = props.idEdit || "";
  const [post, setPost] = useState(props.data);
  useEffect(() => {
    getCategories();
    setContentVi(props.data?.postContent ?? "<p></p>");
    setPost({
      ...post,
      image: props.data?.image?.imageSrc ?? props.data?.image?.imageLink ?? "",
    });
  }, [navigate]);

  const [topics, setTopics] = useState([]);

  const [errors, setErrors] = useState([]);
  const [contentVi, setContentVi] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImage1, setSelectedImage1] = useState();
  const getCategories = () => {
    axios
      .get(`admin/categories?type=cPost`)
      .then((res) => {
        setTopics(res.data.data?.categories);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
          handleClose();
        }
      });
  };

  const handleClose = () => props.onHide();

  const handleInput = (e) => {
    let value = e.target.value;
    let field = e.target.name;
    post[field] = value;
    delete errors[field];
    setPost({ ...post });

    setErrors({ ...errors });
  };
  const handleSelectFile = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setSelectedImage(base64String);
      };
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];

        setSelectedImage(base64String);
      };
      reader.readAsDataURL(e.target.files[0]);
      setSelectedImage1(e.target.files[0]);
    }
  };
  const handleChange = (value) => {
    setContentVi(value);
    delete errors.postContent;
    // setErrors({ ...errors });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.type == "CN") updatePost();
    else createPost();
  };
  const createPost = () => {
    const newPost = {
      categoryId: post.categoryId ?? "",
      postTitle: post.postTitle ?? "",
      postDescription: post.postDescription ?? "",
      postContent: contentVi ?? "<p></p>",
      status: "S1",
      postKeyword: post.postKeyword ?? "",
    };
    const formData = new FormData();
    for (const key in newPost) {
      formData.append(key, newPost[key]);
    }
    if (selectedImage) {
      formData.append("image", selectedImage1);
    } else if (post.image != "" || post.image != undefined) {
      formData.append("imageSrc", post.image);
    }

    axios
      .post("/admin/create-post", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          props.onHide(true);
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErrors(error.response?.data?.messages ?? "");
      });
  };
  const updatePost = () => {
    const newPost = {
      categoryId: post.categoryId ?? "",
      postId: post.id,
      postTitle: post.postTitle ?? "",
      postDescription: post.postDescription ?? "",
      postContent: contentVi ?? "<p></p>",
      status: "S1",
      postKeyword: post.postKeyword ?? "",
    };
    const formData = new FormData();
    for (const key in newPost) {
      formData.append(key, newPost[key]);
    }
    if (selectedImage) {
      formData.append("image", selectedImage1);
    }
    if (post.image != "") {
      formData.append("imageSrc", post.image);
    }
    axios
      .put("/admin/update-post", formData)

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          props.onHide(true);
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErrors(error.response?.data?.messages ?? "");
      });
  };
  const optionTopics = () => {
    if (topics.length > 0) {
      return topics.map((item, key) => {
        return (
          <option key={`topicOption${key}`} value={item.id}>
            {item.categoryName}
          </option>
        );

        // selected={item.id === post.topicId ? true : false}
      });
    }
  };
  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto"; // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set it to the scrollHeight
  };
  return (
    <>
      <Modal size="lg" show={props.show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.type == "TM" ? "Thêm bài viết" : "Chi tiết bài viết"}
          </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Row>
              <Col>
                <FloatingLabel
                  label={
                    <>
                      Chủ đề<i className="text-danger">*</i>
                    </>
                  }
                  className="mb-3"
                >
                  <Form.Select
                    onChange={handleInput}
                    value={post.categoryId || "DEFAULT"}
                    name="categoryId"
                    required
                    className={errors.categoryId ? "is-invalid" : ""}
                  >
                    <option value="DEFAULT">-- Chọn --</option>
                    {optionTopics()}
                  </Form.Select>

                  <span className="text-err">
                    {errors.categoryId ? errors.categoryId : ""}
                  </span>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  {" "}
                  <label
                    className=""
                    style={{
                      fontSize: 12,
                      cursor: "pointer",
                      border: "1px dashed rgb(197 197 197)",
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    htmlFor="banner-top"
                    title={`${
                      post.image
                        ? "Xóa link hình ảnh trước khi tải file"
                        : "Chọn hình ảnh"
                    }`}
                  >
                    {" "}
                    + Thêm banner bài viết
                  </label>
                  <input
                    type="file"
                    disabled={post.image ? true : false}
                    id="banner-top"
                    className="d-none"
                    accept="image/*"
                    multiple={false}
                    onChange={(e) => handleSelectFile(e)}
                  />
                </div>
              </Col>
              <Col>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    onChange={handleInput}
                    name="image"
                    value={post.image}
                    disabled={selectedImage ? true : false}
                    className={errors.postTitle ? "is-invalid" : ""}
                    placeholder="Tên chủ đề"
                  />
                  <Form.Label>
                    Link hình ảnh<i className="text-danger"></i>
                  </Form.Label>
                  <span className="text-err">
                    {errors.postTitle ? errors.postTitle : ""}
                  </span>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                {selectedImage ? (
                  <>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      style={{ float: "right", marginTop: "-20px" }}
                      onClick={(e) => setSelectedImage()}
                    >
                      X
                    </Button>
                    <Image
                      className="mb-3"
                      src={`data:image/jpeg;base64,${selectedImage}`}
                      thumbnail
                      height={"60px"}
                      style={{ width: "100%", aspectRatio: "5/3" }}
                    />
                  </>
                ) : null}
              </Col>

              <Col md={6}>
                {post.image ? (
                  <Image
                    className="mb-3"
                    src={post.image}
                    thumbnail
                    height={"60px"}
                    style={{ width: "100%", aspectRatio: "5/3" }}
                  />
                ) : null}
              </Col>
            </Row>

            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                onChange={handleInput}
                name="postTitle"
                value={post.postTitle}
                className={errors.postTitle ? "is-invalid" : ""}
                placeholder="Tên chủ đề"
              />
              <Form.Label>
                Tiêu đề<i className="text-danger">*</i>
              </Form.Label>
              <span className="text-err">
                {errors.postTitle ? errors.postTitle : ""}
              </span>
            </Form.Floating>

            <Row>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    as="textarea"
                    // onChange={handleInput}
                    onChange={(e) => {
                      handleInput(e);
                      adjustTextareaHeight(e.target);
                    }}
                    value={post.postKeyword}
                    name="postKeyword"
                    placeholder="Từ khóa"
                    style={{
                      height: "max-content",
                    }}
                  />
                  <Form.Label>
                    Từ khóa<i className="text-danger"></i>
                  </Form.Label>
                  <span className="text-err">
                    {errors.postKeyword ? errors.postKeyword : ""}
                  </span>
                </Form.Floating>
              </Col>

              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    as="textarea"
                    onChange={(e) => {
                      handleInput(e);
                      adjustTextareaHeight(e.target);
                    }}
                    value={post.postDescription}
                    name="postDescription"
                    placeholder="Mô tả"
                    style={{
                      height: "max-content",
                    }}
                    required
                  />
                  <Form.Label>
                    Mô tả<i className="text-danger">*</i>
                  </Form.Label>
                  <span className="text-err">
                    {errors.postDescription ? errors.postDescription : ""}
                  </span>
                </Form.Floating>
              </Col>
            </Row>

            <Form.Label>
              Nội dung<span className="text-danger">*</span>
            </Form.Label>

            {isLoading ? (
              <i>Đang tải...</i>
            ) : (
              <TextEditor
                setContents={contentVi}
                required
                onChange={(e) => handleChange(e)}
                placeholder="Nội dung"
                name="postContent"
              />
            )}

            <span className="text-err">
              {errors.postContent ? errors.postContent : ""}
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Đóng
            </Button>
            <Button
              variant={props.type == "TM" ? "success" : "primary"}
              type="submit"
            >
              {props.type == "TM" ? "Lưu" : "Cập nhật"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default PostModal;
