import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Row, Col, Card, Button } from "react-bootstrap";
import WarehouseModal from "./WarehouseModal";
import { toast } from "react-toastify";
import axios from "axios";
import DataTables from "../datatable/DataTables";
import { useNavigate } from "react-router-dom";
const WarehouseIndex = () => {
  const [show, setShow] = useState(false);
  const [showInf, setShowInf] = useState(false);
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    address: "",
    provinceId: "",
    districtId: "",
    wardId: "",
  });
  const onHide = () => {
    setShow(false);
    setShowInf(false);
    getCategories();
  };
  useEffect(() => {
    document.title = "Quản lý kho";
    getCategories();
  }, []);
  const getCategories = () => {
    axios
      .get(`admin/warehouses`)
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          navigate("/admin/access-denied");
        }
        console.log(err);
      });
  };

  const getCategoryBySlug = (item) => {
    setData(item ?? {});
    setShowInf(true);
  };
  const deleteCategory = (id) => {
    axios
      .delete(`/admin/delete-warehouse/${id}`)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 1000 });
        getCategories();
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        } else
          toast(err.response?.data?.message || "Xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
      });
  };
  const renderTable = () => {
    const columns = [
      {
        name: "Tên kho",
        selector: (row) => row.name,
        cell: (row) => <div className="text-wrap">{row.name}</div>,
      },
      {
        name: "Phường/ xã",
        selector: (row) => row.Ward,
        cell: (row) => <div className="text-wrap">{row.Ward?.name}</div>,
      },
      {
        name: "Quận/ huyện",
        selector: (row) => row.District,
        cell: (row) => <div className="text-wrap">{row.District?.name}</div>,
      },
      {
        name: "Tỉnh/ thành phố",
        selector: (row) => row.Province,
        cell: (row) => <div className="text-wrap">{row.Province?.name}</div>,
      },

      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];
    const data = [];
    if (categories?.length > 0)
      categories?.map((item) => {
        data?.push({
          ...item,
          // categoryName: item.categoryName,
          // categoryContent: item.categoryContent,
          // categoryKeyword: item.categoryKeyword,
          // categoryPriority: item.categoryPriority,
          setting: (
            <>
              <Button
                className="me-2"
                size="sm"
                onClick={() => getCategoryBySlug(item)}
                title="Chi tiết"
              >
                <i className="fa-solid fa-circle-info"></i>
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => deleteCategory(item.id)}
                title="Xóa"
              >
                <i className="fas fa-trash"></i>
              </Button>
            </>
          ),
        });
      });

    return <DataTables data={data} columns={columns} />;
  };
  return (
    <div className="container">
      <Breadcrumb className="mb-2 mt-2 " style={{ display: "contents" }}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Tổng quan
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Quản lý kho hàng</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header>
              <Button
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => {
                  setData({
                    name: "",
                    address: "",
                    provinceId: "",
                    districtId: "",
                    wardId: "",
                  });
                  setShow(true);
                }}
              >
                <i className="fa-solid fa-plus"></i> Thêm
              </Button>
              <Card.Title>
                <i className="fas fa-list me-1"></i> Danh sách kho
              </Card.Title>
            </Card.Header>
            <Card.Body>{renderTable()}</Card.Body>
          </Card>
        </Col>
      </Row>
      {show ? (
        <WarehouseModal show={show} onHide={onHide} data={data} type={"TM"} />
      ) : null}
      {showInf ? (
        <WarehouseModal
          show={showInf}
          onHide={onHide}
          data={data}
          type={"CN"}
        />
      ) : null}
    </div>
  );
};

export default WarehouseIndex;
