import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

// import Logo from '../../assets/client/images/logo.png'
import "./sidebar.css";
import ProductItem from "../product/ProductItem";
import { Row, Col } from "react-bootstrap";

import ProductItemNew from "../product/productItemNew";

import axios from "axios";
function SideBar() {
  const [category, setCategory] = useState([]);
  const [choiceCate, setChoiceCate] = useState([]);
  const [logo, setLogo] = useState();
  const [products, setProducts] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [sortBy, setSortBy] = useState("name");
  const [limit, setLimit] = useState(9);
  const [selected, setSelected] = useState(null);
  const { category_slug } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryParams = new URLSearchParams(location.category);
  const searchKeyword = searchParams.get("search");
  useEffect(() => {
    getCategories();

    getLogo();
  }, []);

  useEffect(() => {
    if (choiceCate) {
      setListProduct(products?.filter((p) => p.categoryId == choiceCate?.id));
    }
  }, [choiceCate]);

  useEffect(() => {
    getProducts(searchKeyword);
  }, [searchKeyword, category]);

  useEffect(() => {}, [category_slug]);
  useEffect(() => {
    const sortedProducts = [...listProduct];

    if (sortBy === "ten") {
      sortedProducts.sort((a, b) => a.productName.localeCompare(b.productName));
    } else if (sortBy === "price") {
      sortedProducts.sort((a, b) => a.salePrice - b.salePrice);
    }

    setListProduct(sortedProducts);
  }, [sortBy]);
  useEffect(() => {
    setListProduct(listProduct.slice(0, limit));
  }, [limit]);
  useEffect(() => {
    if (selected) getProductsbyPrice(selected);
    else getProducts();
  }, [selected]);
  const getLogo = () => {
    axios
      .get(`settings?names=logo`)
      .then((res) => {
        setLogo(res.data.data?.filter((e) => e.name == "logo")[0]?.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getProductsbyPrice = (type) => {
    let minPrice = "";
    let maxPrice = "";
    if (type == 1) {
      minPrice = 0;
      maxPrice = 500000;
    } else if (type == 2) {
      minPrice = 500000;
      maxPrice = 1000000;
    } else if (type == 3) {
      minPrice = 1000000;
      maxPrice = 10000000000;
    } else {
      minPrice = null;
      maxPrice = null;
    }

    axios
      .get(`products?minPrice=${minPrice}&maxPrice=${maxPrice}`)
      .then((res) => {
        setProducts(res.data.data?.products);
        setListProduct(res.data.data?.products);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCategories = () => {
    axios
      .get(`categories?type=cPro`)
      .then((res) => {
        setCategory(res.data.data?.categories);
        getProducts();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProducts = (search) => {
    let categoryId = category?.filter((e) => e.categorySlug == category_slug)[0]
      ?.id;
    axios
      .get(`products?search=${search ?? ""}`)
      .then((res) => {
        setProducts(res.data.data?.products);
        if (category_slug) {
          setListProduct(
            res.data.data?.products?.filter((p) => p.categoryId == categoryId)
          );
        } else setListProduct(res.data.data?.products);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLatestProducts = (products) => {
    products.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return products.slice(0, 3);
  };
  const handleCheckboxChange = (e) => {
    if (selected === e.target.id) {
      // Uncheck the checkbox if it is already checked
      setSelected(null);
    } else {
      // Check the new checkbox
      setSelected(e.target.id);
    }
  };
  return (
    <section className="product-area shop-sidebar shop section">
      <div className="container mb-3">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-12">
            <div className="shop-sidebar ">
              {/* <!-- Single Widget --> */}
              <div className="single-widget category">
                <h3 className="title">Danh mục</h3>
                <ul className="categor-list">
                  {category
                    ?.sort((a, b) => a.categoryPriority - b.categoryPriority)
                    ?.map((item) => {
                      return (
                        <li>
                          <Link to={"#"} onClick={() => setChoiceCate(item)}>
                            {item.categoryName}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
              {/* <!--/ End Single Widget --> */}
              {/* <!-- Shop By Price --> */}
              <div className="single-widget range  ">
                <h3 className="title">Giá sản phẩm</h3>

                <div className="check-box-list">
                  <li>
                    <label className="checkbox-inline" htmlFor="1">
                      <input
                        name="news"
                        id="1"
                        type="checkbox"
                        checked={selected === "1"}
                        onChange={handleCheckboxChange}
                      />{" "}
                      {"< 500.000 ₫"}
                      {/* <span className="count">(3)</span> */}
                    </label>
                  </li>
                  <li>
                    <label className="checkbox-inline" htmlFor="2">
                      <input
                        name="news"
                        id="2"
                        type="checkbox"
                        checked={selected === "2"}
                        onChange={handleCheckboxChange}
                      />
                      500.000 ₫ - 1.000.000 ₫
                      {/* <span className="count">(5)</span> */}
                    </label>
                  </li>
                  <li>
                    <label className="checkbox-inline" htmlFor="3">
                      <input
                        name="news"
                        id="3"
                        type="checkbox"
                        checked={selected === "3"}
                        onChange={handleCheckboxChange}
                      />
                      {"> 1.000.000 ₫"}
                      {/* <span className="count">(8)</span> */}
                    </label>
                  </li>
                </div>
              </div>
              {/* <!--/ End Shop By Price --> */}
              {/* <!-- Single Widget --> */}
              <div className="single-widget recent-post">
                <h3 className="title">Sản Phẩm mới về</h3>
                {getLatestProducts(products)?.map((item) => {
                  return <ProductItemNew item={item} logo={logo} />;
                })}
              </div>

              {/* <!--/ End Single Widget --> */}
            </div>
          </div>
          <div className="col-lg-9 col-md-8 col-12">
            <div className="row">
              <div className="col-12">
                {/* <!-- Shop Top --> */}
                <div className="shop-top">
                  <div className="shop-shorter">
                    <div className="single-shorter">
                      <label>Hiển thị :</label>
                      <select onChange={(e) => setLimit(e.target.value)}>
                        <option defaultValue="selected" value="9">
                          09
                        </option>
                        <option value={"15"}>15</option>
                        <option value="25">25</option>
                        <option value={"30"}>30</option>
                      </select>
                    </div>
                    <div className="single-shorter">
                      <label>Sắp xếp :</label>
                      <select onChange={(e) => setSortBy(e.target.value)}>
                        <option defaultValue="selected" value={"ten"}>
                          Tên
                        </option>

                        <option value={"price"}>Giá</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="view-mode">
                    <li className="active">
                      <Link to={"/"}>
                        <i className="fa fa-th-large"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/"}>
                        <i className="fa fa-th-list"></i>
                      </Link>
                    </li>
                  </div> */}
                </div>
                {/* <!--/ End Shop Top --> */}
              </div>
            </div>
            <Row>
              {listProduct?.map((item) => {
                return (
                  <Col md={4} sm={6} xs={6}>
                    <ProductItem item={item} logo={logo} />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SideBar;
