import React from "react";
import { Carousel } from "react-bootstrap";

import "./pageHero.css";
const PageHero = (data) => {
  return (
    <section className="page-hero">
      <Carousel indicators={false}>
        {data?.banner?.map((item) => {
          if (item)
            return (
              <Carousel.Item interval={5000}>
                <img className="banner" src={item} alt={"banner"} />
              </Carousel.Item>
            );
        })}
      </Carousel>
    </section>
  );
};

export default PageHero;
