import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Row, Col, Card, Button } from "react-bootstrap";
import ProductModal from "./ProductModal";
import { toast } from "react-toastify";
import axios from "axios";
import DataTables from "../datatable/DataTables";
import { useNavigate } from "react-router-dom";

const ProductIndex = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const [showInf, setShowInf] = useState(false);
  const [posts, setPosts] = useState([]);
  const [data, setData] = useState({
    productName: "",
    productKeyword: "",
    productDescription: "",
    productContent: "",
    productPriority: "",
    status: "S1",
    categoryId: "",
    price: "",
    salePrice: "",
  });
  const onHide = (isLoading) => {
    setShow(false);
    setShowInf(false);
    if (isLoading) {
      getProducts();
    }
  };
  useEffect(() => {
    getProducts();
    document.title = "Quản lý sản phẩm";
  }, []);
  const getProducts = () => {
    axios
      .get(`admin/products`)
      .then((res) => {
        setPosts(res.data.data?.products);
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          navigate("/admin/access-denied");
        }
        console.log(err);
      });
  };
  const getPostBySlug = (slug) => {
    axios
      .get(`admin/product/${slug}`)
      .then((res) => {
        setData(res.data.data ?? {});
        setShowInf(true);
      })
      .catch((err) => {
        toast(err.data.message, { type: "error", autoClose: 1000 });
        console.log(err);
      });
  };
  const deletePost = (id) => {
    axios
      .delete(`/admin/delete-product/${id}`)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 1000 });
        getProducts();
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        } else
          toast(err.response?.data?.message || "Xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });

        console.log(err);
      });
  };
  const renderTable = () => {
    const columns = [
      {
        name: "Tên sản phẩm",
        selector: (row) => row.productName,
        cell: (row) => <div className="two-line-limit">{row.productName}</div>,
      },

      {
        name: "Mô tả",
        selector: (row) => row.productDescription,
        cell: (row) => (
          <div className="two-line-limit">{row.productDescription}</div>
        ),
      },
      {
        name: "Giá bán",
        selector: (row) => row.salePrice,
        cell: (row) => <div className="two-line-limit">{row.salePrice}</div>,
        right: true,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];
    const data = [];
    if (posts?.length > 0)
      posts?.map((item) => {
        data?.push({
          productName: item.productName,
          postContent: item.postContent,
          salePrice: item.salePrice,
          productDescription: item.productDescription,
          setting: (
            <>
              <Button
                className="me-2"
                size="sm"
                onClick={() => getPostBySlug(item.productSlug)}
                title="Chi tiết"
              >
                <i className="fa-solid fa-circle-info"></i>
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => deletePost(item.id)}
                title="Xóa"
              >
                <i className="fas fa-trash"></i>
              </Button>
            </>
          ),
        });
      });

    return <DataTables data={data} columns={columns} />;
  };
  return (
    <div className="container">
      <Breadcrumb className="mb-2 mt-2 " style={{ display: "contents" }}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Tổng quan
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Quản lý sản phẩm</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header>
              <Button
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => {
                  setData({
                    categoryId: "",
                    productDescription: "",
                    postContent: "",
                    productName: "",
                    salePrice: "",
                    status: "S1",
                    image: "",
                  });
                  setShow(true);
                }}
              >
                <i className="fa-solid fa-plus"></i> Thêm
              </Button>
              <Card.Title>
                <i className="fas fa-list me-1"></i> Danh sách sản phẩm
              </Card.Title>
            </Card.Header>
            <Card.Body>{renderTable()}</Card.Body>
          </Card>
        </Col>
      </Row>
      {show ? (
        <ProductModal show={show} onHide={onHide} data={data} type={"TM"} />
      ) : null}
      {showInf ? (
        <ProductModal show={showInf} onHide={onHide} data={data} type={"CN"} />
      ) : null}
    </div>
  );
};

export default ProductIndex;
