import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Nav,
  Row,
  Breadcrumb,
} from "react-bootstrap";
// import BreadcrumbView from "../other/HtmlTag/BreadcrumbView";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "./product.css";
import CurrencyFormat from "react-currency-format";
import { useCart } from "react-use-cart";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import IsLoading from "../../../layouts/IsLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faMinus,
  faPlus,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import ProductItem from "./ProductItem";

const ProductDetail = (props) => {
  const { product_slug } = useParams();
  const [product, setProduct] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [logo, setLogo] = useState();
  const {
    addItem,
    items,
    updateItemQuantity,
    updateCartMetadata,
    metadata,
    cartTotal,
  } = useCart();

  useEffect(() => {
    // document.title = product.product_name || "Sản phẩm";

    window.scrollTo({ top: 0, behavior: "smooth" });

    getProduct(product_slug);
  }, [product_slug]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getLogo();
  }, []);
  const getLogo = () => {
    setIsLoad(true);
    axios
      .get(`settings?names=logo`)
      .then((res) => {
        setLogo(res.data.data?.filter((e) => e.name == "logo")[0]?.payload);
        setIsLoad(false);
      })
      .catch((err) => {
        setIsLoad(true);

        console.log(err);
      });
  };
  const getProduct = (productSlug) => {
    setIsLoad(true);
    axios
      .get(`/product/${productSlug}`)
      .then((res) => {
        if (res.status === 200) {
          setProduct(res.data.data);
          getProductByCategory(res.data.data?.categoryId);
        }
        setIsLoad(false);
      })
      .catch((error) => {
        setIsLoad(false);
        toast(error.message, { type: "error" });
      });
  };
  const getProductByCategory = (id) => {
    setIsLoad(true);
    axios
      .get(`/products?filterByCategory=${id}`)
      .then((res) => {
        if (res.status === 200) {
          setProducts(res.data.data?.products);
        }
        setIsLoad(false);
      })
      .catch((error) => {
        setIsLoad(false);
        toast(error.message, { type: "error" });
      });
  };
  const addToCart = (data) => {
    toast("Đã thêm vào giỏ hàng.", { type: "success", autoClose: 500 });

    // if (!metadata.customer_name || !metadata.discount_code) {
    updateCartMetadata({
      customer_name: "",
      customer_phone: "",
      customer_email: "",
      province_id: "",
      district_id: "",
      ward_id: "",
      customer_address: "",
      contract_desc: "",
      feeshipping: 0,
      discount_code: "",
      discount_money: 0,
      discount_type: "",
      discount_value: "",
      totalMoney: cartTotal,
      error_list: [],
    });
    // }

    return addItem(data);
  };
  // const addToCart = (product) => {
  //   toast("Đã thêm vào giỏ hàng.", { type: "success", autoClose: 500 });

  //   addItem(product);
  // };
  const productInfo = () => {
    const productDetails = product.product_detail ? product.product_detail : [];

    let itemProDt = [];

    if (productDetails.length > 0) {
      productDetails.map((itemDt, iDt) => {
        const price =
          parseInt(itemDt.product_price_sale) > 0
            ? parseInt(itemDt.product_price_sale)
            : parseInt(itemDt.product_price);
        const price_old =
          parseInt(itemDt.product_price_sale) > 0
            ? parseInt(itemDt.product_price)
            : 0;
        let info = "";

        let cartItem = items.filter((itemCart, i) => itemCart.id === itemDt.id);

        let quantity = cartItem.length > 0 ? cartItem[0].quantity : 0;

        if (itemDt.info.length > 0) {
          itemDt.info.map((itemIf, iIf) => {
            let firstchar = iIf === 0 ? "" : " - ";
            info =
              info + firstchar + itemIf.info_name + " " + itemIf.info_dt_name;
            return itemIf;
          });
        }

        itemProDt.push(
          <ListGroup.Item key={`itemProDt${iDt}`}>
            <Row>
              <Col md={6} className="d-flex align-items-center ">
                {itemDt.info.length > 0 ? (
                  <>
                    <strong>{info}</strong>
                    <br />
                  </>
                ) : null}

                {product.salePrice > 0 ? (
                  <CurrencyFormat
                    value={product.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix="₫"
                    renderText={(value) => (
                      <span className="me-2 text-muted fst-italic text-decoration-line-through">
                        {value}
                      </span>
                    )}
                  />
                ) : null}

                <CurrencyFormat
                  value={product.salePrice}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix="₫"
                  renderText={(value) => (
                    <span className="fw-bold fs-6">
                      {value}/{itemDt.product_unit}
                    </span>
                  )}
                />

                {product.salePrice > 0 ? (
                  <span className="fw-bold fst-italic text-danger">
                    {parseInt(
                      ((product.price - product.salePrice) / product.price) *
                        100
                    ) * -1}
                    %
                  </span>
                ) : null}
              </Col>
              <Col md={6} className="text-end">
                {/* {cartItem.length === 0 ? ( */}
                <Button
                  variant="outline-danger"
                  className="float-end"
                  onClick={() => addToCart(product)}
                >
                  <FontAwesomeIcon icon={faCartPlus} />
                  <span className="btn-cart-text"> Thêm giỏ hàng</span>
                </Button>
                {/* ) : ( */}
                <>
                  <InputGroup className="justify-content-end flex-nowrap">
                    <Button
                      variant="outline-secondary"
                      // onClick={(e) =>
                      //   updateItemQuantity(itemDt.id, parseInt(quantity) - 1)
                      // }
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                    <Form.Control
                      className="text-end"
                      type="number"
                      min="0"
                      value={quantity}
                      // onChange={(e) =>
                      //   updateItemQuantity(itemDt.id, e.target.value)
                      // }
                      style={{ maxWidth: "72px", minWidth: "72px" }}
                    />
                    <Button
                      variant="outline-secondary"
                      // onClick={(e) =>
                      //   updateItemQuantity(itemDt.id, parseInt(quantity) + 1)
                      // }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </InputGroup>
                </>
                {/* )} */}
              </Col>
              <div className="mota mt-2">
                <span className="tittle"> Mô tả ngắn: </span>
                {product.productDescription ?? "Mô tả"}
              </div>
            </Row>
          </ListGroup.Item>
        );
        return itemDt;
      });
    }

    return (
      <Card>
        <Card.Body>
          <Card.Title>
            <h1> {product.productName || ""}</h1>
          </Card.Title>
          <div className="product__details__rating">
            <FontAwesomeIcon icon={faStar} className="text-warning" />
            <FontAwesomeIcon icon={faStar} className="text-warning" />
            <FontAwesomeIcon icon={faStar} className="text-warning" />
            <FontAwesomeIcon icon={faStar} className="text-warning" />
            <FontAwesomeIcon icon={faStar} className="text-warning" />
            {/* <span>(18 reviews)</span> */}
          </div>

          <Card.Text
            className="product_desc"
            dangerouslySetInnerHTML={{ __html: product.product_desc || "" }}
          ></Card.Text>
        </Card.Body>

        <ListGroup className="list-group-flush">
          {/* {itemProDt} */}
          <ListGroup.Item key={`itemProDt${product.id}`}>
            <Row>
              <Col md={6} className="d-flex align-items-center ">
                {/* {itemDt.info.length > 0 ? <><strong>{info}</strong><br /></> : null} */}

                {product.salePrice > 0 ? (
                  <CurrencyFormat
                    value={product.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix="₫"
                    renderText={(value) => (
                      <span className="me-2 text-muted fst-italic text-decoration-line-through">
                        {value}
                      </span>
                    )}
                  />
                ) : null}

                <CurrencyFormat
                  value={product.salePrice}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix="₫"
                  renderText={(value) => (
                    <span className="fw-bold fs-6">{value}</span>
                  )}
                />

                {product.salePrice > 0 ? (
                  <span className="fw-bold fst-italic text-danger">
                    {parseInt(
                      ((product.price - product.salePrice) / product.price) *
                        100
                    ) * -1}
                    %
                  </span>
                ) : null}
              </Col>
              <Col md={6} className="text-end">
                {/* {cartItem.length === 0 ? */}
                <Button
                  variant="outline-danger"
                  className="float-end"
                  onClick={() => addToCart(product)}
                >
                  <FontAwesomeIcon icon={faCartPlus} />
                  <span className="btn-cart-text"> Thêm giỏ hàng</span>
                </Button>
                {/* : */}
                <>
                  {/* <InputGroup className='justify-content-end flex-nowrap'>
                                            <Button variant='outline-secondary'
                                            //  onClick={(e) => updateItemQuantity(itemDt.id, parseInt(quantity) - 1)}
                                             >
                                                <FontAwesomeIcon icon={faMinus} />
                                            </Button>
                                            <Form.Control className='text-end' type='number' min="0" value={98}
                                            //  onChange={(e) => updateItemQuantity(itemDt.id, e.target.value)}
                                              style={{ maxWidth: '72px', minWidth: '72px' }} />
                                            <Button variant='outline-secondary' 
                                            // onClick={(e) => updateItemQuantity(itemDt.id, parseInt(quantity) + 1)}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </InputGroup> */}
                </>

                {/* } */}
              </Col>
              <div className="mota mt-2">
                <span className="tittle"> Mô tả ngắn: </span>
                {product.productDescription ?? "Mô tả"}
              </div>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    );
  };

  const showProductContent = (e) => {
    const product__details__tab__desc = document.getElementById(
      "product__details__tab__desc"
    );
    const product__details__tab__btn = document.getElementById(
      "product__details__tab__btn"
    );

    let testClass = product__details__tab__desc.className;

    if (testClass === "product__details__tab__desc card-text show") {
      product__details__tab__desc.classList.remove("show");
      product__details__tab__btn.innerHTML = "Xem thêm";
    } else {
      product__details__tab__desc.classList.add("show");
      product__details__tab__btn.innerHTML = "Thu gọn";
    }
  };

  const imageProduct = () => {
    const images = [
      {
        original:
          product?.mainImage?.imageSrc ?? product.mainImage?.imageLink ?? logo,
        thumbnail:
          product?.mainImage?.imageSrc ?? product.mainImage?.imageLink ?? logo,
      },
    ];

    const product_img_sub_ = product.images ? product.images : [];

    if (product_img_sub_.length > 0) {
      product_img_sub_.map((itemImg) => {
        if (itemImg !== null) {
          images.push({
            original: itemImg.imageSrc ?? itemImg.imageLink,
            thumbnail: itemImg.imageSrc ?? itemImg.imageLink,
          });
        }
        return itemImg;
      });
    }

    return <ImageGallery items={images} />;
  };

  return (
    <>
      {isLoad ? (
        <IsLoading show={isLoad} />
      ) : (
        <div className="mt-3 mb-3">
          <Breadcrumb
            className="mb-2 mt-2 custom-breadcrumb"
            //  style={{ display: "contents" }}
          >
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Trang chủ
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/product" }}>
              Sản phẩm
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{product.productName}</Breadcrumb.Item>
          </Breadcrumb>
          <Row className="featured_product">
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={6} className="featured_product_img py-0">
                      <Card className="img_main">
                        {
                          // product?.mainImage != null ? (
                          imageProduct()
                          // ) : (
                          //   <IsLoading show={true} />
                          // )
                        }
                      </Card>
                    </Col>

                    <Col md={6} className="featured_product_info py-0">
                      {productInfo()}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md={12} className="product__details__tab mt-1">
              <Card>
                <Card.Header>
                  <Nav variant="tabs" defaultActiveKey="#first">
                    <Nav.Item>
                      <Nav.Link href="#first" className="fw-bold">
                        Chi tiết sản phẩm
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  {product.productContent !== null ? (
                    <>
                      <Card.Text
                        className="product__details__tab__desc"
                        id="product__details__tab__desc"
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: product.productContent,
                          }}
                        ></span>
                      </Card.Text>

                      <div className="product__details__tab bg-article">
                        <Button
                          variant="outline-warning"
                          id="product__details__tab__btn"
                          onClick={(e) => showProductContent(e)}
                          data-show="show"
                        >
                          Xem thêm
                        </Button>
                      </div>
                      {/* <div className='text-container'>
                                            <div className='text-content'>
                                            <span dangerouslySetInnerHTML={{ __html: product.product_content }}></span>
                                            </div>
                                            
                                            <a class="read-more-link" href="#">Xem thêm</a>
                                        </div> */}
                    </>
                  ) : (
                    <h6 className="text-muted">Không có thông tin...</h6>
                  )}
                  <div className="section-title">
                    <h2>Sản phẩm liên quan</h2>
                  </div>
                  <Row style={{ justifyContent: "space-around" }}>
                    {products?.length > 0 ? (
                      products?.slice(0, 4)?.map((product) => {
                        return (
                          <Col md={3} sm={6} xs={6}>
                            <ProductItem item={product} logo={logo} />
                          </Col>
                        );
                      })
                    ) : (
                      <p className="text-center">Chưa có sản phẩm liên quan</p>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* <CartBubble page={props.page} /> */}
        </div>
      )}
      {/* <CartBubble /> */}
    </>
  );
};

export default ProductDetail;
