import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Check from "../../../components/admin/other/Check";
import "./sidebar.css";
import { useLocation } from "react-router-dom";
// import "../../../assets/admin/css/style.css"
const Sidebar = () => {
  const [permissions, setPermissions] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const storedPermissions = localStorage.getItem("permission");
    if (storedPermissions) {
      setPermissions(JSON.parse(storedPermissions));
    }
  }, [location]);

  const hasPermission = (permissionName) => {
    return permissions.includes(permissionName);
  };
  return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
        <div className="nav">
          <NavLink className="nav-link" to="/admin">
            <div className="sb-nav-link-icon">
              <i className="fas fa-tachometer-alt"></i>
            </div>
            Tổng quan
          </NavLink>
          <>
            <Link
              className="nav-link collapsed"
              to="#"
              data-bs-toggle="collapse"
              data-bs-target="#pagesCollapseUser"
              aria-expanded="false"
              aria-controls="pagesCollapseUser"
            >
              <div className="sb-nav-link-icon">
                {" "}
                <i className="fa-solid fa-chalkboard-user"></i>{" "}
              </div>
              Quản lý thành viên
              <div className="sb-sidenav-collapse-arrow">
                {" "}
                <i className="fas fa-angle-down"></i>{" "}
              </div>
            </Link>

            <div
              className="collapse"
              id="pagesCollapseUser"
              aria-labelledby="headingOne"
              data-bs-parent="#sidenavAccordionPages"
            >
              <nav className="sb-sidenav-menu-nested nav">
                {hasPermission("Xem danh sách người dùng") && (
                  <NavLink className="nav-link" to="/admin/user">
                    <div className="sb-nav-link-icon">
                      {" "}
                      <i className="fa-solid fa-users"></i>
                    </div>
                    Danh sách thành viên
                  </NavLink>
                )}
                {hasPermission("Xem danh sách vai trò") && (
                  <NavLink className="nav-link" to="/admin/role">
                    <div className="sb-nav-link-icon">
                      {" "}
                      <i className="fa-solid fa-shield-halved"></i>{" "}
                    </div>
                    Vai trò
                  </NavLink>
                )}
              </nav>
            </div>
          </>
          <>
            <Link
              className="nav-link collapsed"
              to="#"
              data-bs-toggle="collapse"
              data-bs-target="#pagesCollapseIncome"
              aria-expanded="false"
              aria-controls="pagesCollapseIncome"
            >
              <div className="sb-nav-link-icon">
                {" "}
                <i className="fa-solid fa-coins"></i>{" "}
              </div>
              Quản lý thu nhập
              <div className="sb-sidenav-collapse-arrow">
                {" "}
                <i className="fas fa-angle-down"></i>{" "}
              </div>
            </Link>

            <div
              className="collapse"
              id="pagesCollapseIncome"
              aria-labelledby="headingOne"
              data-bs-parent="#sidenavAccordionPages"
            >
              <nav className="sb-sidenav-menu-nested nav">
                {hasPermission("Xem danh sách thu nhập") && (
                  <NavLink className="nav-link" to="/admin/income">
                    <div className="sb-nav-link-icon">
                      <i className="fa-solid fa-coins"></i>
                    </div>
                    Danh sách thu nhập
                  </NavLink>
                )}
                {hasPermission("Xem thu nhập cá nhân") && (
                  <NavLink className="nav-link" to="/admin/personal-incomes">
                    <div className="sb-nav-link-icon">
                      <i className="fa-solid fa-money-bill"></i>
                    </div>
                    Xem thu nhập cá nhân
                  </NavLink>
                )}
              </nav>
            </div>
          </>
          <>
            <Link
              className="nav-link collapsed"
              to="#"
              data-bs-toggle="collapse"
              data-bs-target="#pagesCollapseStudent"
              aria-expanded="false"
              aria-controls="pagesCollapseStudent"
            >
              <div className="sb-nav-link-icon">
                {" "}
                <i className="fa-solid fa-user-graduate"></i>{" "}
              </div>
              Quản lý đơn hàng
              <div className="sb-sidenav-collapse-arrow">
                {" "}
                <i className="fas fa-angle-down"></i>{" "}
              </div>
            </Link>
            <div
              className="collapse"
              id="pagesCollapseStudent"
              aria-labelledby="headingOne"
              data-bs-parent="#sidenavAccordionPages"
            >
              <nav className="sb-sidenav-menu-nested nav">
                {hasPermission("Xem danh sách đơn hàng") && (
                  <NavLink className="nav-link" to="/admin/order">
                    <div className="sb-nav-link-icon">
                      <i className="fa-solid fa-layer-group"></i>
                    </div>
                    Danh sách đơn hàng
                  </NavLink>
                )}
              </nav>
            </div>
          </>

          <>
            <Link
              className="nav-link collapsed"
              to="#"
              data-bs-toggle="collapse"
              data-bs-target="#pagesCollapseStore"
              aria-expanded="false"
              aria-controls="pagesCollapseStore"
            >
              <div className="sb-nav-link-icon">
                {" "}
                <i className="fa-solid fa-store"></i>{" "}
              </div>
              Quản lý sản phẩm
              <div className="sb-sidenav-collapse-arrow">
                {" "}
                <i className="fas fa-angle-down"></i>{" "}
              </div>
            </Link>
            <div
              className="collapse"
              id="pagesCollapseStore"
              aria-labelledby="headingOne"
              data-bs-parent="#sidenavAccordionPages"
            >
              <nav className="sb-sidenav-menu-nested nav">
                {hasPermission("Xem danh sách sản phẩm") && (
                  <NavLink className="nav-link" to="/admin/product">
                    <div className="sb-nav-link-icon">
                      {" "}
                      <i className="fa-solid fa-cart-plus"></i>{" "}
                    </div>
                    Sản phẩm
                  </NavLink>
                )}
                {hasPermission("Xem danh sách danh mục") && (
                  <NavLink className="nav-link" to="/admin/category">
                    <div className="sb-nav-link-icon">
                      <i className="fa-solid fa-boxes-stacked"></i>
                    </div>
                    Danh mục sản phẩm
                  </NavLink>
                )}
                {hasPermission("Xem danh sách kho") && (
                  <NavLink className="nav-link" to="/admin/warehouse">
                    <div className="sb-nav-link-icon">
                      <i className="fa-solid fa-warehouse"></i>
                    </div>
                    Danh sách kho
                  </NavLink>
                )}
              </nav>
            </div>
          </>
          <>
            <Link
              className="nav-link collapsed"
              to="#"
              data-bs-toggle="collapse"
              data-bs-target="#pagesCollapsePost"
              aria-expanded="false"
              aria-controls="pagesCollapsePost"
            >
              <div className="sb-nav-link-icon">
                {" "}
                <i className="fa-solid fa-store"></i>{" "}
              </div>
              Quản lý bài viết
              <div className="sb-sidenav-collapse-arrow">
                {" "}
                <i className="fas fa-angle-down"></i>{" "}
              </div>
            </Link>
            <div
              className="collapse"
              id="pagesCollapsePost"
              aria-labelledby="headingOne"
              data-bs-parent="#sidenavAccordionPages"
            >
              <nav className="sb-sidenav-menu-nested nav">
                {hasPermission("Xem danh sách bài viết") && (
                  <NavLink className="nav-link" to="/admin/post">
                    <div className="sb-nav-link-icon">
                      {" "}
                      <i className="fa-solid fa-envelopes-bulk"></i>
                    </div>
                    Bài viết
                  </NavLink>
                )}
                {hasPermission("Xem danh sách danh mục") && (
                  <NavLink className="nav-link" to="/admin/topic">
                    <div className="sb-nav-link-icon">
                      <i className="fa-solid fa-boxes-stacked"></i>
                    </div>
                    Danh mục bài viết
                  </NavLink>
                )}
              </nav>
            </div>
          </>
          {hasPermission("Cài đặt") && (
            <>
              <div className="sb-sidenav-menu-heading">Hệ thống</div>

              <NavLink className="nav-link" to="/admin/setting">
                <div className="sb-nav-link-icon">
                  <i className="fa-solid fa-gears"></i>
                </div>
                Cài đặt
              </NavLink>
            </>
          )}
        </div>
      </div>
      <div className="sb-sidenav-footer py-2">
        <div className="small">Version: 1.0</div>
      </div>
    </nav>
  );
};

export default Sidebar;
