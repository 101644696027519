import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Form, Row, Modal, Container } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import emailjs from '@emailjs/browser'
import Accordion from "react-bootstrap/Accordion";
import Carousel from "react-bootstrap/Carousel";
import { faStar } from "@fortawesome/free-solid-svg-icons";
// import Test from './Test'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SideBar from "../components/frontend/sidebar/SideBar";
import IsLoading from "../layouts/IsLoading";
import axios from "axios";
function About() {
  const navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [about, setAbout] = useState([]);
  const [showimg, setshowimg] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [key, setKey] = useState(10);
  useEffect(() => {
    document.title = "Giới thiệu";
    window.scrollTo({ top: 0, behavior: "smooth" });
    getAbout();
  }, []);

  const getAbout = () => {
    setIsLoad(true);
    axios
      .get(`settings?names=privacyPolicy`)
      .then((res) => {
        setIsLoad(false);
        setAbout(res.data.data[0].payload);
      })
      .catch((err) => {
        setIsLoad(true);

        console.log(err);
      });
  };
  return (
    <>
      {isLoad ? (
        <IsLoading show={isLoad} />
      ) : (
        <>
          <div className="section-title">
            <h2>Giới thiệu</h2>
          </div>

          <div dangerouslySetInnerHTML={{ __html: about }}></div>
        </>
      )}
    </>
  );
}

export default About;
