import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
function Verify() {
  const navigate = useNavigate();
  const { param } = useParams();
  useEffect(() => {
    verify(param);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const verify = (param) => {
    axios
      .get(`verify/${param}`)
      .then((res) => {
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        toast("Lỗi xác thực", { type: "error", autoClose: 3000 });
        navigate("/");
      });
  };
  return <></>;
}

export default Verify;
