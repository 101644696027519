import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Row, Col, Card, Button } from "react-bootstrap";
import CategoryModal from "./CategoryModal";
import { toast } from "react-toastify";
import axios from "axios";
import DataTables from "../datatable/DataTables";
import { useNavigate } from "react-router-dom";
import { getDate } from "../other/Format";
const CategoryIndex = (page) => {
  const [show, setShow] = useState(false);
  const [showInf, setShowInf] = useState(false);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState({
    categoryContent: "",
    categoryName: "",
    categoryKeyword: "",
    type: page.element == "category-product" ? "cPro" : "cPost",
    categoryPriority: 0,
    status: "S1",
    categoryParentId: "",
    image: "",
  });
  const onHide = () => {
    setShow(false);
    setShowInf(false);
    getCategories();
  };
  useEffect(() => {
    document.title =
      page.element == "category-product"
        ? "Quản lý danh mục"
        : "Quản lý chủ đề";
    getCategories();
  }, [page.element]);
  const getCategories = () => {
    axios
      .get(
        `admin/categories?type=${
          page.element == "category-product" ? "cPro" : "cPost"
        }`
      )
      .then((res) => {
        setCategories(res.data.data?.categories);
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          navigate("/admin/access-denied");
        }
        console.log(err);
      });
  };
  const getCategoryBySlug = (slug) => {
    axios
      .get(`admin/edit-category/${slug}`)
      .then((res) => {
        setData(res.data.data ?? {});
        setShowInf(true);
      })
      .catch((err) => {
        toast(err.data.message, { type: "error", autoClose: 1000 });
        console.log(err);
      });
  };
  const deleteCategory = (id) => {
    axios
      .delete(`/admin/delete-category/${id}`)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 1000 });
        getCategories();
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        } else
          toast(err.response?.data?.message || "Xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
      });
  };
  const renderTable = () => {
    const columns = [
      {
        name: "Tên danh mục",
        selector: (row) => row.categoryName,
        cell: (row) => <div className="text-wrap">{row.categoryName}</div>,
      },
      {
        name: "Nội dung",
        selector: (row) => row.categoryContent,
        cell: (row) => <div className="text-wrap">{row.categoryContent}</div>,
      },
      {
        name: "Từ khóa",
        selector: (row) => row.categoryKeyword,
        cell: (row) => <div className="text-wrap">{row.categoryKeyword}</div>,
      },
      {
        name: "Độ ưu tiên",
        selector: (row) => row.categoryPriority,
        sortable: true,
        right: true,
      },
      // {
      //   name: "Update",
      //   selector: (row) => row.updatedAt,
      //   cell: (row) => (
      //     <div className="text-wrap">
      //       {getDate(new Date(row.updatedAt) / 1000)}
      //     </div>
      //   ),
      // },
      // {
      //   name: "create",
      //   selector: (row) => row.createdAt,
      //   cell: (row) => (
      //     <div className="text-wrap">
      //       {getDate(new Date(row.createdAt) / 1000)}
      //     </div>
      //   ),
      // },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];
    const data = [];
    if (categories?.length > 0)
      categories?.map((item) => {
        data?.push({
          ...item,
          // categoryName: item.categoryName,
          // categoryContent: item.categoryContent,
          // categoryKeyword: item.categoryKeyword,
          // categoryPriority: item.categoryPriority,
          setting: (
            <>
              <Button
                className="me-2"
                size="sm"
                onClick={() => getCategoryBySlug(item.categorySlug)}
                title="Chi tiết"
              >
                <i className="fa-solid fa-circle-info"></i>
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => deleteCategory(item.id)}
                title="Xóa"
              >
                <i className="fas fa-trash"></i>
              </Button>
            </>
          ),
        });
      });

    return <DataTables data={data} columns={columns} />;
  };
  return (
    <div className="container">
      <Breadcrumb className="mb-2 mt-2 " style={{ display: "contents" }}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Tổng quan
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          Quản lý {page.element == "category-product" ? "danh mục" : "chủ đề"}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header>
              <Button
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => {
                  setData({
                    categoryContent: "",
                    categoryName: "",
                    categoryKeyword: "",
                    type: page.element == "category-product" ? "cPro" : "cPost",
                    categoryPriority: 0,
                    status: "S1",
                    categoryParentId: "",
                    image: "",
                  });
                  setShow(true);
                }}
              >
                <i className="fa-solid fa-plus"></i> Thêm
              </Button>
              <Card.Title>
                <i className="fas fa-list me-1"></i> Danh sách danh mục
              </Card.Title>
            </Card.Header>
            <Card.Body>{renderTable()}</Card.Body>
          </Card>
        </Col>
      </Row>
      {show ? (
        <CategoryModal show={show} onHide={onHide} data={data} type={"TM"} />
      ) : null}
      {showInf ? (
        <CategoryModal show={showInf} onHide={onHide} data={data} type={"CN"} />
      ) : null}
    </div>
  );
};

export default CategoryIndex;
