import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Row, Col, Card, Button } from "react-bootstrap";
import PostModal from "./PostModal";
import { toast } from "react-toastify";
import axios from "axios";
import DataTables from "../datatable/DataTables";
import { useNavigate } from "react-router-dom";

const PostIndex = () => {
  const [show, setShow] = useState(false);
  const [showInf, setShowInf] = useState(false);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  const [data, setData] = useState({
    categoryId: "",
    postDescription: "",
    postContent: "",
    postTitle: "",
    postKeyword: "",
    status: "S1",
    image: "",
  });
  const onHide = (isLoading) => {
    setShow(false);
    setShowInf(false);
    if (isLoading) {
      getPosts();
    }
  };
  useEffect(() => {
    getPosts();
    document.title = "Quản lý bài viết";
  }, []);
  const getPosts = () => {
    axios
      .get(`admin/posts`)
      .then((res) => {
        setPosts(res.data.data?.posts);
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          navigate("/admin/access-denied");
        }
        console.log(err);
      });
  };
  const getPostBySlug = (slug) => {
    axios
      .get(`admin/post/${slug}`)
      .then((res) => {
        setData(res.data.data ?? {});
        setShowInf(true);
      })
      .catch((err) => {
        toast(err.data.message, { type: "error", autoClose: 1000 });
        console.log(err);
      });
  };
  const deletePost = (id) => {
    axios
      .delete(`/admin/delete-post/${id}`)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 1000 });
        getPosts();
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        } else
          toast(err.response?.data?.message || "Xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
      });
  };
  const renderTable = () => {
    const columns = [
      {
        name: "Tiêu đề",
        selector: (row) => row.postTitle,
        cell: (row) => <div className="two-line-limit">{row.postTitle}</div>,
      },

      {
        name: "Mô tả",
        selector: (row) => row.postDescription,
        cell: (row) => (
          <div className="two-line-limit">{row.postDescription}</div>
        ),
      },
      {
        name: "Từ khóa",
        selector: (row) => row.categoryKeyword,
        cell: (row) => <div className="two-line-limit">{row.postKeyword}</div>,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];
    const data = [];
    if (posts?.length > 0)
      posts?.map((item) => {
        data?.push({
          postTitle: item.postTitle,
          postContent: item.postContent,
          postKeyword: item.postKeyword,
          postDescription: item.postDescription,
          setting: (
            <>
              <Button
                className="me-2"
                size="sm"
                onClick={() => getPostBySlug(item.postSlug)}
                title="Chi tiết"
              >
                <i className="fa-solid fa-circle-info"></i>
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => deletePost(item.id)}
                title="Xóa"
              >
                <i className="fas fa-trash"></i>
              </Button>
            </>
          ),
        });
      });

    return <DataTables data={data} columns={columns} />;
  };
  return (
    <div className="container">
      <Breadcrumb className="mb-2 mt-2 " style={{ display: "contents" }}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Tổng quan
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Quản lý bài viết</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header>
              <Button
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => {
                  setData({
                    categoryId: "",
                    postDescription: "",
                    postContent: "",
                    postTitle: "",
                    postKeyword: "",
                    status: "S1",
                    image: "",
                  });
                  setShow(true);
                }}
              >
                <i className="fa-solid fa-plus"></i> Thêm
              </Button>
              <Card.Title>
                <i className="fas fa-list me-1"></i> Danh sách bài viết
              </Card.Title>
            </Card.Header>
            <Card.Body>{renderTable()}</Card.Body>
          </Card>
        </Col>
      </Row>
      {show ? (
        <PostModal show={show} onHide={onHide} data={data} type={"TM"} />
      ) : null}
      {showInf ? (
        <PostModal show={showInf} onHide={onHide} data={data} type={"CN"} />
      ) : null}
    </div>
  );
};

export default PostIndex;
