import React, { Component } from "react";
import { Container } from "react-bootstrap";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    document.title = "Tổng quan";
  }

  // Handle Logic

  // Render
  render() {
    return <Container fluid></Container>;
  }
}

export default Dashboard;
