
import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row, Modal, Container } from 'react-bootstrap';
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SideBar from '../components/frontend/sidebar/SideBar';
// import Navbar from '../layouts/frontend/NavBar_Product';
function ProductIndex() {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Sản phẩm";
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    return (
        <>
                <SideBar />
        </>
    )
}

export default ProductIndex;




