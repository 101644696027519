import React from "react";
import { Link } from "react-router-dom";

const Error403 = () => {
  return (
    <div id="layoutError_content">
      {/* <Navbar/> */}

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-center mt-4">
              <br /> <br /> <br /> <br />
              {/* <img className="mb-4 img-error" src="/assets/img/banner/403.webp" alt="error403" /> */}
              <h1>Error 403</h1>
              <p className="lead">Bạn không có quyền truy cập vào trang này.</p>
              <Link to="/" className="btn btn-primary">
                <i className="fas fa-arrow-left me-1"></i> Trang chủ
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error403;
