import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import jQuery from "jquery";
import { Container } from "react-bootstrap";
function Navbar() {
  const navigate = useNavigate();
  const params = useParams();
  const [ShowDropdown, setShowDropdown] = useState(false);
  const [category, setCategory] = useState([]);
  const getCategories = () => {
    axios
      .get(`categories?type=cPro`)
      .then((res) => {
        setCategory(res.data.data?.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCategories();
  }, []);
  const sidenavToggle = () => {
    const navbarSupportedContent = document.getElementById(
      "navbarSupportedContent"
    );

    let testclass = navbarSupportedContent.className;

    if (
      testclass === "navbar-collapse collapse show" &&
      window.innerWidth < 992
    ) {
      navbarSupportedContent.classList.remove("show");
    }
  };
  (function ($) {
    $(function () {
      //  open and close nav
      $("#navbar-toggle").click(function () {
        // $('nav ul').slideToggle();
        $("nav ul").toggle();
      });
      // Hamburger toggle
      $("#navbar-toggle").on("click", function () {
        this.classList.toggle("active");
      });
      // If a link has a dropdown, add sub menu toggle.
      $("nav ul li a:not(:only-child)").click(function (e) {
        $(this).siblings(".navbar-dropdown").slideToggle("slow");
        // Close dropdown when select another dropdown
        $(".navbar-dropdown").not($(this).siblings()).hide("slow");
        e.stopPropagation();
      });
      // Click outside the dropdown will remove the dropdown class
      $("html").click(function () {
        $(".navbar-dropdown").hide();
      });
    });
  })(jQuery);

  return (
    <section className="navigation">
      <div className=" container nav-container">
        {window.location.pathname == "/" ? (
          <div className="brand">
            {/* <Link to={"/"}>Logo</Link> */}
            <div
              // className="all-category"
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
            >
              <div className="myDIV">
                <span className="menu_nav">
                  <i className="fa fa-bars" aria-hidden="true"></i> Danh mục
                </span>
              </div>
              {ShowDropdown ? (
                <ul className="menunav">
                  {category?.map((item) => {
                    return (
                      <li>
                        <Link to={`/product/${item.categorySlug}`}>
                          {item.categoryName}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}

        <nav>
          <div className="nav-mobile">
            <Link id="navbar-toggle" to={"/"}>
              <span></span>
            </Link>
          </div>
          <ul className="nav-list">
            <li>
              <Link to={"/"}>
                <span className="menu_nav">Trang chủ</span>
              </Link>
            </li>
            <li>
              <Link to={"/product"}>
                <span className="menu_nav">Sản phẩm</span>
              </Link>
            </li>
            {/* <li>
                <Link to={"/"}>Services</Link>
                <ul class="navbar-dropdown">
                  <li>
                    <Link to={"/"}>Sass</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Less</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Stylus</Link>
                  </li>
                </ul>
              </li> */}
            {/* <li>
                <Link to={"/"}>Portfolio</Link>
              </li> */}
            <li>
              <Link to={"/blog"}>
                <span className="menu_nav">Tin tức</span>
              </Link>
            </li>
            <li>
              <Link to={"/about"}>
                <span className="menu_nav">Giới thiệu</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
}

export default Navbar;
