import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "./sale.css";
import { Row, Col, Card, Button } from "react-bootstrap";
import { priceFormatter } from "../../admin/other/Format";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../blog/Blog.css";
function Sale() {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getPostAll();
  }, []);
  const getPostAll = async () => {
    // setIsload(true);

    await axios
      .get(`/posts`)
      .then((res) => {
        setPosts(res.data.data?.posts?.slice(0, 8));

        // setIsload(false);
      })
      .catch((error) => {
        // toast(error.message, { type: 'error' });
        // setIsload(false);
      });
  };
  const getImage = (src) => {
    if (src == null) return "/logo512.png";
    else return src;
  };
  const viewPosts = () => {
    let output = [];
    if (posts?.length > 0) {
      for (let i = 0; i < posts.length; i++) {
        output[i] = (
          <Col
            md={((i * 2) / 3) % 3 === 0 ? 6 : 3}
            key={`postItem_${posts[i].postSlug}`}
            className="p-2"
          >
            <Card>
              <Card.Img
                variant="top"
                src={getImage(
                  posts[i].image?.imageLink ?? posts[i].image?.imageSrc
                )}
                alt="img"
                className="post_img"
              />
              <Card.Body>
                <Card.Title>
                  <Link
                    to={`/blog/${posts[i].postSlug}`}
                    className="text-titleVi"
                  >
                    {posts[i].postTitle}
                  </Link>
                </Card.Title>

                <Card.Text className="text_descVi">
                  {posts[i].posts_desc}
                </Card.Text>

                <div className="cart-reviews">
                  <Card.Link href="#">
                    <FontAwesomeIcon icon={faThumbsUp} />
                  </Card.Link>
                  <Card.Link href="#">
                    <FontAwesomeIcon icon={faThumbsDown} />
                  </Card.Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        );
      }
    } else {
      output = <p className="text-muted">Hiện tại chưa có bài viết!</p>;
    }

    return output;
  };

  return (
    <section className="cown-down">
      <div className="section-inner ">
        <div id="page_blog">
          {/* <Row>
            <Col md={6}>
              <div className="image ">
                <img
                  src={
                    dataItem?.item?.mainImage?.imageLink ??
                    dataItem?.item?.mainImage?.imageSrc ??
                    dataItem?.logo
                  }
                  alt={dataItem?.item?.productName}
                  height="200px"
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="content">
                <div className="heading-block">
                  <h3 className="small-title">Khuyến mãi hấp dẫn</h3>
                  <Link
                    to={`/product-detail/${dataItem?.item?.productSlug}`}
                    className="text-black"
                  >
                    <h4 className="title">{dataItem?.item?.productName}</h4>
                  </Link>
                  <p className="text">{dataItem?.item?.productDescription} </p>
                  <p className="price">
                    {priceFormatter.format(dataItem?.item?.salePrice)} ₫{" "}
                    <s>{priceFormatter.format(dataItem?.item?.price)} ₫</s>
                  </p>
                  <div className="coming-time"></div>
                </div>
              </div>
            </Col>
          </Row> */}
          <Row>
            {viewPosts()}

            {posts?.length > 0 ? (
              <center>
                <Button
                  className="mt-3"
                  variant="danger"
                  onClick={() => {
                    navigate("/blog");
                  }}
                >
                  {" "}
                  Tất cả tin tức
                </Button>
              </center>
            ) : (
              ""
            )}
          </Row>
        </div>
      </div>
    </section>
  );
}

export default Sale;
