import axios from "axios";
import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";

import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
function Tracking() {
  useEffect(() => {
    document.title = "Tra cứu đơn hàng";
  }, []);

  const [dataInput, setDataInput] = useState({
    email: "",
    trackingNumber: "",
  });
  const [err, setErr] = useState({});
  const [status, setStatus] = useState([
    { value: "PENDING", label: "Chờ duyệt" },
    { value: "ACCEPTED", label: "Đã duyệt" },
    { value: "DELIVERED", label: "Đã gửi" },
    { value: "REJECTED", label: "Từ chối" },
    { value: "REFUNDED", label: "Hoàn tiền" },
    { value: "CANCELED", label: "Hủy" },
  ]);
  const [data, setData] = useState();
  const handleInput = (e) => {
    e.persist();

    let field = e.target.name;
    let value = e.target.value;

    setDataInput({ ...dataInput, [field]: value });
    setErr({ ...err, [field]: "" });
  };

  const getTracking = (e) => {
    e.preventDefault();
    let data = {
      trackingNumber: dataInput.trackingNumber,
      email: dataInput.email,
    };
    axios
      .post("/tracking-order", data)

      .then((res) => {
        if (res.status == 200) {
          setData(res.data.data);
        } else {
        }
      })
      .catch((error) => {
        setData();
        if (error.response?.data?.message)
          toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
        setErr(error.response?.data?.messages ?? "");
      });
  };

  return (
    <Container className="py-5" id="login">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="text-center">
            <Form onSubmit={getTracking}>
              <Card.Header className="cardheader">TRA CỨU ĐƠN HÀNG</Card.Header>

              <Card.Body>
                <Form.Floating className="mb-3">
                  <Form.Control
                    onChange={handleInput}
                    // defaultValue={dataInput.username}
                    name="trackingNumber"
                    type="text"
                    placeholder="Mã đơn hàng"
                    required
                  />
                  <Form.Label>
                    {/* <i className="fa-solid fa-user" /> */}
                    Mã đơn hàng
                  </Form.Label>
                  {/* <span className="text-err">{err?.username}</span> */}
                </Form.Floating>
                <Form.Floating className="mb-3">
                  {/* <InputGroup className="has-validation w-100 is-invalid"> */}
                  <Form.Floating
                  // style={{ width: "calc(100% - 46px)" }}
                  >
                    <Form.Control
                      onChange={handleInput}
                      // defaultValue={dataInput.password}
                      type={"text"}
                      name="email"
                      // className="border-end-0"
                      placeholder="Email đặt hàng"
                      required
                    />
                    <Form.Label>Email đặt hàng</Form.Label>
                  </Form.Floating>

                  {/* </InputGroup> */}
                </Form.Floating>
              </Card.Body>

              <Card.Footer>
                <Col sm={6} className="d-grid gap-2 mx-auto">
                  <Button type="submit" variant="danger">
                    Tra cứu
                  </Button>
                </Col>
              </Card.Footer>
            </Form>
          </Card>
        </Col>
        {data != null ? (
          <Col md={6}>
            <Card style={{ height: "247px" }}>
              <Form>
                <Card.Header className="cardheader text-center">
                  THÔNG TIN ĐƠN HÀNG
                </Card.Header>

                <Card.Body>
                  <p>
                    <strong>Tên người nhận: </strong>
                    {data.name}
                  </p>{" "}
                  <p>
                    <strong>Địa chỉ: </strong>
                    {data.shippingAddress}
                  </p>
                  <p>
                    <strong>Trạng thái: </strong>
                    {status?.filter((e) => e.value == data.status)[0]?.label}
                  </p>
                  <Row>
                    <Col></Col>
                  </Row>
                </Card.Body>
              </Form>
            </Card>
          </Col>
        ) : null}
      </Row>
      <br />
    </Container>
  );
}

export default Tracking;
