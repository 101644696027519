import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Row, Col, Card, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import TextEditor from "../other/TextEditor";
import { useNavigate } from "react-router-dom";

const SettingIndex = (page) => {
  const [setting, setSetting] = useState({});
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [termAndCondition, setTermAndCondition] = useState("");
  const [imageBanner, setImageBanner] = useState([]);
  useEffect(() => {
    getSetting();
    document.title = "Cài đặt";
  }, []);
  useEffect(() => {}, [setting]);
  const getSetting = () => {
    axios
      .get(`admin/site-settings`)
      .then((res) => {
        setSetting(formatSettings(res.data.data));

        setTermAndCondition(
          res.data.data?.filter((e) => e.name == "termAndCondition")[0]?.payload
        );

        setPrivacyPolicy(
          res.data.data?.filter((e) => e.name == "privacyPolicy")[0]?.payload
        );
        let banner = [];
        banner = res.data.data?.filter((e) => e.name == "banner")[0]?.payload;
        banner?.push("");
        setImageBanner(banner);
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          navigate("/admin/access-denied");
        }
        console.log(err);
        setIsLoading(true);
      });
  };
  const handleInputChange = (index, event) => {
    const newValue = event.target.value;
    const newImagesrc = [...imageBanner];
    newImagesrc[index] = newValue;
    const filteredImagesrc = newImagesrc.filter(
      (src, idx) => src != "" || idx == newImagesrc.length - 1
    );
    delete errors["banner"];

    setImageBanner(filteredImagesrc);
    if (index === imageBanner.length - 1 && newValue) {
      setImageBanner([...newImagesrc, ""]);
    }
  };
  const handleInput = (e) => {
    let value = e.target.value;
    let field = e.target.name;
    setting[field] = value;
    delete errors[field];
    setSetting({ ...setting });

    setErrors({ ...errors });
  };
  const formatSettings = (settings) => {
    const formattedSettings = {};
    settings.forEach((setting) => {
      formattedSettings[setting.name] = setting.payload;
    });
    return formattedSettings;
  };
  const updateSetting = () => {
    let dataT = {
      ...setting,
      privacyPolicy: privacyPolicy,
      termAndCondition: termAndCondition,
      banner: imageBanner,
    };
    let data = {
      settings: dataT,
    };

    axios
      .put("/admin/update-site-setting", data)
      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
        } else {
        }
      })
      .catch((error) => {
        setErrors(error.response?.data?.messages ?? "");
      });
  };
  const renderSetting = () => {
    return (
      <>
        <Row>
          <Col>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                onChange={handleInput}
                name="siteName"
                value={setting.siteName}
                className={errors.siteName ? "is-invalid" : ""}
                placeholder="Tên chủ đề"
              />
              <Form.Label>
                Tên công ty<i className="text-danger"></i>
              </Form.Label>
              <span className="text-err">
                {errors.productName ? errors.productName : ""}
              </span>
            </Form.Floating>
          </Col>
          <Col>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                onChange={handleInput}
                name="logo"
                value={setting.logo}
                className={errors.logo ? "is-invalid" : ""}
                placeholder="Tên chủ đề"
              />
              <Form.Label>
                Link logo<i className="text-danger"></i>
              </Form.Label>
              <span className="text-err">{errors.logo ? errors.logo : ""}</span>
            </Form.Floating>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                onChange={handleInput}
                name="address"
                value={setting.address}
                className={errors.address ? "is-invalid" : ""}
                placeholder="Tên chủ đề"
              />
              <Form.Label>
                Địa chỉ <i className="text-danger"></i>
              </Form.Label>
              <span className="text-err">
                {errors.address ? errors.address : ""}
              </span>
            </Form.Floating>
          </Col>
          <Col md={3}>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                onChange={handleInput}
                name="email"
                value={setting.email}
                className={errors.email ? "is-invalid" : ""}
                placeholder="Tên chủ đề"
              />
              <Form.Label>
                Email <i className="text-danger"></i>
              </Form.Label>
              <span className="text-err">
                {errors.email ? errors.email : ""}
              </span>
            </Form.Floating>
          </Col>
          <Col>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                onChange={handleInput}
                name="phoneNumber"
                value={setting.phoneNumber}
                className={errors.phoneNumber ? "is-invalid" : ""}
                placeholder="Tên chủ đề"
              />
              <Form.Label>
                Số điện thoại <i className="text-danger"></i>
              </Form.Label>
              <span className="text-err">
                {errors.phoneNumber ? errors.phoneNumber : ""}
              </span>
            </Form.Floating>
          </Col>
          <Col>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                onChange={handleInput}
                name="taxSetting"
                value={setting.taxSetting}
                className={errors.taxSetting ? "is-invalid" : ""}
                placeholder="Tên chủ đề"
              />
              <Form.Label>
                Mã số thuế <i className="text-danger"></i>
              </Form.Label>
              <span className="text-err">
                {errors.taxSetting ? errors.taxSetting : ""}
              </span>
            </Form.Floating>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                onChange={handleInput}
                name="facebookLink"
                value={setting.facebookLink}
                className={errors.facebookLink ? "is-invalid" : ""}
                placeholder="Tên chủ đề"
              />
              <Form.Label>
                Link Facebook<i className="text-danger"></i>
              </Form.Label>
              <span className="text-err">
                {errors.facebookLink ? errors.facebookLink : ""}
              </span>
            </Form.Floating>
          </Col>
          <Col>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                onChange={handleInput}
                name="zaloLink"
                value={setting.zaloLink}
                className={errors.zaloLink ? "is-invalid" : ""}
                placeholder="Tên chủ đề"
              />
              <Form.Label>
                Link Zalo<i className="text-danger"></i>
              </Form.Label>
              <span className="text-err">
                {errors.zaloLink ? errors.zaloLink : ""}
              </span>
            </Form.Floating>
          </Col>
          <Col>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                onChange={handleInput}
                name="youtubeLink"
                value={setting.youtubeLink}
                className={errors.youtubeLink ? "is-invalid" : ""}
                placeholder="Tên chủ đề"
              />
              <Form.Label>
                Link Youtube<i className="text-danger"></i>
              </Form.Label>
              <span className="text-err">
                {errors.youtubeLink ? errors.youtubeLink : ""}
              </span>
            </Form.Floating>
          </Col>
        </Row>
        <Row>
          {/* <Col>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                onChange={handleInput}
                name="instagramLink"
                value={setting.instagramLink}
                className={errors.instagramLink ? "is-invalid" : ""}
                placeholder="Tên chủ đề"
              />
              <Form.Label>
                Link Instagram<i className="text-danger"></i>
              </Form.Label>
              <span className="text-err">
                {errors.instagramLink ? errors.instagramLink : ""}
              </span>
            </Form.Floating>
          </Col> */}
          {/* <Col>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                onChange={handleInput}
                name="telegramLink"
                value={setting.telegramLink}
                className={errors.telegramLink ? "is-invalid" : ""}
                placeholder="Tên chủ đề"
              />
              <Form.Label>
                Link Telegram<i className="text-danger"></i>
              </Form.Label>
              <span className="text-err">
                {errors.telegramLink ? errors.telegramLink : ""}
              </span>
            </Form.Floating>
          </Col>{" "} */}
        </Row>
        <Row>
          {imageBanner.map((src, index) => (
            <Col md={4}>
              <Form.Floating className="mb-3" key={index}>
                <Form.Control
                  type="text"
                  // className={errors.imageSrcs ? "is-invalid" : ""}
                  placeholder="Link hình ảnh"
                  value={src}
                  // disabled={file?.length > 0 ? true : false}
                  onChange={(event) => handleInputChange(index, event)}
                />
                <Form.Label>
                  Link banner <i className="text-danger"></i>
                </Form.Label>
              </Form.Floating>
            </Col>
          ))}{" "}
          <span className="text-err">
            {errors.banner ? "Đường dẫn không hợp lệ." : ""}
          </span>
        </Row>
        <Row>
          <Col>
            <Form.Label>
              Giới thiệu<span className="text-danger"></span>
            </Form.Label>

            {isLoading ? (
              <i>Đang tải...</i>
            ) : (
              <TextEditor
                setContents={privacyPolicy}
                onChange={(e) => setPrivacyPolicy(e)}
                placeholder="Nội dung"
                name="privacyPolicy"
              />
            )}

            <span className="text-err">
              {errors.productContent ? errors.productContent : ""}
            </span>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <Form.Label>
              Điều kiện và điều khoản<span className="text-danger"></span>
            </Form.Label>

            {isLoading ? (
              <i>Đang tải...</i>
            ) : (
              <TextEditor
                setContents={termAndCondition}
                onChange={(e) => setTermAndCondition(e)}
                placeholder="Nội dung"
                name="termAndCondition"
              />
            )}

            <span className="text-err">
              {errors.productContent ? errors.productContent : ""}
            </span>
          </Col>
        </Row> */}
      </>
    );
  };
  return (
    <div className="container">
      <Breadcrumb className="mb-2 mt-2 " style={{ display: "contents" }}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Tổng quan
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Quản lý danh mục</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header>
              <Button
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => {
                  updateSetting();
                }}
              >
                Cập nhật
              </Button>
              <Card.Title>
                <i className="fas fa-list me-1"></i> Danh sách cài đặt
              </Card.Title>
            </Card.Header>
            <Card.Body>{renderSetting()}</Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SettingIndex;
