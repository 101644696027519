
const priceFormatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

const dateFormatter = (date) => ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();

const takeDecimalNumber = (num, n) => {
    let base = 10 ** n;
    let result = Math.round(num * base) / base;
    return result;
}

const getDate = (data) => {
    const date = new Date(data * 1000);
    return (
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear() +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2)
    );
};

export { priceFormatter, dateFormatter, takeDecimalNumber, getDate };
