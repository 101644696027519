import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const UserModal = ({ show, onHide, data }) => {
  const [checkRef, setCheckRef] = useState(false);
  const [dataInput, setDataInput] = useState(data);
  const [err, setErr] = useState({});
  const handleInput = (event) => {
    const field = event.target.name;
    let value = event.target.value;
    const newDataInput = { ...dataInput };
    newDataInput[field] = value;
    setDataInput(newDataInput);
    const newErr = { ...err };
    delete newErr[field];
    setErr(newErr);
  };
  useEffect(() => {
    const dateString = data.dateOfBirth;
    const dateObject = new Date(dateString);

    const year = dateObject.getUTCFullYear();
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getUTCDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    setDataInput({ ...data, dateOfBirth: formattedDate });
  }, []);
  const checkReferralCode = (id) => {
    axios
      .post(`check-referral-code`, { referralId: id })
      .then((res) => {
        setCheckRef(true);
        setErr({ referralId: res.data.message || "default_message" });
      })
      .catch((error) => {
        setCheckRef(false);
        error.response?.data?.message
          ? setErr({
              referralId: error.response?.data?.message || "Không tìm thấy",
            })
          : setErr(error.response?.data?.messages);
      });
  };
  const updateSubmit = (e) => {
    e.preventDefault();

    let dataUpdate = {
      userId: dataInput.id,
      identification: dataInput.identification,
      userFullName: dataInput.userFullName,
      userPhoneNumber: dataInput.userPhoneNumber,
      contactAddress: dataInput.contactAddress,
      permanentAddress: dataInput.permanentAddress,
      bankAccountNumber: dataInput.bankAccountNumber,
      bankBranch: dataInput.bankBranch,
      profession: dataInput.profession,
      taxCode: dataInput.taxCode,
      academicLevel: dataInput.academicLevel,
      appliedPosition: dataInput.appliedPosition,
      dateOfBirth: dataInput.dateOfBirth,
      gender: dataInput.gender,
      gender: dataInput.gender,
      referralId: dataInput?.referralId || "",
    };
    axios
      .put(`/admin/update-user`, dataUpdate)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 3000 });
        onHide(true);
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErr(error.response?.data?.messages);
      });
  };
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>Chi tiết người dùng</Modal.Header>
      <Form onSubmit={updateSubmit}>
        <Modal.Body>
          <Row>
            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={handleInput}
                  defaultValue={dataInput.userFullName}
                  name="userFullName"
                  type="text"
                  placeholder="Họ và tên"
                  required
                />
                <Form.Label>
                  Họ tên<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.userFullName}</span>
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Select
                  defaultValue={dataInput.gender}
                  name="gender"
                  onChange={handleInput}
                  required
                >
                  <option value="">--Chọn--</option>
                  <option value="M">Nam</option>
                  <option value="F">Nữ</option>
                  <option value="O">Khác</option>
                </Form.Select>
                <Form.Label>
                  Giới tính<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.gender}</span>
              </Form.Floating>
            </Col>
            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={handleInput}
                  defaultValue={dataInput.identification}
                  name="identification"
                  type="text"
                  placeholder="Số điện thoại"
                  required
                />
                <Form.Label>
                  CMND/CCCD<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.identification}</span>
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={handleInput}
                  value={dataInput.dateOfBirth}
                  name="dateOfBirth"
                  type="date"
                  placeholder="Ngày sinh"
                  required
                />
                <Form.Label>
                  Ngày sinh<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.dateOfBirth}</span>
              </Form.Floating>
            </Col>
            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={handleInput}
                  value={dataInput.userPhoneNumber}
                  name="userPhoneNumber"
                  type="text"
                  placeholder="Số điện thoại"
                  required
                />
                <Form.Label>
                  Số điện thoại<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.userPhoneNumber}</span>
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={handleInput}
                  value={dataInput.userEmail}
                  name="userEmail"
                  type="text"
                  placeholder="Email"
                  required
                  readOnly
                />
                <Form.Label>
                  Email<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.userEmail}</span>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={handleInput}
                  value={dataInput.contactAddress}
                  name="contactAddress"
                  type="text"
                  placeholder="Số điện thoại"
                  required
                />
                <Form.Label>
                  Địa chỉ liên lạc<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.contactAddress}</span>
              </Form.Floating>
            </Col>
            <Col>
              <Form.Floating className="mb-3">
                <InputGroup className="has-validation w-100 is-invalid">
                  <Form.Floating style={{ width: "calc(100% - 46px)" }}>
                    <Form.Control
                      onChange={handleInput}
                      value={dataInput.permanentAddress}
                      type="text"
                      name="permanentAddress"
                      className="border-end-0"
                      placeholder="Địa chỉ thường trú"
                      required
                    />
                    <Form.Label>
                      Địa chỉ thường chú<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-err">{err?.permanentAddress}</span>
                  </Form.Floating>
                </InputGroup>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={handleInput}
                  defaultValue={dataInput.profession}
                  name="profession"
                  type="text"
                  placeholder="Số điện thoại"
                  required
                />
                <Form.Label>
                  Nghề nghiệp<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.profession}</span>
              </Form.Floating>
            </Col>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={handleInput}
                  defaultValue={dataInput.academicLevel}
                  name="academicLevel"
                  type="text"
                  placeholder="Số điện thoại"
                  required
                />
                <Form.Label>
                  Trình độ học vấn<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.academicLevel}</span>
              </Form.Floating>
            </Col>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={handleInput}
                  defaultValue={dataInput.appliedPosition}
                  name="appliedPosition"
                  type="text"
                  placeholder="Số điện thoại"
                  required
                />
                <Form.Label>
                  Vị trí đăng ký<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.appliedPosition}</span>
              </Form.Floating>
            </Col>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={handleInput}
                  defaultValue={dataInput.taxCode}
                  name="taxCode"
                  type="text"
                  placeholder="Số điện thoại"
                  //  required
                />
                <Form.Label>
                  Mã số thuế<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.taxCode}</span>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={handleInput}
                  defaultValue={dataInput.bankAccountNumber}
                  name="bankAccountNumber"
                  type="text"
                  placeholder="Số điện thoại"
                  required
                />
                <Form.Label>
                  Số tài khoản ngân hàng<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.bankAccountNumber}</span>
              </Form.Floating>
            </Col>
            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={handleInput}
                  defaultValue={dataInput.bankBranch}
                  name="bankBranch"
                  type="text"
                  placeholder="Số điện thoại"
                  required
                />
                <Form.Label>
                  Tại ngân hàng<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err?.bankBranch}</span>
              </Form.Floating>
            </Col>
            <Col md={4}>
              <Form.Floating className="mb-3 d-flex ">
                <Form.Control
                  onChange={handleInput}
                  defaultValue={dataInput.referralId}
                  name="referralId"
                  type="text"
                  placeholder="Số điện thoại"
                  // required
                />
                <Form.Label>Mã số người giới thiệu</Form.Label>
                {/* <Button size="sm" className="d-flex ms-2" variant="success" style={{ whiteSpace: 'nowrap', alignItems: 'center' }}
                  disabled={!dataInput.referralId}
                  onClick={() => checkReferralCode(dataInput.referralId)}
                >Kiểm tra</Button> */}
              </Form.Floating>
              <span
                className={checkRef ? "text-succ" : "text-err"}
                style={{ marginTop: "-14px" }}
              >
                {err?.referralId}
              </span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Đóng
          </Button>
          <Button type="submit"> Cập nhật</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UserModal;
