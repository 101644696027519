import React from "react";
import "./hotItem.css";
// import Logo from '../../assets/client/images/logo.png'

function HotItem() {
  return (
    <div className="product-area most-popular section">
      <div className="row">
        <div className="col-12">
          <div className="section-title">
            <h2>Tin tức</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotItem;
