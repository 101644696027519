import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import "./login.css";
function Register() {
  useEffect(() => {
    document.title = "Đăng ký";
  }, []);

  const [passwordShown, setPasswordShown] = useState(false);

  const [checkRef, setCheckRef] = useState(false);
  const [err, setErr] = useState({});
  const [dataInput, setDataInput] = useState({
    userEmail: "",
    userFullName: "",
    userPhoneNumber: "",
    contactAddress: "",

    bankAccountNumber: "",
    bankBranch: "",
    profession: "",
    taxCode: "",
    academicLevel: "",
    appliedPosition: "",
    dateOfBirth: "",
    gender: "",
    identification: "",
    referralId: null,
  });

  const handleInput = (e) => {
    e.persist();

    let field = e.target.name;
    let value = e.target.value;
    if (e.target.name == "referralId") setCheckRef(false);
    setDataInput({ ...dataInput, [field]: value });
    const newErr = { ...err };
    delete newErr[field];
    setErr(newErr);
  };

  const loginSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`register`, dataInput)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 3000 });
        setDataInput({
          userEmail: "",
          userFullName: "",
          userPhoneNumber: "",
          contactAddress: "",

          bankAccountNumber: "",
          bankBranch: "",
          profession: "",
          taxCode: "",
          academicLevel: "",
          appliedPosition: "",
          dateOfBirth: "",
          gender: "",
          identification: "",
          referralId: null,
        });
      })
      .catch((error) => {
        setErr(error.response?.data?.messages);
      });
  };

  const checkReferralCode = (id) => {
    axios
      .post(`check-referral-code`, { referralId: id })
      .then((res) => {
        setCheckRef(true);
        setErr({ referralId: res.data.message || "default_message" });
      })
      .catch((error) => {
        setCheckRef(false);
        error.response?.data?.message
          ? setErr({
              referralId: error.response?.data?.message || "Không tìm thấy",
            })
          : setErr(error.response?.data?.messages);
      });
  };
  return (
    <Container className="py-5" id="login">
      <Row className="justify-content-center">
        <Col md={12}>
          <Card className="text-center">
            <Form onSubmit={loginSubmit}>
              <Card.Header className="cardheader">ĐĂNG KÝ</Card.Header>

              <Card.Body>
                <Row>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.userFullName}
                        name="userFullName"
                        type="text"
                        placeholder="Họ và tên"
                        required
                      />
                      <Form.Label>
                        Họ tên<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.userFullName}</span>
                    </Form.Floating>
                    <Form.Floating className="mb-3">
                      <Form.Select
                        value={dataInput.gender}
                        name="gender"
                        onChange={handleInput}
                        required
                      >
                        <option value="">--Chọn--</option>
                        <option value="M">Nam</option>
                        <option value="F">Nữ</option>
                        <option value="O">Khác</option>
                      </Form.Select>
                      <Form.Label>
                        Giới tính<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.gender}</span>
                    </Form.Floating>
                  </Col>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.identification}
                        name="identification"
                        type="text"
                        placeholder="Số điện thoại"
                        required
                      />
                      <Form.Label>
                        CMND/CCCD<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.identification}</span>
                    </Form.Floating>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.dateOfBirth}
                        name="dateOfBirth"
                        type="date"
                        placeholder="Ngày sinh"
                        required
                      />
                      <Form.Label>
                        Ngày sinh<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.dateOfBirth}</span>
                    </Form.Floating>
                  </Col>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.userPhoneNumber}
                        name="userPhoneNumber"
                        type="text"
                        placeholder="Số điện thoại"
                        required
                      />
                      <Form.Label>
                        Số điện thoại<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.userPhoneNumber}</span>
                    </Form.Floating>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.userEmail}
                        name="userEmail"
                        type="text"
                        placeholder="Email"
                        required
                      />
                      <Form.Label>
                        Email<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.userEmail}</span>
                    </Form.Floating>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.contactAddress}
                        name="contactAddress"
                        type="text"
                        placeholder="Số điện thoại"
                        required
                      />
                      <Form.Label>
                        Địa chỉ liên lạc<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.contactAddress}</span>
                    </Form.Floating>
                  </Col>
                  <Col md={6}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.academicLevel}
                        name="academicLevel"
                        type="text"
                        placeholder="Số điện thoại"
                        required
                      />
                      <Form.Label>
                        Trình độ học vấn<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.academicLevel}</span>
                    </Form.Floating>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.profession}
                        name="profession"
                        type="text"
                        placeholder="Số điện thoại"
                        required
                      />
                      <Form.Label>
                        Nghề nghiệp<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.profession}</span>
                    </Form.Floating>
                  </Col>

                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.appliedPosition}
                        name="appliedPosition"
                        type="text"
                        placeholder="Số điện thoại"
                        required
                      />
                      <Form.Label>
                        Vị trí đăng ký<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.appliedPosition}</span>
                    </Form.Floating>
                  </Col>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.taxCode}
                        name="taxCode"
                        type="text"
                        placeholder="Số điện thoại"
                        //  required
                      />
                      <Form.Label>
                        Mã số thuế<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.taxCode}</span>
                    </Form.Floating>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.bankAccountNumber}
                        name="bankAccountNumber"
                        type="text"
                        placeholder="Số điện thoại"
                        required
                      />
                      <Form.Label>
                        Số tài khoản ngân hàng<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.bankAccountNumber}</span>
                    </Form.Floating>
                  </Col>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.bankBranch}
                        name="bankBranch"
                        type="text"
                        placeholder="Số điện thoại"
                        required
                      />
                      <Form.Label>
                        Tại ngân hàng<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-err">{err?.bankBranch}</span>
                    </Form.Floating>
                  </Col>
                  <Col md={4}>
                    <Form.Floating className="mb-3 d-flex ">
                      <Form.Control
                        onChange={handleInput}
                        value={dataInput.referralId}
                        name="referralId"
                        type="text"
                        placeholder="Số điện thoại"
                        // required
                      />
                      <Form.Label>Mã số người giới thiệu</Form.Label>
                      <Button
                        size="sm"
                        className="d-flex ms-2"
                        variant="success"
                        style={{ whiteSpace: "nowrap", alignItems: "center" }}
                        disabled={!dataInput.referralId}
                        onClick={() => checkReferralCode(dataInput.referralId)}
                      >
                        Kiểm tra
                      </Button>
                    </Form.Floating>
                    <span
                      className={checkRef ? "text-succ" : "text-err"}
                      style={{ marginTop: "-14px" }}
                    >
                      {err?.referralId}
                    </span>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Col
                  sm={6}
                  className="d-grid gap-2 mx-auto"
                  title={
                    dataInput.referralId && checkRef == false
                      ? "Vui lòng kiểm tra mã số người giới thiệu"
                      : ""
                  }
                >
                  <Button
                    type="submit"
                    variant="danger"
                    disabled={dataInput.referralId && checkRef == false}
                  >
                    Đăng ký <i className="fa-solid fa-right-to-bracket" />
                  </Button>
                </Col>
              </Card.Footer>
            </Form>
          </Card>
        </Col>
      </Row>
      <br />
      <div className="text-center" style={{ fontSize: "15px" }}>
        Bạn có tài khoản? <Link to="/login">Đăng nhập ngay</Link>
      </div>
    </Container>
  );
}

export default Register;
