import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  NavItem,
} from "react-bootstrap";
import IncomeModal from "./IncomeModal";
import { toast } from "react-toastify";
import axios from "axios";
import DataTables from "../datatable/DataTables";
import { useNavigate } from "react-router-dom";
import { priceFormatter } from "../other/Format";
const IncomeIndex = () => {
  const [show, setShow] = useState(false);
  const [showInf, setShowInf] = useState(false);
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const onHide = () => {
    setShow(false);
    setShowInf(false);
    getCategories();
  };
  useEffect(() => {
    document.title = "Quản lý thu nhập";
    getUser();
    getCategories();
  }, []);
  const getUser = () => {
    axios
      .get(`admin/users`)
      .then((res) => {
        setUsers(
          res.data.data?.users?.map((province) => {
            return { value: province.id, label: province.userFullName };
          })
        );
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          navigate("/admin/access-denied");
        }
        console.log(err);
      });
  };
  const getCategories = () => {
    axios
      .get(`admin/incomes`)
      .then((res) => {
        setCategories(res.data.data?.groupedByUser);
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          navigate("/admin/access-denied");
        }
        console.log(err);
      });
  };
  const getCategoryBySlug = (item) => {
    setData(item ?? {});
    setShowInf(true);
  };
  const deleteCategory = (id) => {
    axios
      .delete(`/admin/delete-income/${id}`)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 1000 });
        getCategories();
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        } else
          toast(err.response?.data?.message || "Xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
      });
  };
  const findEarliestFromDate = (incomes) => {
    const fromDates = incomes.map((income) => new Date(income.fromDate));
    const minFromDate = new Date(Math.min(...fromDates));
    // const day = String(minFromDate.getDate()).padStart(2, "0");
    // const month = String(minFromDate.getMonth() + 1).padStart(2, "0");
    // const year = minFromDate.getFullYear();

    return convertDate(minFromDate);
  };
  const findLatestFromDate = (incomes) => {
    const fromDates = incomes.map((income) => new Date(income.toDate));
    const maxFromDate = new Date(Math.max(...fromDates));

    return convertDate(maxFromDate);
  };
  const calculateTotalIncome = (incomes) => {
    return incomes.reduce((sum, income) => sum + income.total, 0);
  };
  const convertDate = (maxFromDate) => {
    const day = String(maxFromDate.getDate()).padStart(2, "0");
    const month = String(maxFromDate.getMonth() + 1).padStart(2, "0");
    const year = maxFromDate.getFullYear();

    return `${day}/${month}/${year}`;
  };
  const renderTable = () => {
    const columns = [
      {
        name: "Tên nhân viên",
        selector: (row) => row.userFullName,
        cell: (row) => <div className="text-wrap">{row.userFullName}</div>,
      },

      {
        name: "Từ ngày",
        selector: (row) => row.incomes,
        cell: (row) => (
          <div className="text-wrap">{findEarliestFromDate(row.incomes)}</div>
        ),
      },
      {
        name: "Đến ngày",
        selector: (row) => row.incomes,
        sortable: true,
        cell: (row) => (
          <div className="text-wrap">{findLatestFromDate(row.incomes)}</div>
        ),
        // right: true,
      },
      {
        name: "Tổng thu nhập",
        selector: (row) => row.incomes,
        cell: (row) => (
          <div className="text-wrap">
            {priceFormatter.format(calculateTotalIncome(row.incomes))}
          </div>
        ),

        right: true,
      },
    ];
    const incomesColumns = [
      {
        name: "Từ ngày",
        selector: (row) => row.fromDate,
        cell: (row) => (
          <div className="text-wrap">{convertDate(new Date(row.fromDate))}</div>
        ),
      },
      {
        name: "Đến ngày",
        selector: (row) => row.toDate,
        cell: (row) => (
          <div className="text-wrap">{convertDate(new Date(row.toDate))}</div>
        ),

        wrap: true,
      },
      {
        name: "Thu nhập",
        selector: (row) => row.total,
        cell: (row) => (
          <div className="text-wrap">{priceFormatter.format(row.total)}</div>
        ),
        right: true,

        wrap: true,
      },

      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];
    const data = [];
    if (categories?.length > 0)
      categories?.map((item) => {
        data?.push({
          ...item,
        });
      });
    const IncomesTable = ({ data }) => {
      const dataIncome = [];
      if (data?.incomes?.length > 0)
        data?.incomes?.map((item) => {
          dataIncome?.push({
            ...item,
            setting: (
              <>
                <Button
                  className="me-2"
                  size="sm"
                  onClick={() => getCategoryBySlug(item)}
                  title="Chi tiết"
                >
                  <i className="fa-solid fa-circle-info"></i>
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => deleteCategory(item.id)}
                  title="Xóa"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </>
            ),
          });
        });
      if (data.incomes?.length == 0) {
        return <i className="text-muted ms-5">Không có dữ liệu!</i>;
      }

      return (
        <DataTables
          className="ms-5"
          data={dataIncome}
          columns={incomesColumns}
          pagination={false}
          fixedHeader={false}
        />
      );
    };
    return (
      <DataTables
        data={data}
        columns={columns}
        expandableRows
        // expandableRowExpanded={(row) => row === currentRow}
        expandOnRowClicked
        // onRowClicked={(row) => setCurrentRow(row)}
        expandableRowsComponent={IncomesTable}
        // onRowExpandToggled={(bool, row) => setCurrentRow(row)}
        progressComponent={
          <Spinner
            animation="border"
            role="status"
            variant="primary"
            style={{ width: "3rem", height: "3rem" }}
            className="mt-3"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        }
      />
    );
  };
  return (
    <div className="container">
      <Breadcrumb className="mb-2 mt-2 " style={{ display: "contents" }}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Tổng quan
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Quản lý thu nhập</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header>
              <Button
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => {
                  setData({});
                  setShow(true);
                }}
              >
                <i className="fa-solid fa-plus"></i> Thêm
              </Button>
              <Card.Title>
                <i className="fas fa-list me-1"></i> Danh sách thu nhập
              </Card.Title>
            </Card.Header>
            <Card.Body>{renderTable()}</Card.Body>
          </Card>
        </Col>
      </Row>
      {show ? (
        <IncomeModal
          show={show}
          onHide={onHide}
          data={data}
          type={"TM"}
          users={users}
        />
      ) : null}
      {showInf ? (
        <IncomeModal
          show={showInf}
          onHide={onHide}
          data={data}
          type={"CN"}
          users={users}
        />
      ) : null}
    </div>
  );
};

export default IncomeIndex;
