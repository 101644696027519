import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Card, Button, Form, Table } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const IncomeModal = ({ show, onHide, data, type, users }) => {
  useEffect(() => {
    let arr = data.IncomeDetails;
    arr?.push({ amount: 0, description: "" });
    if (data?.IncomeDetails) {
      setRows(arr);
    }
  }, []);

  const [err, setErr] = useState({});

  const [startDate, setStartDate] = useState(
    data?.fromDate ? new Date(data?.fromDate) : new Date()
  );
  const [endDate, setEndDate] = useState(
    data?.toDate ? new Date(data?.toDate) : new Date()
  );
  const [rows, setRows] = useState([{ amount: 0, description: "" }]);
  const [choiceUser, setChoiceUser] = useState(
    data?.User
      ? { value: data?.User?.id, label: data?.User?.userFullName }
      : {
          value: null,
          label: "Chọn nhân viên",
        }
  );
  const [total, setTotal] = useState(data?.total || "");
  const handleSubmit = (event) => {
    event.preventDefault();
    if (type == "CN") updateCategory();
    else createCategory();
  };
  const createCategory = () => {
    const newCategory = {
      staffId: choiceUser.value, // required
      fromDate: startDate.toISOString().split("T")[0],
      toDate: endDate.toISOString().split("T")[0],
      // total: total,
      incomeDetails: rows?.filter((item) => item.amount != 0),
    };
    axios
      .post("/admin/create-income", newCategory)
      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          onHide();
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErr(error.response?.data?.messages ?? "");
      });
  };
  const updateCategory = () => {
    const newCategory = {
      incomeId: data.id,
      staffId: choiceUser.value, // required
      fromDate: startDate.toISOString().split("T")[0],
      toDate: endDate.toISOString().split("T")[0],
      // total: total,
      incomeDetails: rows?.filter((item) => item.amount != 0),
    };
    axios
      .put("/admin/update-income", newCategory)

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          onHide();
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErr(error.response?.data?.messages ?? "");
      });
  };
  const handleInputIncome = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
    const isAmountEmpty = newRows[index].amount?.trim() == "";
    if (!isAmountEmpty) {
      if (index == newRows?.length - 1) {
        setRows([...newRows, { amount: 0, description: "" }]);
      }
    } else {
      if (newRows?.length > 1) {
        newRows?.splice(index, 1);
        setRows(newRows);
      }
    }
  };
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        {type == "TM" ? "Thêm thu nhập" : "Chi tiết thu nhập"}
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col className="mb-3">
              <span
                className="pe-2 "
                style={{
                  position: "absolute",
                  marginLeft: "8px",
                  zIndex: 1,
                  color: "#757e86",
                }}
              >
                Từ ngày<i className="text-danger">*</i>
              </span>
              <DatePicker
                className="form-control"
                selected={startDate}
                value={startDate}
                onChange={(date) => {
                  if (date) {
                    setStartDate(date);
                  }
                }}
                selectsStart
                maxDate={endDate}
                dateFormat="dd/MM/yyyy"
              />
            </Col>
            <Col className="mb-3">
              <span
                className="pe-2 "
                style={{
                  position: "absolute",
                  marginLeft: "8px",
                  zIndex: 1,
                  color: "#757e86",
                }}
              >
                Đến ngày<i className="text-danger">*</i>
              </span>
              <DatePicker
                className="form-control"
                selected={endDate}
                value={endDate}
                onChange={(date) => {
                  if (date) {
                    setEndDate(date);
                  }
                }}
                selectsStart
                minDate={startDate}
                dateFormat="dd/MM/yyyy"
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <Select
                // defaultValue={choiceProvince}
                options={users}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: "50px",
                  }),
                }}
                required
                placeholder="Chọn nhân viên"
                value={choiceUser || null}
                onChange={(choice) => {
                  setChoiceUser(choice);
                  delete err[`staffId`];
                }}
              />
              <span className="text-err">{err.staffId}</span>
            </Col>
            <Col className="mb-3">
              <Form.Floating className="mb-3">
                <Form.Control
                  value={
                    rows.reduce(
                      (sum, income) => sum + Number(income.amount),
                      0
                    ) || ""
                  }
                  name="total"
                  id="total"
                  placeholder=" thu nhập"
                  readOnly
                  // onChange={(e) => setTotal(e.target.value)}
                />
                <Form.Label>
                  Tổng thu nhập<i className="text-danger"></i>
                </Form.Label>
                <span className="text-err">{err.total}</span>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Thu nhập</th>
                    <th>Ghi chú</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          type="number"
                          value={row.amount ?? ""}
                          name="amount"
                          id="amount"
                          onChange={(event) => handleInputIncome(index, event)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={row.description ?? ""}
                          name="description"
                          id="description"
                          onChange={(event) => handleInputIncome(index, event)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} variant={"secondary"}>
            {" "}
            Đóng
          </Button>
          <Button type="submit" variant={type == "TM" ? "success" : "primary"}>
            {type == "TM" ? "Lưu" : "Cập nhật"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default IncomeModal;
