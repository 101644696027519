import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Col, Row, Card, Breadcrumb, Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import "./Blog.css";
// import IsLoading from '../../user/other/IsLoading';

const BlogIndex = () => {
  const { topicSlug } = useParams();
  const [posts, setPosts] = useState([]);

  const [isLoad, setIsload] = useState(false);

  useEffect(() => {
    document.title = "Gen A | Tin tức";
    window.scrollTo({ top: 0, behavior: "smooth" });
    getPostAll(topicSlug);
  }, []);

  const getImage = (src) => {
    if (src == null) return "/logo512.png";
    else return src;
  };

  const getPostAll = async (topicSlug) => {
    setIsload(true);

    await axios
      .get(`/posts`)
      .then((res) => {
        setPosts(res.data.data?.posts);

        setIsload(false);
      })
      .catch((error) => {
        // toast(error.message, { type: 'error' });
        setIsload(false);
      });
  };

  const viewPosts = () => {
    let output = [];
    if (posts.length > 0) {
      for (let i = 0; i < posts.length; i++) {
        output[i] = (
          <Col
            md={((i * 2) / 3) % 3 === 0 ? 6 : 3}
            key={`postItem_${posts[i].postSlug}`}
            className="p-2"
          >
            <Card>
              <Card.Img
                variant="top"
                src={getImage(
                  posts[i].image?.imageLink ?? posts[i].image?.imageSrc
                )}
                alt="img"
                className="post_img"
              />
              <Card.Body>
                <Card.Title>
                  <Link
                    to={`/blog/${posts[i].postSlug}`}
                    className="text-titleVi"
                  >
                    {posts[i].postTitle}
                  </Link>
                </Card.Title>

                <Card.Text className="text_descVi">
                  {posts[i].posts_desc}
                </Card.Text>

                <div className="cart-reviews">
                  <Card.Link href="#">
                    <FontAwesomeIcon icon={faThumbsUp} />
                  </Card.Link>
                  <Card.Link href="#">
                    <FontAwesomeIcon icon={faThumbsDown} />
                  </Card.Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        );
      }
    } else {
      output = <p className="text-muted">Hiện tại chưa có bài viết!</p>;
    }

    return output;
  };

  return (
    <Container className="detail_bg bg-white" id="page_blog">
      <Breadcrumb className="mb-2 mt-2 breadcrumbCus">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Trang chủ
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/blog" }}>
          Tin tức
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row className="bg-white mt-3 mb-3">{viewPosts()}</Row>
    </Container>
  );
};

export default BlogIndex;
