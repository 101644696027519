import React, { useEffect, useState } from "react";

import axios from "axios";
// import './trendProduct.css'
import { useNavigate } from "react-router-dom";
import "../product/product.css";
import ProductItem from "../product/ProductItem";
import { Row, Col, Form, Button } from "react-bootstrap";
function TrendProduct(data) {
  const [category, setCategory] = useState([]);
  const [product, setProduct] = useState(data.products ?? []);
  const [choiceCate, setChoiceCate] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getCategories();
  }, []);
  const getCategories = () => {
    axios
      .get(`categories?type=cPro`)
      .then((res) => {
        let arr = [];
        arr?.push({ id: 0, categoryName: "Tất cả", categoryPriority: -1 });
        res.data.data?.categories?.forEach((item) => {
          arr?.push(item);
        });
        setCategory(arr);
        setChoiceCate(arr[0]?.id ?? 0);

        if (data.products?.lenght > 0) {
          getProductByCategory(res.data.data?.categories[0]?.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getProductByCategory = (id) => {
    // if (id > 0)
    setProduct(
      data.products
        ?.filter((e) => e.categoryId == id || id == 0)
        ?.sort((a, b) => b.productPriority - a.productPriority)
        ?.slice(0, 8)
    );
  };
  return (
    <div className="product-area section">
      <div>
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h2>Sản phẩm nổi bật</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="product-info">
              <div className="nav-main">
                {/* <!-- Tab Nav --> */}
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {category
                    ?.sort((a, b) => a.categoryPriority - b.categoryPriority)
                    ?.map((item, index) => {
                      return (
                        <li className="nav-item">
                          <a
                            className={
                              item.id == choiceCate
                                ? " nav-link active"
                                : "nav-link"
                            }
                            onClick={() => {
                              getProductByCategory(item.id);
                              setChoiceCate(item.id);
                            }}
                            data-toggle="tab"
                            // href={`${item.categorySlug}`}
                            role="tab"
                          >
                            {item.categoryName}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <Row style={{ justifyContent: "space-around" }}>
                {product
                  ?.filter((e) => e.categoryId == choiceCate || choiceCate == 0)
                  ?.sort((a, b) => a.productPriority - b.productPriority)
                  ?.slice(0, 8)
                  ?.map((item) => {
                    return (
                      <Col md={3} sm={6} xs={6}>
                        <ProductItem item={item} logo={data.logo} />
                      </Col>
                    );
                  })}
              </Row>
              <center>
                <Button
                  className="mt-3"
                  variant="danger"
                  onClick={() => {
                    navigate("/product");
                  }}
                >
                  {" "}
                  Tất cả sản phẩm
                </Button>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrendProduct;
