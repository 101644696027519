import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Row, Col, Card, Button } from "react-bootstrap";
import RoleModal from "./RoleModal";
import { toast } from "react-toastify";
import axios from "axios";
import DataTables from "../datatable/DataTables";
import { getDate } from "../other/Format";
import { useNavigate } from "react-router-dom";
const RoleIndex = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const [showInf, setShowInf] = useState(false);
  const [categories, setCategories] = useState([]);
  const [permission, setPermission] = useState([]);
  const [data, setData] = useState({
    roleName: "",
    permissionIds: [],
  });
  const onHide = () => {
    setShow(false);
    setShowInf(false);
    getCategories();
  };
  useEffect(() => {
    document.title = "Quản lý vài trò";

    getCategories();
    getPermission();
  }, []);
  const getPermission = () => {
    axios
      .get(`admin/permissions`)
      .then((res) => {
        setPermission(buildTree(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const buildTree = (data) => {
    let tree = [];
    let itemsById = {};

    data.forEach((item) => {
      itemsById[item.id] = { ...item, children: [] };
    });

    data.forEach((item) => {
      if (item.permissionParentId == null) {
        tree[item.id] = itemsById[item.id];
      } else {
        if (!itemsById[item.permissionParentId].children) {
          itemsById[item.permissionParentId].children = [];
        }
        itemsById[item.permissionParentId].children.push(itemsById[item.id]);
      }
    });

    return tree;
  };

  const getCategories = () => {
    axios
      .get(`admin/roles`)
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          navigate("/admin/access-denied");
        }
        console.log(err);
      });
  };
  const getCategoryBySlug = (slug) => {
    axios
      .get(`admin/edit-role/${slug}`)
      .then((res) => {
        setData(res.data.data ?? {});
        setShowInf(true);
      })
      .catch((err) => {
        toast(err.data.message, { type: "error", autoClose: 1000 });
        console.log(err);
      });
  };
  const deleteCategory = (id) => {
    axios
      .delete(`/admin/delete-role/${id}`)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 1000 });
        getCategories();
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        } else
          toast(err.response?.data?.message || "Xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
      });
  };
  const renderTable = () => {
    const columns = [
      {
        name: "Tên vai trò",
        selector: (row) => row.roleName,
        cell: (row) => <div className="text-wrap">{row.roleName}</div>,
      },

      {
        name: "Cập nhật",
        selector: (row) => row.updatedAt,
        cell: (row) => (
          <div className="text-wrap">
            {getDate(new Date(row.updatedAt) / 1000)}
          </div>
        ),
      },

      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];
    const data = [];
    if (categories?.length > 0)
      categories?.map((item) => {
        data?.push({
          ...item,
          setting: (
            <>
              <Button
                className="me-2"
                size="sm"
                onClick={() => getCategoryBySlug(item.id)}
                title="Chi tiết"
              >
                <i className="fa-solid fa-circle-info"></i>
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => deleteCategory(item.id)}
                disabled={item.id == 1}
                title="Xóa"
              >
                <i className="fas fa-trash"></i>
              </Button>
            </>
          ),
        });
      });

    return <DataTables data={data} columns={columns} />;
  };
  return (
    <div className="container">
      <Breadcrumb className="mb-2 mt-2 " style={{ display: "contents" }}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Tổng quan
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Quản lý vai trò</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header>
              <Button
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => {
                  setData({
                    roleName: "",
                    permissionIds: [],
                  });
                  setShow(true);
                }}
              >
                <i className="fa-solid fa-plus"></i> Thêm
              </Button>
              <Card.Title>
                <i className="fas fa-list me-1"></i> Danh sách Vai trò
              </Card.Title>
            </Card.Header>
            <Card.Body>{renderTable()}</Card.Body>
          </Card>
        </Col>
      </Row>
      {show ? (
        <RoleModal
          show={show}
          onHide={onHide}
          data={data}
          permission={permission}
          type={"TM"}
        />
      ) : null}
      {showInf ? (
        <RoleModal
          show={showInf}
          onHide={onHide}
          data={data}
          permission={permission}
          type={"CN"}
        />
      ) : null}
    </div>
  );
};

export default RoleIndex;
