import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, FloatingLabel, Form, Image, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";
import TextEditor from "../other/TextEditor";
import CurrencyFormat from "react-currency-format";
const ProductModal = (props) => {
  const navigate = useNavigate();
  const id = props.idEdit || "";
  const [post, setPost] = useState(props.data);
  useEffect(() => {
    getCategories();
    setProductContent(props.data?.productContent ?? "<p></p>");
    let arrImage = [];
    arrImage?.push(
      props?.data?.mainImage?.imageLink ?? props?.data?.mainImage?.imageSrc
    );
    props?.data?.images?.map((item) => {
      arrImage?.push(item.imageLink ?? item.imageSrc);
    });
    arrImage = arrImage?.filter((e) => e != undefined);
    arrImage.push("");
    setImagesrc(arrImage);
    setPost({
      ...post,
    });
  }, [navigate]);

  const [topics, setTopics] = useState([]);

  const [errors, setErrors] = useState([]);
  const [productContent, setProductContent] = useState(
    props.data?.productContent ?? "<p></p>"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [imagesrc, setImagesrc] = useState([""]);

  const getCategories = () => {
    axios
      .get(`admin/categories?type=cPro`)
      .then((res) => {
        setTopics(res.data.data?.categories);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
          handleClose();
        }
      });
  };

  const handleClose = () => props.onHide();

  const handleInput = (e) => {
    let value = e.target.value;
    let field = e.target.name;
    post[field] = value;
    delete errors[field];
    setPost({ ...post });
    setErrors({ ...errors });
  };

  const handleInputChange = (index, event) => {
    const newValue = event.target.value;
    const newImagesrc = [...imagesrc];
    newImagesrc[index] = newValue;
    const filteredImagesrc = newImagesrc.filter(
      (src, idx) => src != "" || idx == newImagesrc.length - 1
    );
    delete errors["imageSrcs"];

    setImagesrc(filteredImagesrc);
    if (index === imagesrc.length - 1 && newValue) {
      setImagesrc([...newImagesrc, ""]);
    }
  };

  const handleSelectFile = (e) => {
    delete errors["imageSrcs"];
    delete errors["imageFile"];

    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) =>
      /\.(jpg|jpeg|png|gif)$/i.test(file.name)
    );

    if (validFiles.length !== files.length) {
      // alert("Only .jpg files are allowed");
      setErrors({
        ...errors,
        imageFile: "Chỉ chấp nhận định dạng: jpg, jpeg, png, gif.",
      });
    }

    // Proceed with the valid files
    if (validFiles.length > 0) {
      setFile(e.target.files);

      // Your logic to handle the valid files
    }
  };

  const handleChange = (value) => {
    setProductContent(value ?? "<p></p>");
    delete errors.productContent;
    setErrors({ ...errors });
  };
  const handleDeleteImage = (index) => {
    const updatedFiles = [...file];
    updatedFiles.splice(index, 1);
    setFile(updatedFiles);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.type == "CN") updatePost();
    else createPost();
  };
  const createPost = () => {
    // const newPost = {
    let arr = [imagesrc];
    const newPost = {
      categoryId: post.categoryId ?? "",
      productName: post.productName ?? "",
      productDescription: post.productDescription ?? "",
      productContent: productContent,
      status: "S1",
      productPriority: post.productPriority ?? 0,
      productCode: post.productCode,
      price: post.price,
      salePrice: post.salePrice,
      productKeyword: post.productKeyword ?? "",
    };
    const formData = new FormData();
    for (const key in newPost) {
      formData.append(key, newPost[key]);
    }

    if ([...arr[0]]?.filter((e) => e != "")?.length == 1)
      [...arr[0]]?.forEach((f) => {
        if (f != "") formData.append("imageSrcs[0]", f);
      });
    if ([...arr[0]]?.length > 1)
      [...arr[0]]?.forEach((f) => {
        if (f != "") formData.append("imageSrcs", f);
      });
    [...file]?.forEach((f) => {
      formData.append("image", f);
    });
    axios
      .post("/admin/create-product", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          props.onHide(true);
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErrors(error.response?.data?.messages ?? "");
      });
  };
  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto"; // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set it to the scrollHeight
  };
  const updatePost = () => {
    let arr = [imagesrc];
    const newPost = {
      productId: post.id,
      categoryId: post.categoryId ?? "",
      productName: post.productName ?? "",
      productDescription: post.productDescription ?? "",
      productContent: productContent,
      status: "S1",
      productPriority: post.productPriority || 0,
      productCode: post.productCode,
      price: post.price,
      salePrice: post.salePrice,
      productKeyword: post.productKeyword ?? "",
    };
    const formData = new FormData();
    for (const key in newPost) {
      formData.append(key, newPost[key]);
    }
    if ([...arr[0]]?.length > 1)
      [...arr[0]]?.forEach((f) => {
        if (f != "") formData.append("imageSrcs", f);
      });
    [...file]?.forEach((f) => {
      formData.append("image", f);
    });
    axios
      .put("/admin/update-product", formData)

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          props.onHide(true);
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErrors(error.response?.data?.messages ?? "");
      });
  };
  const optionTopics = () => {
    if (topics.length > 0) {
      return topics.map((item, key) => {
        return (
          <option key={`topicOption${key}`} value={item.id}>
            {item.categoryName}
          </option>
        );
      });
    }
  };

  return (
    <>
      <Modal size="xl" show={props.show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.type == "TM" ? "Thêm sản phẩm" : "Chi tiết sản phẩm"}
          </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Row>
              <Col md={2}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    onChange={handleInput}
                    name="productCode"
                    value={post.productCode}
                    className={errors.productCode ? "is-invalid" : ""}
                    placeholder="Mã sản phẩm"
                  />
                  <Form.Label>
                    Mã sản phẩm<i className="text-danger"></i>
                  </Form.Label>
                  <span className="text-err">
                    {errors.productCode ? errors.productCode : ""}
                  </span>
                </Form.Floating>
              </Col>
              <Col md={10}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    onChange={handleInput}
                    name="productName"
                    value={post.productName}
                    className={errors.productName ? "is-invalid" : ""}
                    placeholder="Tên chủ đề"
                    required
                  />
                  <Form.Label>
                    Tên sản phẩm<i className="text-danger">*</i>
                  </Form.Label>
                  <span className="text-err">
                    {errors.productName ? errors.productName : ""}
                  </span>
                </Form.Floating>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FloatingLabel
                  label={
                    <>
                      Danh mục<i className="text-danger">*</i>
                    </>
                  }
                  className="mb-3"
                >
                  <Form.Select
                    onChange={handleInput}
                    value={post.categoryId || "DEFAULT"}
                    name="categoryId"
                    required
                    className={errors.categoryId ? "is-invalid" : ""}
                  >
                    <option value="DEFAULT">-- Chọn --</option>
                    {optionTopics()}
                  </Form.Select>

                  <span className="text-err">
                    {errors.categoryId ? errors.categoryId : ""}
                  </span>
                </FloatingLabel>
              </Col>
              <Col md={2}>
                <Form.Floating className="mb-3">
                  <CurrencyFormat
                    className="form-control text-end"
                    thousandSeparator={true}
                    required
                    placeholder="Giá"
                    value={post.price ?? ""}
                    onValueChange={(values) => {
                      const { value } = values;
                      let dataInput = { ...post };
                      dataInput.price = value;
                      setPost(dataInput);
                      delete errors["price"];
                    }}
                  />
                  <Form.Label>
                    Giá
                    <i className="text-danger">*</i>
                  </Form.Label>
                  <span className="text-err">
                    {errors.price ? errors.price : ""}
                  </span>
                </Form.Floating>
              </Col>
              <Col md={2}>
                <Form.Floating className="mb-3">
                  <CurrencyFormat
                    className="form-control text-end"
                    thousandSeparator={true}
                    name="salePrice"
                    required
                    placeholder="Giá bán"
                    value={post.salePrice ?? ""}
                    onValueChange={(values) => {
                      const { value } = values;
                      let dataInput = { ...post };
                      dataInput.salePrice = value;
                      setPost(dataInput);
                      delete errors["salePrice"];
                    }}
                  />
                  <Form.Label>
                    Giá bán
                    <i className="text-danger">*</i>
                  </Form.Label>
                  <span className="text-err">
                    {errors.salePrice ? errors.salePrice : ""}
                  </span>
                </Form.Floating>
              </Col>
              <Col md={2}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="number"
                    value={post.productPriority ?? ""}
                    name="productPriority"
                    onChange={handleInput}
                    id="productPriority"
                    placeholder="Độ ưu tiên"
                  />
                  <Form.Label>
                    Độ ưu tiên<i className="text-danger"></i>
                  </Form.Label>
                  <span className="text-err">{errors.productPriority}</span>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    as="textarea"
                    onChange={handleInput}
                    value={post.productKeyword}
                    name="productKeyword"
                    placeholder="Từ khóa"
                    style={{ height: "70px" }}
                  />
                  <Form.Label>
                    Từ khóa<i className="text-danger"></i>
                  </Form.Label>
                  <span className="text-err">
                    {errors.productKeyword ? errors.productKeyword : ""}
                  </span>
                </Form.Floating>
              </Col>

              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    as="textarea"
                    onChange={(e) => {
                      handleInput(e);
                      adjustTextareaHeight(e.target);
                    }}
                    value={post.productDescription}
                    name="productDescription"
                    placeholder="Mô tả"
                    style={{ height: "70px" }}
                    // rows={10}
                    required
                  />
                  <Form.Label>
                    Mô tả<i className="text-danger">*</i>
                  </Form.Label>
                  <span className="text-err">
                    {errors.productDescription ? errors.productDescription : ""}
                  </span>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  {" "}
                  <label
                    className=""
                    style={{
                      fontSize: 12,
                      cursor: "pointer",
                      border: "1px dashed rgb(197 197 197)",
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    htmlFor="productImage"
                    title={`${
                      imagesrc?.length > 1
                        ? "Xóa link hình ảnh trước khi tải file"
                        : "Chọn hình ảnh"
                    }`}
                  >
                    {" "}
                    + Thêm hình ảnh sản phẩm
                  </label>
                  <input
                    type="file"
                    disabled={imagesrc?.length > 1 ? true : false}
                    id="productImage"
                    className="d-none"
                    accept=".jpg, .jpeg, image/jpeg, image/jpg" // Accept .jpg and .jpeg files
                    multiple={true}
                    onChange={(e) => handleSelectFile(e)}
                  />
                </div>

                <Row>
                  {[...file].map((f, index) => {
                    return (
                      <Col md={4}>
                        <Button
                          onClick={() => handleDeleteImage(index)}
                          variant="secondary"
                          style={{
                            float: "right",
                            position: "relative",
                            zIndex: 9,
                          }}
                          title="Xóa hình"
                        >
                          X
                        </Button>

                        <Image
                          className="mb-3"
                          src={URL.createObjectURL(f)}
                          style={{
                            width: "100%",
                            aspectRatio: "5/3",
                            marginTop: "-15px",
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <span className="text-err">{errors.imageFile}</span>
              </Col>
              <Col>
                {imagesrc.map((src, index) => (
                  <Form.Floating className="mb-3" key={index}>
                    <Form.Control
                      type="text"
                      // className={errors.imageSrcs ? "is-invalid" : ""}
                      placeholder="Link hình ảnh"
                      value={src}
                      disabled={file?.length > 0 ? true : false}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                    <Form.Label>
                      Link hình ảnh <i className="text-danger"></i>
                    </Form.Label>
                  </Form.Floating>
                ))}{" "}
                <span className="text-err">
                  {errors.imageSrcs ? "Đường dẫn không hợp lệ." : ""}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>
                  Nội dung<span className="text-danger"></span>
                </Form.Label>

                {isLoading ? (
                  <i>Đang tải...</i>
                ) : (
                  <TextEditor
                    setContents={productContent}
                    required
                    onChange={(e) => handleChange(e)}
                    placeholder="Nội dung"
                    name="productContent"
                  />
                )}

                <span className="text-err">
                  {errors.productContent ? errors.productContent : ""}
                </span>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Đóng
            </Button>
            <Button
              variant={props.type == "TM" ? "success" : "primary"}
              type="submit"
            >
              {props.type == "TM" ? "Lưu" : "Cập nhật"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ProductModal;
