import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Row, Col, Card, Button, Form } from "react-bootstrap";
import UserModal from "./UserModal";
import axios from "axios";
import DataTables from "../datatable/DataTables";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const UserIndex = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [roles, setRoles] = useState([]);
  const [data, setData] = useState({});
  const onHide = (isLoading) => {
    setShow(false);
    if (isLoading) getUser();
  };
  const [users, setUser] = useState([]);

  useEffect(() => {
    getUser();
    getRoles();
    document.title = "Quản lý người dùng";
  }, []);
  const getUser = () => {
    axios
      .get(`admin/users`)
      .then((res) => {
        setUser(res.data.data?.users);
      })
      .catch((err) => {
        if (err.response?.status == 403) {
          navigate("/admin/access-denied");
        }
        console.log(err);
      });
  };
  const getRoles = () => {
    axios
      .get(`admin/roles`)
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const optionRoles = () => {
    if (roles.length > 0) {
      return roles.map((item, key) => {
        return (
          <option key={`roleOption${key}`} value={item.id}>
            {item.roleName}
          </option>
        );
      });
    }
  };
  const changeRole = (e, accountId) => {
    let data = {
      accountId: accountId,
      roleIds: [e.target.value],
    };
    // let data = { accountId: id };
    axios
      .post(`admin/assign-account-roles`, data)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 3000 });
        getUser();
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        } else
          toast(err.response.data.message, { type: "error", autoClose: 3000 });
      });
  };

  const changeStatus = (id) => {
    let data = { accountId: id };
    axios
      .put(`admin/toggle-account-status`, data)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 3000 });
        getUser();
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        } else
          toast(err.response.data.message, { type: "error", autoClose: 3000 });
      });
  };
  const getUserById = (id) => {
    axios
      .get(`admin/edit-user/${id}`)
      .then((res) => {
        setData(res.data.data ?? {});
        setShow(true);
      })
      .catch((err) => {
        // toast(err.data.message, { type: "error", autoClose: 1000 });
        console.log(err);
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
      });
  };
  const deleteUser = (id) => {
    axios
      .delete(`/admin/delete-user/${id}`)
      .then((res) => {
        toast(res.data.message, { type: "success", autoClose: 1000 });
        getUser();
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        } else
          toast(err.response?.data?.message || "Xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
      });
  };
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f0f0f0",
        color: "#333",
        fontSize: "16px",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
  };
  const renderTable = () => {
    const columns = [
      {
        name: "Mã nhân viên",
        selector: (row) => row.userCode,
        cell: (row) => <div className="text-wrap">{row.userCode}</div>,
      },
      {
        name: "Tên nhân viên",
        selector: (row) => row.userFullName,
        cell: (row) => <div className="text-wrap">{row.userFullName}</div>,
      },
      {
        name: "Cấp bậc",
        selector: (row) => row.academicLevel,
        cell: (row) => <div className="text-wrap">{row.academicLevel}</div>,
      },
      {
        name: "Vai trò",
        selector: (row) => row.role,
        cell: (row) => <div className="text-wrap">{row.role}</div>,
      },
      {
        name: "Trạng thái",

        selector: (row) => row.Account?.status ?? "",
        center: true,
        cell: (row) => (
          <div className="text-center">
            <Button
              className="me-2"
              size="sm"
              onClick={() => changeStatus(row.Account?.id.toString())}
              disabled={
                row.userCode == "00000000" || row.Account?.verifiedAt == null
              }
              title="Chọn để thay đổi trạng thái"
              variant={
                row.Account?.status == "ACTIVE" ? "success" : "secondary"
              }
            >
              {row.Account?.status == "ACTIVE"
                ? " Đang hoạt động"
                : "Đang bị khóa"}
            </Button>
          </div>
        ),
      },

      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];
    const refColumns = [
      {
        name: "Mã nhân viên",
        selector: (row) => row.userCode,
        cell: (row) => <div className="text-wrap">{row.userCode}</div>,
      },
    ];
    const data = [];
    if (users?.length > 0)
      users?.map((item) => {
        data?.push({
          ...item,
          setting: (
            <>
              <Button
                className="me-2"
                size="sm"
                onClick={() => getUserById(item.id)}
                title="Chi tiết"
              >
                <i className="fa-solid fa-circle-info"></i>
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => deleteUser(item.id)}
                disabled={item.userCode == "00000000"}
                title="Xóa"
              >
                <i className="fas fa-trash"></i>
              </Button>
            </>
          ),
          role:
            roles.length > 0 ? (
              <>
                <Form.Select
                  onChange={(e) => changeRole(e, item.Account.id)}
                  value={item.Account?.Roles[0]?.id || "DEFAULT"}
                  name="categoryId"
                  required
                  disabled={
                    item.userCode == "00000000"
                    // ||  item.Account?.verifiedAt == null
                  }
                >
                  <option value="DEFAULT">-- Chọn --</option>
                  {optionRoles()}
                </Form.Select>
              </>
            ) : (
              item.Account?.Roles[0]?.roleName
            ),
        });
      });
    const RefTable = ({ data }) => {
      const dataRef = [];
      if (data?.referrals?.length > 0)
        data?.referrals?.map((item) => {
          dataRef?.push({
            ...item,
            role:
              roles.length > 0 ? (
                <>
                  <Form.Select
                    onChange={(e) => changeRole(e, item.Account.id)}
                    value={item.Account?.Roles[0]?.id || "DEFAULT"}
                    name="categoryId"
                    required
                    disabled={
                      item.userCode == "00000000"
                      // ||  item.Account?.verifiedAt == null
                    }
                  >
                    <option value="DEFAULT">-- Chọn --</option>
                    {optionRoles()}
                  </Form.Select>
                </>
              ) : (
                item.Account?.Roles[0]?.roleName
              ),
            setting: (
              <>
                <Button
                  className="me-2"
                  size="sm"
                  // onClick={() => getCategoryBySlug(item)}
                  title="Chi tiết"
                >
                  <i className="fa-solid fa-circle-info"></i>
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  // onClick={() => deleteCategory(item.id)}
                  title="Xóa"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </>
            ),
          });
        });
      if (data.referrals?.length == 0) {
        return <i className="text-muted ms-5">Không có dữ liệu!</i>;
      }

      return (
        <DataTables
          className="ms-5"
          data={dataRef}
          columns={columns}
          pagination={false}
          fixedHeader={false}
          expandableRows
          expandableRowDisabled={rowPreDisabled}
          expandableRowsComponent={RefTable}
          customStyles={customStyles}
        />
      );
    };
    const rowPreDisabled = (row) => row.referrals?.length == 0;
    return (
      <DataTables
        data={data}
        columns={columns}
        expandableRows
        expandableRowDisabled={rowPreDisabled}
        expandableRowsComponent={RefTable}
        customStyles={customStyles}
      />
    );
  };

  return (
    <div className="container">
      <Breadcrumb className="mb-2 mt-2 " style={{ display: "contents" }}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
          Tổng quan
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Quản lý thành viên</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Header>
              <Card.Title>
                <i className="fas fa-list me-1"></i> Danh sách người dùng
              </Card.Title>
            </Card.Header>
            <Card.Body>{renderTable()}</Card.Body>
          </Card>
        </Col>
      </Row>
      {show ? <UserModal show={show} onHide={onHide} data={data} /> : null}
    </div>
  );
};

export default UserIndex;
