import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Card, Button, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
const WarehouseModal = ({ show, onHide, data, type }) => {
  useEffect(() => {
    getProvinces();
    if (choiceDistrict.value != 0) getWards(choiceDistrict.value);
    if (choiceProvince.value != null) getDistricts(choiceProvince.value);
  }, []);
  const [choiceProvince, setChoiceProvince] = useState({
    value: data?.Province?.id || null,
    label: data?.Province?.name || "Chọn tỉnh/ thành phố",
  });
  const [choiceDistrict, setChoiceDistrict] = useState({
    value: data?.District?.id || 0,
    label: data?.District?.name || "Chọn quận/ huyện",
  });
  const [choiceWard, setChoiceWard] = useState({
    value: data?.Ward?.id || 0,
    label: data?.Ward?.name || "Chọn phường/ xã",
  });
  const [wards, setWards] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [dataInput, setDataInput] = useState(data);
  const [err, setErr] = useState({});
  const handleInput = (event) => {
    const field = event.target.name;
    let value = event.target.value;
    const newDataInput = { ...dataInput };
    if (field == "status") value = event.target.checked ? "S1" : "S2";
    newDataInput[field] = value;
    setDataInput(newDataInput);
    const newErr = { ...err };
    delete newErr[field];
    setErr(newErr);
  };
  const getProvinces = () => {
    axios
      .get(`provinces`)
      .then((res) => {
        setProvinces(
          res.data.data?.map((province) => {
            return { value: province.id, label: province.name };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDistricts = (idProvince) => {
    axios
      .get(`districts/${idProvince}`)
      .then((res) => {
        setDistricts(
          res.data.data?.map((province) => {
            return { value: province.id, label: province.name };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getWards = (idDistrict) => {
    axios
      .get(`wards/${idDistrict}`)
      .then((res) => {
        setWards(
          res.data.data?.map((province) => {
            return { value: province.id, label: province.name };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (type == "CN") updateCategory();
    else createCategory();
  };
  const createCategory = () => {
    const newCategory = {
      name: dataInput.name,
      address: dataInput.address,
      provinceId: choiceProvince.value,
      districtId: choiceDistrict.value,
      wardId: choiceWard.value,
    };
    axios
      .post("/admin/create-warehouse", newCategory)

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          onHide();
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErr(error.response?.data?.messages ?? "");
      });
  };
  const updateCategory = () => {
    const newCategory = {
      warehouseId: dataInput.id,
      name: dataInput.name,
      address: dataInput.address,
      provinceId: choiceProvince.value,
      districtId: choiceDistrict.value,
      wardId: choiceWard.value,
      // categoryParentId:dataInput.categoryParentId
    };
    axios
      .put("/admin/update-warehouse", newCategory)

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          onHide();
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.status == 403) {
          toast("Không có quyền truy cập", {
            type: "error",
            autoClose: 1000,
          });
        }
        setErr(error.response?.data?.messages ?? "");
      });
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {type == "TM" ? "Thêm kho" : "Chi tiết kho"}
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  value={dataInput.name ?? ""}
                  name="name"
                  id="name"
                  placeholder="Tên kho"
                  onChange={handleInput}
                  required
                />
                <Form.Label>
                  Tên kho<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-err">{err.name}</span>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col>
              <Select
                defaultValue={choiceProvince}
                options={provinces}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: "50px",
                  }),
                }}
                required
                placeholder="Chọn tỉnh/ thành phố"
                value={choiceProvince}
                onChange={(choice) => {
                  setChoiceProvince(choice);
                  getDistricts(choice.value);
                  delete err[`provinceId`];
                  delete err[`districtId`];
                  delete err[`wardId`];
                  setChoiceWard({
                    value: 0,
                    label: "Chọn phường/ xã",
                  });
                  setChoiceDistrict({
                    value: 0,
                    label: "Chọn quận/ huyện",
                  });
                }}
              />
              <span className="text-err">{err.provinceId}</span>
            </Col>
            <Col>
              <Select
                options={districts}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: "50px",
                  }),
                }}
                placeholder="Chọn quận/ huyện"
                value={choiceDistrict}
                isDisabled={choiceProvince.value == null}
                onChange={(choice) => {
                  setChoiceDistrict(choice);
                  getWards(choice.value);
                  delete err[`districtId`];
                  delete err[`wardId`];
                  setChoiceWard({
                    value: 0,
                    label: "Chọn phường/ xã",
                  });
                }}
              />

              <span className="text-err">{err.districtId}</span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Select
                options={wards}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: "50px",
                  }),
                }}
                placeholder="Chọn xã/ phường"
                value={choiceWard}
                isDisabled={choiceDistrict.value == 0}
                onChange={(choice) => {
                  delete err[`wardId`];

                  setChoiceWard(choice);
                }}
              />
              <span className="text-err">{err.wardId}</span>
            </Col>

            <Col>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  value={dataInput.address ?? ""}
                  name="address"
                  id="address"
                  placeholder="Tên kho"
                  onChange={handleInput}
                />
                <Form.Label>
                  Địa chỉ<i className="text-danger"></i>
                </Form.Label>
                <span className="text-err">{err.address}</span>
              </Form.Floating>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} variant={"secondary"}>
            {" "}
            Đóng
          </Button>
          <Button type="submit" variant={type == "TM" ? "success" : "primary"}>
            {type == "TM" ? "Lưu" : "Cập nhật"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default WarehouseModal;
