import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";

import "./login.css";
function Login() {
  useEffect(() => {
    document.title = "Đăng nhập";
  }, []);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [loginInput, setLogin] = useState({
    type: 1,
    account: localStorage.getItem("auth_account") || "",
    username: "",
    password: "",
    error_list: [],
  });
  const [err, setErr] = useState({});

  const handleInput = (e) => {
    e.persist();

    let field = e.target.name;
    let value = e.target.value;

    setLogin({ ...loginInput, [field]: value });
    setErr({ ...err, [field]: "" });
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    let data = {
      username: loginInput.username,
      password: loginInput.password,
    };
    axios
      .post("/login", data)

      .then((res) => {
        if (res.status == 200) {
          console.log(res);
          localStorage.setItem("access_token", res.data.data.accessToken);
          localStorage.setItem("username", loginInput.username);
          toast(res.data.message, { type: "success", autoClose: 1000 });
          window.location.href = "/admin";
        } else {
        }
      })
      .catch((error) => {
        if (error.response?.data?.message)
          toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
        setErr(error.response?.data?.messages ?? "");
      });
  };

  return (
    <Container className="py-5" id="login">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="text-center">
            <Form onSubmit={loginSubmit}>
              <Card.Header className="cardheader">ĐĂNG NHẬP</Card.Header>

              <Card.Body>
                <Form.Floating className="mb-3">
                  <Form.Control
                    onChange={handleInput}
                    defaultValue={loginInput.username}
                    name="username"
                    type="text"
                    placeholder="Tài khoản"
                    required
                  />
                  <Form.Label>
                    <i className="fa-solid fa-user" /> Tài khoản
                  </Form.Label>
                  <span className="text-err">{err?.username}</span>
                </Form.Floating>
                <Form.Floating className="mb-3">
                  <InputGroup className="has-validation w-100 is-invalid">
                    <Form.Floating style={{ width: "calc(100% - 46px)" }}>
                      <Form.Control
                        onChange={handleInput}
                        defaultValue={loginInput.password}
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        className="border-end-0"
                        placeholder="Mật Khẩu"
                        required
                      />
                      <Form.Label>
                        <i className="fa-solid fa-key" /> Mật Khẩu
                      </Form.Label>
                    </Form.Floating>

                    <InputGroup.Text
                      onClick={togglePassword}
                      style={{ width: "46px" }}
                    >
                      <i
                        className={
                          passwordShown
                            ? "fa-regular fa-eye"
                            : "fa-regular fa-eye-slash"
                        }
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Floating>
              </Card.Body>

              <Card.Footer>
                <Col sm={6} className="d-grid gap-2 mx-auto">
                  <Button type="submit" variant="danger">
                    Đăng nhập <i className="fa-solid fa-right-to-bracket" />
                  </Button>
                </Col>
              </Card.Footer>
            </Form>
          </Card>
        </Col>
      </Row>
      <br />
      <div className="text-center" style={{ fontSize: "15px" }}>
        {" "}
        Chưa có tài khoản? <Link to="/register">Đăng ký ngay</Link>
      </div>
    </Container>
  );
}

export default Login;
