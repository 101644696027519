import React, { Component } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { error } from "jquery";
import Check from "../../../components/admin/other/Check";
import FormatData from "../../../components/admin/FormatData";
import Logo from "../../../assets/client/images/logo-gena.png";
// import Logo from "../../assets/admin/assets/img/logo-vplife-trong-suot.png"
import "./navbar.css";
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listUser: [],
      user: {},
      show: false,
      dateInput: {
        id_user: "",
        user_name: "",
        user_account: "",
        password: "",
        id_role: "",
        is_meal: 0,
      },
      listrole: [],
      showAlarm: false,
      ingredent_alarmlist: [],
      count: 0,
    };
  }
  async componentDidMount() {
    this.getAllUser();
    this.getlistrole();
    this.getIngredent();
  }
  handleClose = () => {
    this.setState({ show: false, showAlarm: false });
  };
  getAllUser = () => {};

  sidenavToggle = (event) => {
    document
      .getElementById("sb-nav-fixed")
      .classList.toggle("sb-sidenav-toggled");
  };
  dateNotTime(data) {
    let d = new Date(data * 1000);
    return d.getDate() + "/" + d.getMonth() + 1 + "/" + d.getFullYear();
  }
  logout = (event) => {
    swal({
      title: "Bạn muốn đăng xuất?",
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        localStorage.removeItem("auth_id_shool");
        localStorage.removeItem("auth_id_user");
        localStorage.removeItem("auth_name");
        localStorage.removeItem("role_rank");
        localStorage.removeItem("role_id");
        localStorage.removeItem("role_name");
        localStorage.removeItem("permission");
        localStorage.removeItem("token_type");
        localStorage.removeItem("access_token");
        localStorage.removeItem("auth_phone");
        localStorage.removeItem("auth_account");
        localStorage.removeItem("vip");
        this.props.navHook("/login");

        toast("Đăng xuất thành công", { type: "success" });
      }
    });
  };
  updateuser = () => {
    swal({
      title: "Bạn muốn cập nhật tài khoản?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .post(
            `/updateUser?id_user=${localStorage.getItem(
              "auth_id_user"
            )}&user_name=${
              this.state.dataInput?.user_name != undefined
                ? this.state.dataInput.user_name
                : this.state.user?.user_name
            }&user_account=${
              this.state.dataInput?.user_account != undefined
                ? this.state.dateInput.user_account
                : this.state.user?.user_account
            }&user_password=${
              this.state.dataInput?.password != undefined
                ? this.state.dataInput?.password
                : ""
            }&id_role=${
              this.state.dataInput?.id_role != undefined
                ? this.state.dateInput.id_role
                : this.state.user.id_role
            }&is_meal=${
              this.state.dateInput.is_meal != undefined
                ? this.state.dateInput.is_meal != undefined
                : this.state.user?.is_meal
            }`
          )
          .then((res) => {
            if (res.data.status) {
              this.handleClose();
              this.getAllUser();
              this.setState({ id_user: "" });
              toast(`${res.data.data.mess}`, {
                type: "success",
                autoClose: 1000,
              });
            } else {
              toast(`${res.data.data.mess}`, {
                type: "error",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  getIngredent = () => {};
  getlistrole = () => {};
  handleShow = () => {
    this.setState({ show: true });
  };
  handleShowAlarm = () => {
    this.getIngredent();
    if (this.state.count > 0) this.setState({ showAlarm: true });
  };
  handleUpdate = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    if (event.target.name === "is_meal") {
      if (event.target.checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    let dataInput = { ...this.state.dataInput };
    dataInput[field] = value;
    // console.log(dataInput)
    this.setState({ dataInput: dataInput });
  };

  render() {
    return (
      // sb-topnav navbar navbar-expand navbar-dark bg-dark
      <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
        <Modal
          show={this.state.show}
          size="md"
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa-solid fa-chalkboard-user"></i>&nbsp; Thông tin
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {}
            <Form>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  name="user_name"
                  onChange={
                    this.handleUpdate
                    // (e) => this.setState({ user_name: e.target.value })
                  }
                  defaultValue={this.state.user?.user_name}
                  placeholder="Họ và tên"
                  required
                />
                <Form.Label>
                  Họ và tên<i className="text-danger">*</i>
                </Form.Label>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  onChange={
                    this.handleUpdate
                    // (e) => this.setState({ account: e.target.value })
                  }
                  defaultValue={this.state.user?.user_account}
                  name="user_account"
                  placeholder="Tài khoản"
                />
                <Form.Label>
                  Tài khoản<i className="text-danger"></i>
                </Form.Label>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  type="password"
                  name="password"
                  onChange={
                    this.handleUpdate
                    // (e) => this.setState({ password: e.target.value })
                  }
                  defaultValue={""}
                  placeholder="Mật khẩu"
                />
                <Form.Label>
                  Mật khẩu<i className="text-danger"></i>
                </Form.Label>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Select
                  onChange={
                    this.handleUpdate
                    // (e) => this.setState({ id_role: e.target.value })
                  }
                  defaultValue={this.state.user?.id_role}
                  name="id_role"
                  required
                >
                  <option value="">--Chọn--</option>
                  {this.state.listrole.map((role) => {
                    return (
                      <option key={role.id} value={role.id}>
                        {role.role_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Label>
                  Chức vụ<i className="text-danger">*</i>
                </Form.Label>
              </Form.Floating>
              <Form.Check
                className="mb-3"
                type="checkbox"
                label="Không ăn ở trường"
                name="is_meal"
                onChange={this.handleUpdate}
                checked={this.state.dataInput?.is_meal}
              />
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              <i className="fa-solid fa-times"></i> Đóng
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={() => this.updateuser()}
            >
              <i className="fa-solid fa-pencil"></i> Cập nhật
            </Button>
          </Modal.Footer>
        </Modal>

        <Link
          className="navbar-brand ps-3 d-flex justify-content-around "
          to="/admin"
        >
          <img src={Logo} style={{ height: 60, width: 70 }} alt="Gen A" />
          Gen A
        </Link>
        <Button
          variant="outline-danger"
          size="sm"
          className="order-1 order-lg-0 me-2"
          id="sidebarToggle"
          onClick={this.sidenavToggle}
        >
          <i className="fas fa-bars"></i>
        </Button>

        <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0"></form>
        {/* {new Check().permission(['89']) ?
          <i className="fa-solid fa-bell fa-lg" style={{ color: this.state.count > 0 ? "#fff" : '#d5d5d5', paddingTop: '5px', cursor: this.state.count > 0 ? 'pointer' : null }} onClick={this.handleShowAlarm} >
            {this.state.count > 0 ? <sup >{this.state.count}</sup> : null} </i> : null} {" "} */}
        <ul className="navbar-nav  me-lg-4">
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle text-white"
              id="navbarDropdown"
              to="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-user fa-fw"></i>
              {localStorage.getItem("username")}
            </Link>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdown"
            >
              {/* <li><Link className="dropdown-item" onClick={this.handleShow} ><i className="fa-solid fa-gear"> </i> Thông tin</Link></li> */}
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link
                  className="dropdown-item text-danger"
                  onClick={this.logout}
                >
                  <i className="fa-solid fa-right-from-bracket"></i> Đăng xuất
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    );
  }
}

function myParams(Component) {
  //Back link AND use "params"
  return (props) => (
    <Component navHook={useNavigate()} {...props} params={useParams()} />
  );
}

export default myParams(Navbar);
