import { BrowserRouter, Routes, Route } from "react-router-dom";

import "sweetalert/dist/sweetalert.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MasterLayout from "../layouts/admin/masterLayout/MasterLayout";
import Home from "../components/frontend/home/Home";
import Login from "../components/frontend/auth/Login";
import Register from "../components/frontend/auth/Register";
import Dashboard from "../components/admin/Dashboard";
import Error404 from "../components/admin/errors/Error404";
import ErrorHome404 from "../components/admin/errors/ErrorHome404";
import UserIndex from "../components/admin/user/User";
import Product from "../page/Product";
import HomePage from "../components/frontend/home_page/Home_page";
import Cart from "../components/frontend/cart/Cart";
import About from "../page/About";
import ProductDetail from "../components/frontend/product/ProductDetail";
import CategoryIndex from "../components/admin/category/Category";
import PostIndex from "../components/admin/Post/Post";
import ProductIndex from "../components/admin/product/Product";
import SettingIndex from "../components/admin/setting/Setting";
import OrderIndex from "../components/admin/order/Order";
import Verify from "../components/frontend/auth/Verify";
import Tracking from "../page/Tracking";
import BlogIndex from "../components/frontend/blog/BlogIndex";
import BlogDetail from "../components/frontend/blog/BlogDetail";
import RoleIndex from "../components/admin/Role/Role";
import Error403 from "../components/admin/errors/Error403";
import WarehouseIndex from "../components/admin/warehouse/Warehouse";
import IncomeIndex from "../components/admin/incomes/Income";
import IncomePersonIndex from "../components/admin/incomes/IncomePerson";
export default function router() {
  return (
    <>
      <ToastContainer />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="/product/:category_slug" element={<Product />} />
            <Route path="/product" element={<Product />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/product-detail/:product_slug"
              element={<ProductDetail />}
            />
            <Route path="/verify/:param" element={<Verify />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/tracking" element={<Tracking />} />
            <Route path="/blog" element={<BlogIndex />} />

            <Route path="/blog/:post_slug" element={<BlogDetail />} />

            <Route path="*" element={<ErrorHome404 />} />
          </Route>
          <Route path="admin" element={<MasterLayout />}>
            <Route path="*" element={<Error404 />} />
            <Route path="access-denied" element={<Error403 />} />
            <Route path="user" element={<UserIndex />} />
            <Route path="role" element={<RoleIndex />} />
            <Route path="warehouse" element={<WarehouseIndex />} />
            <Route path="income" element={<IncomeIndex />} />
            <Route path="personal-incomes" element={<IncomePersonIndex />} />
            <Route
              path="category"
              element={<CategoryIndex element="category-product" />}
            />
            <Route
              path="topic"
              element={<CategoryIndex element="category-post" />}
            />
            <Route path="post" element={<PostIndex />} />
            <Route path="product" element={<ProductIndex />} />
            <Route path="setting" element={<SettingIndex />} />
            <Route path="order" element={<OrderIndex />} />
          </Route>

          <Route path="*" element={<ErrorHome404 />} />
          <Route index element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
