import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCart } from "react-use-cart";
import {
  faCartPlus,
  faMagnifyingGlass,
  faUserPlus,
  faZ,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "../../../assets/client/images/logo-gena.png";
import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import "./navbarMiddle.css";
const NavbarMiddle = (data) => {
  const navigate = useNavigate();

  const { totalUniqueItems } = useCart();

  const logo = data.logo;
  const { category_slug, search } = useParams();
  const [keyword, setKeyword] = useState("");
  const handleChange = (e) => {
    const value = e.target.value;
    setKeyword(value || "");
    if (value != "") {
      navigate(`/product?search=${value ?? ""}`);
    } else {
      navigate("/product");
    }
  };
  const submitSearch = (e) => {
    e.preventDefault();

    navigate(`/danh-muc/${category_slug || "tat-ca"}/${keyword}`);
  };

  return (
    <div id="header" className="top-head_ bg-white">
      {/* <Row> */}
      <div className="hotline">
        {data?.setting?.length > 0 ? (
          <a
            href={`tel:${
              data?.setting?.filter((e) => e.name == "phoneNumber")[0]
                ?.payload ?? "#"
            }`}
            className="mb-3 ms-3"
          >
            <FontAwesomeIcon icon={faPhone} /> Liên hệ :
            {data?.setting?.filter((e) => e.name == "phoneNumber")[0]
              ?.payload ?? " "}
          </a>
        ) : null}
        <div className="icon_">
          <ul className="list-inline socials">
            <li className="list-inline-item px-1">
              {data?.setting?.length > 0 ? (
                <Link
                  to={
                    data.setting?.filter((e) => e.name == "facebookLink")[0]
                      ?.payload ?? "#"
                  }
                  className="fw-bold"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </Link>
              ) : null}
            </li>
            <li className="list-inline-item px-1">
              {data?.setting?.length > 0 ? (
                <Link
                  to={
                    data?.setting?.filter((e) => e.name == "zaloLink")[0]
                      ?.payload ?? "#"
                  }
                  className="fw-bold"
                >
                  <FontAwesomeIcon icon={faZ} />
                </Link>
              ) : null}
            </li>

            <li className="list-inline-item px-1">
              {data?.setting?.length > 0 ? (
                <Link
                  to={
                    data?.setting?.filter((e) => e.name == "youtubeLink")[0]
                      ?.payload ?? "#"
                  }
                  className="fw-bold"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </Link>
              ) : null}
            </li>
          </ul>
        </div>
      </div>
      {/* </Row>  */}
      <Container>
        <Row className="row-header">
          <Col lg={4} md={3} sm={3} xs={5} className="company ">
            <Link to="/" className="companyinfo">
              <img
                className="navbar_logo me-md-1"
                src={logo ?? Logo}
                alt="logo"
              />
              {/* <img className="navbar_logo_mobile" src={IconLogo} alt="logo" /> */}
            </Link>
          </Col>
          <Col lg={5} md={4} sm={5} xs={5} className="menu">
            <div className="row-item-center d-flex align-items-center">
              <Form
                onSubmit={submitSearch}
                style={{ width: "100%", marginTop: "-2px" }}
              >
                <InputGroup className="search-input">
                  <Form.Control
                    className="noboder"
                    onChange={
                      (e) => handleChange(e)
                      // setKeyword(e.target.value)
                    }
                    value={keyword}
                    placeholder="Tìm kiếm"
                  />
                  <div className="button">
                    <a href="#" className="btn">
                      {" "}
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        style={{ color: "var(--menu-btn)" }}
                        className=" me-2 mt-2"
                      />
                    </a>
                  </div>
                </InputGroup>
              </Form>
            </div>
          </Col>

          <Col lg={3} md={4} sm={4} xs={2} className="menu ps-0 text-right">
            <div className="row-item-right d-flex justify-content-md-end">
              <Link to={"/cart"} className="gio_hang me-2 mt-2">
                <FontAwesomeIcon icon={faCartPlus} />
                <span className="title">Giỏ hàng</span>
                <span className="cart_number position-absolute translate-middle badge rounded-pill bg-danger">
                  {totalUniqueItems}
                </span>
              </Link>
              <Link to={"/login"} className="gio_hang me-2 mt-2">
                <FontAwesomeIcon icon={faUser} />
                <span className="title">Đăng nhập</span>
              </Link>
              <Link to={"/register"} className="gio_hang me-2 mt-2">
                <FontAwesomeIcon icon={faUserPlus} />
                <span className="title">Đăng ký</span>
              </Link>

              {/* <div className="account_home mt-2">
                <FontAwesomeIcon icon={faUser} />
                <NavDropdown
                  title={
                    <>
                      {" "}
                      <span className="title">Tài khoản</span>
                    </>
                  }
                >
                  <NavDropdown.Item as={Link} to={`/login/`} className="btn">
                    Đăng nhập
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/register" className="btn">
                    Đăng ký
                  </NavDropdown.Item>
                </NavDropdown>
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Sidebar show={visibleCategory} onHide={() => setVisibleCategory(false)} /> */}
    </div>
  );
};

export default NavbarMiddle;
